import { BASE_URL } from "../../constant/base";

const checkPasswordAndConfirm = (confirmpassword, values, formik) => {
  if (confirmpassword === values.password) {
    return true;
  } else {
    formik.setFieldError(
      "confirm",
      "Not match your password and confirm password"
    );
    return false;
  }
};
const onSubmit = async (confirmpassword, values, token) => {
  const isConfirm = checkPasswordAndConfirm(confirmpassword, values);
  // console.log(isConfirm);
  if (isConfirm) {
    const _res = await fetch(`${BASE_URL}api/user/resetPassword/${token}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: values.password,
      }),
    });
    if (_res.status === 200) {
      return { status: 200, messgae: "Your password is reseted" };
    } else {
      return { status: 400, messgae: "Fail to reset password" };
    }
  }
};

export { onSubmit, checkPasswordAndConfirm };
