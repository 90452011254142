import React, { Suspense, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useIsAuthState } from "../../hooks/useAuth";
import { getDisabledComponent } from "../../utils/auth/get_disabled_component";
import { useLocation, useHistory } from "react-router-dom";
import Loading from "../common/loading/loading";
// const Chat = React.lazy(() => import("../admin-component/chat/chat"))
import Chat from "../admin-component/chat/chat";
import SocketProvider from "../../context/socket";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import lazyWithRetry from "../../constant/lazyWithRetry";
import CloseWindow from "../admin-component/vehicle/tabel/closeWindow";

const AllDealershipUsersCars = React.lazy(() =>
  import("../admin-component/all-cars/dealer_all_cars")
);

const DealershipsTicket = React.lazy(() =>
  import("../admin-component/ticketing/dealerships_ticket")
);
const TicketReplays = React.lazy(() =>
  import("../admin-component/ticketing/ticket_replay")
);
const BillOfSell = React.lazy(() =>
  import("../admin-component/bill-of-sell/bill_of_sell")
);
const ContractManagement = React.lazy(() =>
  import("../admin-component/dealership-contracts/contract_management")
);
const DealershipTaskManagment = React.lazy(() =>
  import("../admin-component/tasks/dealership_task_managment")
);
const AddingBlogPost = React.lazy(() =>
  import("../admin-component/dealership-blog/adding_post")
);
const AddingBlogtag = React.lazy(() =>
  import("../admin-component/dealership-blog/adding_tag")
);
const AddingCategory = React.lazy(() =>
  import("../admin-component/dealership-blog/adding_category")
);
const PostManagment = React.lazy(() =>
  import("../admin-component/dealership-blog/post_managment")
);
const AuthorManament = React.lazy(() =>
  import("../admin-component/dealership-blog/author_managment")
);
const Faq = React.lazy(() => import("../admin-component/dealership-blog/faq"));
const EditBlogPost = React.lazy(() =>
  import("../admin-component/dealership-blog/edit_post")
);
const EditFaq = React.lazy(() =>
  import("../admin-component/dealership-blog/edit_faq")
);
const Comments = React.lazy(() =>
  import("../admin-component/dealership-blog/comments")
);
// const DealerMessaging = React.lazy(() =>
//   import("../admin-component/messaging/dealer_messaging")
// );
const Dealer = React.lazy(() => import("../admin-component/dealer"));
const DealerRealTestDrive = React.lazy(() =>
  import("../admin-component/real-test-drive/dealer_real_test_drive")
);
const AddDealerRealTestDrive = React.lazy(() =>
  import("../admin-component/real-test-drive/add_dealer_real_test_drive")
);
const RealTestDriveContaxtProvider = React.lazy(() =>
  import("../../context/real_test_drive")
);

// const EditDealerRealTestDrive = React.lazy(() =>
//   import("../admin-component/real-test-drive/edit_dealer_real_test_drive")
// );
const LeadCenter = React.lazy(() => import("../admin-component/lead_center"));
const DealerVehicleManage = React.lazy(() =>
  import("../admin-component/vehicle/vehicle_manage")
);
const DealerVehicleManagement = React.lazy(() =>
  import("../admin-component/vehicle/dealer_vehicle_managment")
);
const EditVehicleManagement = React.lazy(() =>
  import("../admin-component/vehicle/edit_vehicle_management")
);
const EditVinNumber = React.lazy(() =>
  import("../admin-component/vehicle/parts/vin_edit")
);
const AddSeller = React.lazy(() =>
  import("../admin-component/seller/add_seller")
);
const SellerManage = React.lazy(() =>
  import("../admin-component/seller/seller_manage")
);
const DocuSignRedirect = React.lazy(() =>
  import("../admin-component/docusign_redirect")
);
const VehicleVendor = React.lazy(() =>
  import("../admin-component/vehicle/vehicle-vendor/vehicle_vendor")
);
const ChangePassword = React.lazy(() =>
  import("../admin-component/user-profile/user_change_password")
);
const MultiWindowSticker = React.lazy(() =>
  import("../admin-component/window-sticker/mutli_select_window_sticker")
);
const UserUpdateProfile = React.lazy(() =>
  import("../admin-component/user-profile/user_update_profile")
);
const UserUpdateExclusiveProfile = React.lazy(() =>
  import("../admin-component/user-profile/user_update_exclusive_profile")
);
const Pricing = React.lazy(() =>
  import("../admin-component/pricing/pricing_component")
);
const DelaerNegotiates = React.lazy(() =>
  import("../admin-component/negotitates-dealer/delaer_negotitates")
);
const DealerSingleDealPage = React.lazy(() =>
  import("../admin-component/negotitates-dealer/dealer_single_deal_page")
);
const DealerSingleInventoryInDealPage = React.lazy(() =>
  import(
    "../admin-component/negotitates-dealer/dealer_single_inventory_in_deal_page"
  )
);
// const DealerMidNegotiateVehiclePart = React.lazy(() =>
//   import(
//     "../admin-component/negotitates-dealer/dealer-nego-parts/mid_nego_vehicle_part"
//   )
// );
// const DealerMidNegotiatesTradeinVehicle = React.lazy(() =>
//   import(
//     "../admin-component/negotitates-dealer/dealer-nego-parts/mid_nego_tradein_vehicle"
//   )
// );
// const DealerNegotaitesFollowUp = React.lazy(() =>
//   import(
//     "../admin-component/negotitates-dealer/dealer-nego-parts/nego_follow_up"
//   )
// );

const SingleNotification = React.lazy(() =>
  import("../admin-component/notfication/single_notification")
);
const AllNotifications = React.lazy(() =>
  import("../../components/admin-component/notfication/all_notifications")
);
const WizardRapperForCampain = React.lazy(() =>
  import("../admin-component/email-camping/main_wizard_for_campain")
);
const EmailCampainState = React.lazy(() =>
  import("../../context/email_camping")
);
const CampainManagment = React.lazy(() =>
  import("../admin-component/email-camping/email_campain_managment")
);
const CampainEdit = React.lazy(() =>
  import("../admin-component/email-camping/edit_hole_campain")
);
const Features = React.lazy(() =>
  import("../admin-component/features/features")
);
const PrivateRoute = React.lazy(() => import("./private_route"));
const PackageProvider = React.lazy(() => import("../../context/package"));
const UserManagment = React.lazy(() =>
  import("../admin-component/user-managment/dealership_user_mangment")
);
const EditUser = React.lazy(() =>
  import("../admin-component/user-managment/dealership_edit_user")
);
const CustomerVehicleDeposite = React.lazy(() =>
  import("../admin-component/customer-deposite/customer_deposite")
);
const CustomerVehicleDepositeProvider = React.lazy(() =>
  import("../../context/customer_deposite")
);
const CustomerDepositeManagment = React.lazy(() =>
  import("../admin-component/customer-deposite/customer_deposite_managment")
);
const DealershipDepositeManagment = React.lazy(() =>
  import("../admin-component/vehicle/dealership_deposite_managment")
);
const DealershipWebPageManagment = React.lazy(() =>
  import("../admin-component/web-pages/dealership_web_page_managment")
);
// const BlogAndWebPageWapper = React.lazy(() =>
//   import("../admin-component/dealership-blog/blog_and_web_page_warpper")
// );
const DealershipAddWebPage = React.lazy(() =>
  import("../admin-component/web-pages/dealership_add_web_page")
);
const DealershipAEditWebPage = React.lazy(() =>
  import("../admin-component/web-pages/dealership_edit_web_page")
);

const UserTasks = React.lazy(() =>
  import("../admin-component/tasks/user_tasks")
);
const CustomerNegotiates = React.lazy(() =>
  import("../admin-component/negotiates-customer/customer_negotiates")
);

const DealershipBills = React.lazy(() =>
  import("../admin-component/bills/dealership_bills")
);
const AddUser = React.lazy(() =>
  import("../admin-component/user-managment/dealership_add_user")
);

const AddCustomerForSeller = React.lazy(() =>
  import("../seller-component/add-customer/add_custommer")
);
const BillNotInPerson = React.lazy(() =>
  import("../admin-component/bill-of-sell/bill_not_in_person")
);
const OfflineChat = React.lazy(() =>
  import("../admin-component/offline-chat/offline-chat")
);
const AllCustomers = React.lazy(() =>
  import("../admin-component/all-customers/all_customers")
);
const DealershipUsesrsTickets = React.lazy(() =>
  import("../admin-component/ticketing/dealership_users_tickets")
);
const CustomerProfiles = React.lazy(() =>
  import("../admin-component/all-customers/customer_profiles")
);
const CustomersLead = React.lazy(() =>
  import("../admin-component/all-customers/customers_lead")
);
const CustomersDeal = React.lazy(() =>
  import("../admin-component/all-customers/customers_deal")
);
const CustomersInventory = React.lazy(() =>
  import("../admin-component/all-customers/customers_inventory")
);
const CustomersBHPH = React.lazy(() =>
  import("../admin-component/all-customers/customers_bhph")
);
const CustomerMidNegotiatesTradeinVehicle = React.lazy(() =>
  import(
    "../admin-component/negotiates-customer/customer-nego-parts/customer_mid_nego_tradein_vehicle"
  )
);
const CustomerMidNegotiateVehiclePart = React.lazy(() =>
  import(
    "../admin-component/negotiates-customer/customer-nego-parts/customer_mid_nego_vehicle"
  )
);
const HillzForDealership = React.lazy(() =>
  import("../admin-component/hillz/hillz-for-dealership")
);

const EmailBodyTeaxt = React.lazy(() =>
  import("../admin-component/email-camping/email-text-body")
);
const PhysicalLeadMainWizard = React.lazy(() =>
  import(
    "../admin-component/admin-lead-center/physical-lead/physical_lead_main_wizard"
  )
);
const PhysicalLeadContaxtProvider = React.lazy(() =>
  import("../../context/physical_lead")
);
const BillOfSaleForSingleVehicleInventory = React.lazy(() =>
  import("../admin-component/vehicle/bill_of_sale_for_single_vehicle")
);
const VehicleManagementCaringlistTable = React.lazy(() =>
  import(
    "../admin-component/vehicle/vehicle-managment-parts/vehicle_managment_caringlist_table"
  )
);
const VehicleManagementFacebookTable = React.lazy(() =>
  import(
    "../admin-component/vehicle/vehicle-managment-parts/vehicle_management_face_book_table"
  )
);
const generalCost = React.lazy(() =>
  import("../admin-component/general-cost/generalCost")
);
const generalCostadd = React.lazy(() =>
  import("../admin-component/general-cost/addGeneralCost")
);
const ExtraCostPartAdd = React.lazy(() =>
  import("../admin-component/vehicle/managment-parts/extra/extra_cost_part_add")
);
const WholeSaleForSingleVehicleInventory = React.lazy(() =>
  import("../admin-component/vehicle/whole_sale")
);
const WholeSaleCustomer = React.lazy(() =>
  import("../admin-component/all-customers/whole_sale_customer")
);
const EditCustomer = React.lazy(() =>
  import("../admin-component/all-customers/edit_customer")
);
const EditTask = React.lazy(() => import("../admin-component/tasks/edit_task"));
const AllAcoountingTable = React.lazy(() =>
  import("../admin-component/accounting/allacoountingtable")
);
const GeneralAccountingTable = React.lazy(() =>
  import("../admin-component/accounting/generalaccountingtable")
);
const MoreInfoAccount = React.lazy(() =>
  import("../admin-component/accounting/more_info")
);
const Accounting = React.lazy(() =>
  import("../admin-component/accounting/accounting")
);
const Advertising = React.lazy(() =>
  import("../admin-component/advertising/advertising")
);
const IncomeInformation = React.lazy(() =>
  import("../admin-component/accounting/service-info/service_Information")
);
const MarketingLead = React.lazy(() =>
  import(
    "../admin-component/admin-lead-center/physical-lead/marketing_lead_form"
  )
);

const MarketCenter = React.lazy(() => import("../admin-component/market_lead"));
const Presets = React.lazy(() =>
  import("../admin-component/presets/user_update_presets")
);
const DealerPullBillOfSaleContaxtProvider = React.lazy(() =>
  import("../../context/dealerpull_bill_of_sale")
);
const DealerPullTabs = React.lazy(() =>
  import("../admin-component/negotitates-dealer/dealer-pull/dealerpull_tab")
);
const DelaerPullNegotiates = React.lazy(() =>
  import(
    "../admin-component/negotitates-dealer/dealer-pull/dealerpull_negotiates"
  )
);
const DetailVehicle = React.lazy(() =>
  import("../admin-component/vehicle/detail-vehicle/detail-vehicle")
);
const Promotion = React.lazy(() =>
  import("../admin-component/promotion/promotion")
);
const PurchaseFromPublic = React.lazy(() =>
  import("../admin-component/admin-lead-center/add_purches_publuic")
);
const AddedWanted = React.lazy(() =>
  import("../admin-component/admin-lead-center/added_wanted")
);
const AttachAndNote = React.lazy(() =>
  import("../admin-component/negotitates-dealer/attach_note_for_deal")
);
const GenerateReports = React.lazy(() =>
  import("../admin-component/reports/generate_reports")
);
const WriteCheque = React.lazy(() =>
  import("../admin-component/accounting/cheque/write_cheque")
);
const AddPurchesWholesale = React.lazy(() =>
  import("../admin-component/admin-lead-center/add_purches_wholesale")
);
const PurchaseFromDealerWholeSale = React.lazy(() =>
  import("../admin-component/vehicle/purchase_from_dealer_wholeSale")
);
const CustomerFollowUp = React.lazy(() =>
  import("../admin-component/all-customers/customer_follow_up")
);
const LeadFollowUp = React.lazy(() =>
  import("../admin-component/admin-lead-center/lead_followUp")
);
const DealerLeadCenterInfo = React.lazy(() =>
  import("../admin-component/admin-lead-center/dealer_lead_center_info")
);
const DealerLeadCenterActivities = React.lazy(() =>
  import("../admin-component/admin-lead-center/dealer_lead_center_activities")
);
const EditCustomerFollowUp = React.lazy(() =>
  import("../admin-component/all-customers/edit_customer_followUp")
);
const SendEmailLead = React.lazy(() =>
  import("../admin-component/admin-lead-center/see-leads-detaile/SendEmail")
);
const DepositForm = React.lazy(() =>
  import("../admin-component/vehicle/depositForm")
);
const SocialPostingList = React.lazy(() =>
  import("../admin-component/social-posting/social_posting_list")
);
const SentSocialPosting = React.lazy(() =>
  import("../admin-component/social-posting/sent_social_posting")
);
const HomeShop = React.lazy(() => import("../admin-component/shop/shop"));
const AddProduct = React.lazy(() =>
  import("../admin-component/shop/add_product_shop")
);
const EditProductManagment = React.lazy(() =>
  import("../admin-component/shop/edit_product_managment")
);
const QuickBooksClass = React.lazy(() =>
  import("../admin-component/quick-books/class/quick_books_class")
);
const QuickBooksTaxCode = React.lazy(() =>
  import("../admin-component/quick-books/tax-code/quick_books_tax_code")
);
const QuickBooksTaxAgency = React.lazy(() =>
  import("../admin-component/quick-books/tax-agency/quick_books_tax_agency")
);
const QuickBooksVendor = React.lazy(() =>
  import("../admin-component/quick-books/vendor/quick_books_vendor")
);
const QuickBooksItem = React.lazy(() =>
  import("../admin-component/quick-books/item/quick_books_item")
);
const QuickBooksSalesReceipt = React.lazy(() =>
  import(
    "../admin-component/quick-books/sales-receipt/quick_books_sales_receipt"
  )
);
const QuickBooksCustomer = React.lazy(() =>
  import("../admin-component/quick-books/customer/quick_books_customer")
);
const QuickBooksPurchase = React.lazy(() =>
  import("../admin-component/quick-books/purchase/quick_books_purchase")
);
const QuickBooksAccount = React.lazy(() =>
  import("../admin-component/quick-books/account-page/quick_books_account")
);

const PurchaseFromDealer = React.lazy(() =>
  import("../admin-component/admin-lead-center/purchase_from_dealer")
);
const MechanicShop = React.lazy(() =>
  import("../admin-component/vehicle/mechanic-shop/mechanic_shop")
);
const DeatilMechanicShop = React.lazy(() =>
  import(
    "../admin-component/vehicle/mechanic-shop/service_list_parts_mecanic_shop/deatil_mechanicShop"
  )
);

const AdminRouter = () => {
  const auth = useIsAuthState();
  const {
    components: { ds_components, role_components },
  } = auth;

  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  useEffect(() => {
    if (
      auth?.dealershipInfo?.redirect_url &&
      auth?.dealershipInfo?.redirect_url !== null &&
      auth?.ds_status === 6
    ) {
      setTimeout(() => {
        history.push(auth?.dealershipInfo?.redirect_url);
      }, 500);
    }
  }, [auth, history]);

  if (auth.ds_status === 3 && auth?.dealershipInfo?.redirect_url === null) {
    if (pathname !== "/admin") {
      return <Redirect to="/admin" />;
    }
    return (
      <Suspense fallback={() => <Loading />}>
        <div className="p-0 full-main-page">
          <PrivateRoute
            isAuth={auth.isLoggedIn}
            exact
            path="/admin"
            component={Dealer}
          />
        </div>
      </Suspense>
    );
  } else if (
    (auth.ds_status === 4 || auth.ds_status === 8 || auth.ds_status === 9) &&
    auth?.dealershipInfo?.redirect_url === null
  ) {
    if (pathname !== "/admin/pricing") {
      return <Redirect to="/admin/pricing" />;
    }
    return (
      <Suspense fallback={() => <Loading />}>
        <div className="p-0 full-main-page">
          <PackageProvider>
            <PrivateRoute
              isAuth={auth.isLoggedIn}
              exact
              path="/admin/pricing"
              component={() => (
                <Pricing
                  updateMode={auth.ds_status === 8 || auth.ds_status === 9}
                />
              )}
            />
          </PackageProvider>
        </div>
      </Suspense>
    );
  }
  if (
    location.pathname === "/" &&
    auth.ds_status > 4 &&
    auth?.dealershipInfo?.redirect_url === null
  ) {
    return <Redirect to="/admin" />;
  }
  return (
    <div className="p-0 full-main-page">
      <Suspense fallback={<Loading />}>
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "Dealer"
          )}
          exact
          path="/admin"
          component={
            // role_components.indexOf("Dealer") !== -1
            //   ?
            Dealer
            // : () => <Redirect to="/admin" />
          }
        />

        {/* <PrivateRoute
        isAuth={auth.isLoggedIn}
        isDisabled={
          ds_components.indexOf("DealerMessaging") === -1 &&
          role_components.indexOf("DealerMessaging") !== -1
            ? true
            : false
        }
        exact
        path="/admin/dealer-messaging"
        component={
          role_components.indexOf("DealerMessaging") !== -1
            ? DealerMessaging
            : () => <Redirect to="/admin" />
        }
        /> */}
        <PackageProvider>
          <PrivateRoute
            isAuth={auth.isLoggedIn}
            exact
            path="/admin/pricing"
            component={Pricing}
          />
        </PackageProvider>
        {/* <PrivateRoute
        isAuth={auth.isLoggedIn}
        isDisabled={getDisabledComponent(
          { ds_components, role_components },
          "DealerRealTestDrive"
        )}
        exact
        path="/admin/dealer-real-test-drive"
        component={
          role_components.indexOf("DealerRealTestDrive") !== -1
            ? DealerRealTestDrive
            : () => <Redirect to="/admin" />
        }
       /> */}
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealerVehicleManage"
          )}
          exact
          path="/admin/add-vehicle"
          component={
            // role_components.indexOf("DealerVehicleManage") !== -1
            //   ?
            DealerVehicleManage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealerVehicleManagement"
          )}
          exact
          path="/admin/vehicle-managment"
          component={
            // role_components.indexOf("DealerVehicleManagement") !== -1
            //   ?
            DealerVehicleManagement
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditVehicleManagement"
          )}
          exact
          path="/admin/edit-vehicle-managment/:id"
          component={
            // role_components.indexOf("EditVehicleManagement") !== -1
            //   ?
            EditVehicleManagement
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "VehicleVendor"
          )}
          exact
          path="/admin/vehicle-vendor"
          component={
            // role_components.indexOf("VehicleVendor") !== -1
            //   ?
            VehicleVendor
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "VehicleVendor"
          )}
          exact
          path="/admin/vehicle-vendor/:id"
          component={
            // role_components.indexOf("VehicleVendor") !== -1
            //   ?
            VehicleVendor
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AddSeller"
          )}
          exact
          path="/admin/add-seller"
          component={
            // role_components.indexOf("AddSeller") !== -1
            //   ?
            AddSeller
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "SellerManage"
          )}
          exact
          path="/admin/seller-manage"
          component={
            // role_components.indexOf("SellerManage") !== -1
            //   ?
            SellerManage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditVinNumber"
          )}
          exact
          path="/admin/edit-vehicle"
          component={
            // role_components.indexOf("EditVinNumber") !== -1
            //   ?
            EditVinNumber
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "UserUpdateProfile"
          )}
          exact
          path="/admin/update-profile"
          component={
            // role_components.indexOf("UserUpdateProfile") !== -1
            //   ?
            UserUpdateProfile
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "UserUpdateExclusiveProfile"
          // )}
          exact
          path="/admin/update-bussiness-profile"
          component={
            // role_components.indexOf("UserUpdateExclusiveProfile") !== -1
            //   ?
            UserUpdateExclusiveProfile
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "ChangePassword"
          )}
          exact
          path="/admin/change-password"
          component={
            // role_components.indexOf("ChangePassword") !== -1
            //   ?
            ChangePassword
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "MultiWindowSticker"
          )}
          exact
          path="/admin/window-sticker"
          component={
            // role_components.indexOf("MultiWindowSticker") !== -1
            //   ?
            MultiWindowSticker
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "LeadCenter"
          // )}
          exact
          path="/lead-center"
          component={
            // role_components.indexOf("LeadCenter") !== -1
            //   // ?
            LeadCenter
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DocuSignRedirect"
          )}
          exact
          path="/admin/doc/:x/:y"
          component={
            // role_components.indexOf("DocuSignRedirect") !== -1
            //   ?
            DocuSignRedirect
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealerRealTestDrive"
          )}
          exact
          path="/admin/dealer-real-test-drive"
          component={
            // role_components.indexOf("DealerRealTestDrive") !== -1
            //   ?
            DealerRealTestDrive
            // : () => <Redirect to="/admin" />
          }
        />
        {/* <PrivateRoute
        isAuth={auth.isLoggedIn}
        isDisabled={getDisabledComponent(
          { ds_components, role_components },
          "LeadCenter"
        )}
        exact
        path="/lead-center"
        component={
          role_components.indexOf("LeadCenter") !== -1
            ? LeadCenter
            : () => <Redirect to="/admin" />
        }
       /> */}

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AllNotifications"
          )}
          exact
          path="/admin/notfication/"
          component={
            // role_components.indexOf("AllNotifications") !== -1
            //   ?
            AllNotifications
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "SingleNotification"
          )}
          exact
          path="/admin/notfication/:id"
          component={
            // role_components.indexOf("SingleNotification") !== -1
            //   ?
            SingleNotification
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "WizardRapperForCampain"
          )}
          exact
          path="/admin/email-sms-section"
          component={
            // role_components.indexOf("WizardRapperForCampain") === -1
            //   ?
            (props) => (
              <EmailCampainState>
                <WizardRapperForCampain {...props} />
              </EmailCampainState>
            )
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "CampainManagment"
          )}
          exact
          path="/admin/campain-managment"
          component={
            // role_components.indexOf("CampainManagment") !== -1
            // ?
            CampainManagment
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "CampainEdit"
          )}
          exact
          path="/admin/edit-campaign/:id"
          component={
            // role_components.indexOf("CampainEdit") !== -1
            // ?
            CampainEdit
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "ContractManagement"
          )}
          // exact
          path="/admin/contract-management"
          component={
            // role_components.indexOf("ContractManagement") !== -1
            // ?
            ContractManagement
            // : () => <Redirect to="/admin" />
          }
        />
        {/* <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "ContractManagement"
          )}
          exact
          path="/admin/contract-management"
          component={
            role_components.indexOf("ContractManagement") !== -1
              ? ContractManagement
              : () => <Redirect to="/admin" />
          }
        /> */}
        {/* <Route exact path="/admin/test" component={ContractManagement} /> */}
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/task-managment"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealershipTaskManagment"
          )}
          component={
            // role_components.indexOf("DealershipTaskManagment") !== -1
            //   ?
            DealershipTaskManagment
            // : () => <Redirect to="/admin" />
          }
        />
        {/* <PrivateRoute
        isAuth={auth.isLoggedIn}
        exact
        path="/blog/add-post"
        isDisabled={getDisabledComponent(
          { ds_components, role_components },
          "AddingBlogPost"
        )}
        component={
          role_components.indexOf("AddingBlogPost") !== -1
            ? AddingBlogPost
            : () => <Redirect to="/admin" />
        }
       /> */}
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/add-tag"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AddingBlogtag"
          )}
          component={
            // role_components.indexOf("AddingBlogtag") !== -1
            // ?
            AddingBlogtag
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/add-category"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AddingCategory"
          )}
          component={
            // role_components.indexOf("AddingCategory") !== -1
            // ?
            AddingCategory
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "PostManagment"
          )}
          component={
            // role_components.indexOf("PostManagment") !== -1
            // ?
            PostManagment
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/author-managment"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AuthorManament"
          )}
          component={
            // role_components.indexOf("AuthorManament") !== -1
            // ?
            AuthorManament
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/edit-post/:id"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditBlogPost"
          )}
          component={
            // role_components.indexOf("EditBlogPost") !== -1
            // ?
            EditBlogPost
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/faq/:id"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "Faq"
          )}
          component={
            // role_components.indexOf("Faq") !== -1
            // ?
            Faq
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/edit-faq/:id"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditFaq"
          )}
          component={
            // role_components.indexOf("EditFaq") !== -1
            // ?
            EditFaq
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/comments"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "Comments"
          )}
          component={
            // role_components.indexOf("Comments") !== -1
            // ?
            Comments
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/customer/vehicle/deposite"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "CustomerVehicleDeposite"
          )}
          component={
            // role_components.indexOf("CustomerVehicleDeposite") !== -1
            // ?
            (props) => (
              <CustomerVehicleDepositeProvider>
                <CustomerVehicleDeposite {...props} />
              </CustomerVehicleDepositeProvider>
            )

            // : () => <Redirect to="/admin" />
          }
        />
        {/* <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/user-ticket"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "UserTicket"
          // )}
          component={
            // role_components.indexOf("UserTicket") !== -1
            // ?
            UserTicket
            // : () => <Redirect to="/admin" />
          }
        /> */}
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/user-managment"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "UserManagment"
          )}
          component={
            // role_components.indexOf("UserManagment") !== -1
            // ?
            UserManagment
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/ticket-manage/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "TicketReplays"
          // )}
          component={
            // role_components.indexOf("TicketReplays") !== -1
            // ?
            TicketReplays
            // : () => <Redirect to="/admin" />
          }
        />
        {/* <Route component={NotFound} /> */}
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/edit-user/:id"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditUser"
          )}
          component={
            // role_components.indexOf("EditUser") !== -1
            // ?
            EditUser
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/customer/deposites"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "CustomerDepositeManagment"
          )}
          component={
            // role_components.indexOf("CustomerDepositeManagment") !== -1
            // ?
            CustomerDepositeManagment
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/deposites"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealershipDepositeManagment"
          )}
          component={
            // role_components.indexOf("DealershipDepositeManagment") !== -1
            // ?
            DealershipDepositeManagment
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/web-page/management"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealershipWebPageManagment"
          )}
          component={
            // role_components.indexOf("DealershipWebPageManagment") !== -1
            // ?
            DealershipWebPageManagment
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/web-page/add"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealershipAddWebPage"
          )}
          component={
            // role_components.indexOf("DealershipAddWebPage") !== -1
            // ?
            DealershipAddWebPage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/web-page/edit/:id"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealershipAEditWebPage"
          )}
          component={
            // role_components.indexOf("DealershipAEditWebPage") !== -1
            // ?
            DealershipAEditWebPage
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/user-tasks"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "UserTasks"
          )}
          component={
            // role_components.indexOf("UserTasks") !== -1
            // ?
            UserTasks
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/bills"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealershipBills"
          )}
          component={
            // role_components.indexOf("DealershipBills") !== -1
            // ?
            DealershipBills
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/add-post"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AddingBlogPost"
          )}
          component={
            // role_components.indexOf("AddingBlogPost") !== -1
            // ?
            AddingBlogPost
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/bill-of-sell/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSell"
          // )}
          component={
            // role_components.indexOf("BillOfSell") !== -1
            // ?
            BillOfSell
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/features"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "Features"
          // )}
          component={
            // role_components.indexOf("Features") !== -1
            // ?
            Features
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/add-user"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AddUser"
          )}
          component={
            // role_components.indexOf("AddUser") !== -1
            // ?
            AddUser
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/seller-add-customer"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AddCustomerForSeller"
          // )}
          component={
            // role_components.indexOf("AddCustomerForSeller") !== -1
            // ?
            AddCustomerForSeller
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/bill-not-in-person"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillNotInPerson"
          // )}
          component={
            // role_components.indexOf("BillNotInPerson") !== -1
            // ?
            BillNotInPerson
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/web-page/add"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealershipAddWebPage"
          )}
          component={
            // role_components.indexOf("DealershipAddWebPage") !== -1
            // ?
            DealershipAddWebPage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/blog/web-page/edit/:id"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "DealershipAEditWebPage"
          )}
          component={
            // role_components.indexOf("DealershipAEditWebPage") !== -1
            // ?
            DealershipAEditWebPage
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "DelaerNegotiates"
          // )}
          exact
          path="/admin/dealer-negotiates"
          component={
            // role_components.indexOf("DelaerNegotiates") !== -1
            // ?
            DelaerNegotiates
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "DealerSingleDealPage"
          // )}
          exact
          path="/admin/dealer-negotiates/deal/:id"
          component={
            // role_components.indexOf("DealerSingleDealPage") !== -1
            // ?
            DealerSingleDealPage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "DealerSingleInventoryInDealPage"
          // )}
          exact
          path="/admin/dealer-negotiates/deal/inventory/:id"
          component={
            // role_components.indexOf("DealerSingleInventoryInDealPage") !== -1
            // ?
            DealerSingleInventoryInDealPage
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerNegotiates"
          // )}
          exact
          path="/admin/customer-negotiates"
          component={
            // role_components.indexOf("CustomerNegotiates") !== -1
            // ?
            CustomerNegotiates
            // : () => <Redirect to="/admin" />
          }
        />
        {/* <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={
          //   // getDisabledComponent(
          //   //   { ds_components, role_components },
          //   //   "SellerNegotiates"
          //   // )
          //   1
          // }
          exact
          path="/admin/seller-negotiates"
          component={
            // role_components.indexOf("SellerNegotiates") !== -1
            // ?
            SellerNegotiates
            // : () => <Redirect to="/admin" />
          }
        /> */}

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/offline-chat/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "OfflineChat"
          // )}
          component={
            // role_components.indexOf("OfflineChat") !== -1
            // ?
            OfflineChat
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AllCustomers"
          // )}
          component={
            // role_components.indexOf("AllCustomers") !== -1
            // ?
            AllCustomers
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/dealership-ticket"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "DealershipUsesrsTickets"
          // )}
          component={
            // role_components.indexOf("DealershipUsesrsTickets") !== -1
            // ?
            DealershipUsesrsTickets
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/tickets/user/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "DealershipsTicket"
          // )}
          component={
            // role_components.indexOf("DealershipsTicket") !== -1
            // ?
            DealershipsTicket
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomerProfiles
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/Leads/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomersLead
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/Deals/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomersDeal
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/Inventory/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomersInventory
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/BHPH/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomersBHPH
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/customer-negotiates/tradein/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerMidNegotiatesTradeinVehicle"
          // )}
          component={
            // role_components.indexOf("CustomerMidNegotiatesTradeinVehicle") !== -1
            // ?
            CustomerMidNegotiatesTradeinVehicle
            // : () => <Redirect to="/admin/" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/customer-negotiates/inventories/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerMidNegotiateVehiclePart"
          // )}
          component={
            // role_components.indexOf("CustomerMidNegotiateVehiclePart") !== -1
            // ?
            CustomerMidNegotiateVehiclePart
            // : () => <Redirect to="/admin/" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/hillz"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "HillzForDealership"
          // )}
          component={
            // role_components.indexOf("HillzForDealership") !== -1
            // ?
            HillzForDealership
            // : () => <Redirect to="/admin/" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-cars"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AllDealershipUsersCars"
          // )}
          component={
            // role_components.indexOf("AllDealershipUsersCars") !== -1
            // ?
            AllDealershipUsersCars
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AddDealerRealTestDrive"
          )}
          exact
          path="/admin/dealer-real-test-drive/add"
          component={
            // role_components.indexOf("AddDealerRealTestDrive") !== -1
            // ?
            (props) => (
              <RealTestDriveContaxtProvider>
                <AddDealerRealTestDrive />
              </RealTestDriveContaxtProvider>
            )
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/email-text-body"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AllDealershipUsersCars"
          // )}
          component={
            // role_components.indexOf("AllDealershipUsersCars") !== -1
            // ?
            EmailBodyTeaxt
            // : () => <Redirect to="/admin" />
          }
        />
        {/* <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditDealerRealTestDrive"
          )}
          exact
          path="/admin/dealer-real-test-drive/edit/:id"
          component={
            // role_components.indexOf("EditDealerRealTestDrive") !== -1
            // ?
            EditDealerRealTestDrive
            // : () => <Redirect to="/admin" />
          }
        /> */}
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "PhysicalLeadMainWizard"
          // )}
          exact
          path="/lead/add/physical-lead"
          component={
            // role_components.indexOf("PhysicalLeadMainWizard") !== -1
            // ?
            (props) => (
              <PhysicalLeadContaxtProvider>
                <PhysicalLeadMainWizard />
              </PhysicalLeadContaxtProvider>
            )
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/vehicle-managment/single-vehicle/deal/:id"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            (props) => <BillOfSaleForSingleVehicleInventory />
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/pricing/update-card"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            (props) => <Pricing updateMode />
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/advertisment/craigslist"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            VehicleManagementCaringlistTable
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/advertisment/facebook"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            VehicleManagementFacebookTable
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/extra/cost/add/:id"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            (props) => <ExtraCostPartAdd {...props} />
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/general-cost"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            generalCost
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/add-cost"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            generalCostadd
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/vehicle-managment/whole-sale/deal/:id"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            (props) => <WholeSaleForSingleVehicleInventory />
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditVehicleManagement"
          )}
          exact
          path="/admin/whole_sale_customer"
          component={WholeSaleCustomer}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/edit-customer/:id"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "EditCustomer"
          )}
          component={
            // role_components.indexOf("EditCustomer") !== -1
            // ?
            EditCustomer
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/chat"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => (
            // <SocketProvider>
            <Chat />
            // </SocketProvider>
          )}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/advertising"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={Advertising}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/task-managment/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <EditTask />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/accounting"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            Accounting
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/accounting/more"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            MoreInfoAccount
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/accounting/allacoounting"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            AllAcoountingTable
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/accounting/generalacoounting"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            GeneralAccountingTable
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/accounting/allIncome"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            IncomeInformation
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/lead/add/marketing"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            MarketingLead
            // : () => <Redirect to="/admin" />
          }
        />
        {/* <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "LeadCenter"
          // )}
          exact
          path="/market-center"
          component={
            // role_components.indexOf("LeadCenter") !== -1
            //   // ?
            MarketCenter
            // : () => <Redirect to="/admin" />
          }
        /> */}
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "Presets"
          )}
          exact
          path="/admin/presets"
          component={
            // role_components.indexOf("UserUpdateProfile") !== -1
            //   ?
            Presets
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/new-dealerpull-negotiates"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AllDealershipUsersCars"
          // )}
          component={
            // role_components.indexOf("AllDealershipUsersCars") !== -1
            // ?
            (props) => (
              <DealerPullBillOfSaleContaxtProvider>
                <DealerPullTabs {...props} />
              </DealerPullBillOfSaleContaxtProvider>
            )
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/dealerpull-negotiates"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AllDealershipUsersCars"
          // )}
          component={
            // role_components.indexOf("AllDealershipUsersCars") !== -1
            // ?
            DelaerPullNegotiates
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/vehicle-detail/:id"
          component={
            // role_components.indexOf("LeadCenter") !== -1
            //   // ?
            DetailVehicle
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/edit-dealerpull-negotiates/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AllDealershipUsersCars"
          // )}
          component={
            // role_components.indexOf("AllDealershipUsersCars") !== -1
            // ?
            (props) => (
              <DealerPullBillOfSaleContaxtProvider>
                <DealerPullTabs {...props} />
              </DealerPullBillOfSaleContaxtProvider>
            )
            // : () => <Redirect to="/admin" />
          }
        />
        <DndProvider backend={HTML5Backend}>
          <PrivateRoute
            isAuth={auth.isLoggedIn}
            isDisabled={getDisabledComponent(
              { ds_components, role_components },
              "Promotion"
            )}
            exact
            path="/admin/promotion"
            component={
              // role_components.indexOf("UserUpdateProfile") !== -1
              //   ?
              Promotion
              // : () => <Redirect to="/admin" />
            }
          />
        </DndProvider>
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/reports-generate"
          component={(props) => <GenerateReports {...props} />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "PurchaseFromPublic"
          )}
          exact
          path="/admin/purche-from-public"
          component={
            // role_components.indexOf("UserUpdateProfile") !== -1
            //   ?
            PurchaseFromPublic
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "PurchaseFromPublic"
          )}
          exact
          path="/admin/added-wanted"
          component={AddedWanted}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AddPurchesWholesale"
          // )}
          exact
          path="/admin/Purchase-from-wholesale"
          component={
            // role_components.indexOf("UserUpdateProfile") !== -1
            //   ?
            AddPurchesWholesale
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AttachAndNote"
          )}
          exact
          path="/admin/deals_note/:id"
          // component={
          //   // role_components.indexOf("UserUpdateProfile") !== -1
          //   //   ?
          //   AttachAndNote
          //   // : () => <Redirect to="/admin" />
          // }
          component={(props) => <AttachAndNote {...props} />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "WriteCheque"
          )}
          exact
          path="/admin/write_cheque"
          component={
            // role_components.indexOf("UserUpdateProfile") !== -1
            //   ?
            WriteCheque
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "BillOfSaleForSingleVehicleInventory"
          // )}
          exact
          path="/admin/purchases/from-dealer/:id"
          component={
            // role_components.indexOf("BillOfSaleForSingleVehicleInventory") !== -1
            // ?
            (props) => <PurchaseFromDealerWholeSale />
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/Follow-up/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            CustomerFollowUp
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/lead-center/follow-up/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            LeadFollowUp
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/lead-center/info/:id/:leadType"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            DealerLeadCenterInfo
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/lead-center/activities/:id/:leadType"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerProfiles"
          // )}
          component={
            // role_components.indexOf("CustomerProfiles") !== -1
            // ?
            DealerLeadCenterActivities
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/all-customers/edit/Follow-up/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <EditCustomerFollowUp />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/lead-center/Messages/:id/:leadType"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <SendEmailLead />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/facebook/succeded"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <CloseWindow />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/vehicle-managment/deposit-form/deal/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <DepositForm />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/social-posting"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <SentSocialPosting />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/social-posting/history"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <SocialPostingList />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/shop"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <HomeShop />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/add-product"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <AddProduct />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/edit-product-managment/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <EditProductManagment />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/social-posting"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <SocialPostingList />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/account"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksAccount />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/class"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksClass />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/tax-code"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksTaxCode />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/tax-agency"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksTaxAgency />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/vendor"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksVendor />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/item"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksItem />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/sales-receipt"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksSalesReceipt />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/purchase"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksPurchase />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/quick-books/customer"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "EditCustomer"
          // )}
          component={() => <QuickBooksCustomer />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "PurchaseFromDealer"
          )}
          exact
          path="/admin/purchase-from-dealer"
          component={
            // role_components.indexOf("UserUpdateProfile") !== -1
            //   ?
            PurchaseFromDealer
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "MechanicShop"
          )}
          exact
          path="/admin/mechanic-shop"
          // component={
          //   // role_components.indexOf("UserUpdateProfile") !== -1
          //   //   ?
          //   AttachAndNote
          //   // : () => <Redirect to="/admin" />
          // }
          component={(props) => <MechanicShop {...props} />}
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "MacanicShopServiceDetail"
          )}
          exact
          path="/admin/mechanic-shop/service/:id"
          // component={
          //   // role_components.indexOf("UserUpdateProfile") !== -1
          //   //   ?
          //   AttachAndNote
          //   // : () => <Redirect to="/admin" />
          // }
          component={(props) => <DeatilMechanicShop {...props} />}
        />
      </Suspense>
    </div>
  );
  // }

  // history.replace("/signin");
};

export default AdminRouter;
