import SimpleLoading from "./simple_loading";

const Loading = ({ height = "60vh" }) => {
  // const {height:"60vh"} = props
  return (
    <div
      className="d-flex justify-content-center align-items-center p-5"
      style={{ height: height }}
    >
      <SimpleLoading />
    </div>
  );
};

export default Loading;
