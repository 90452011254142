import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import { useIsAuthState } from "../../hooks/useAuth";
import { getDisabledComponent } from "../../utils/auth/get_disabled_component";
import CustomerVehicleDepositeProvider from "../../context/customer_deposite";
import Loading from "../common/loading/loading";

const ChangePassword = React.lazy(() =>
  import("../admin-component/user-profile/user_change_password")
);

const UserUpdateProfile = React.lazy(() =>
  import("../admin-component/user-profile/user_update_profile")
);
// const Pricing = React.lazy(() =>
//   import("../admin-component/pricing/pricing_component")
// );
// const Dealer = React.lazy(() => import("../admin-component/dealer"));

const CustomerNegotiates = React.lazy(() =>
  import("../admin-component/negotiates-customer/customer_negotiates")
);

const SingleNotification = React.lazy(() =>
  import("../admin-component/notfication/single_notification")
);
const AllNotifications = React.lazy(() =>
  import("../../components/admin-component/notfication/all_notifications")
);
const OfflineChat = React.lazy(() =>
  import("../admin-component/offline-chat/offline-chat")
);
const PrivateRoute = React.lazy(() => import("./private_route"));
const CustomerFavoriteVehicles = React.lazy(() =>
  import(
    "../admin-component/customer-favorite-vehicles/customer_favorite_vahicles"
  )
);
const CustomerVehicleDeposite = React.lazy(() =>
  import("../admin-component/customer-deposite/customer_deposite")
);
const CustomerSinglePage = React.lazy(() =>
  import("../admin-component/negotiates-customer/customer_single_deal_page")
);
const CustomerSingleInventoryInDealPage = React.lazy(() =>
  import(
    "../admin-component/negotiates-customer/customer_single_inventory_in_deal_page"
  )
);
// const cook = new Cookies();
const CustomerRouter = () => {
  const auth = useIsAuthState();
  const {
    components: { ds_components, role_components },
  } = auth;

  return (
    <div className="p-0 full-main-page ">
      <Suspense fallback={() => <Loading />}>
        {/* <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "Dealer"
          )}
          exact
          path="/admin"
          component={
            role_components.indexOf("Dealer") !== -1
              ? Dealer
              : () => <Redirect to="/admin" />
          }
        /> */}

        {/* <PrivateRoute
        isAuth={auth.isLoggedIn}
        isDisabled={
          ds_components.indexOf("DealerMessaging") === -1 &&
          role_components.indexOf("DealerMessaging") !== -1
            ? true
            : false
        }
        exact
        path="/admin/dealer-messaging"
        component={
          role_components.indexOf("DealerMessaging") !== -1
            ? DealerMessaging
            : () => <Redirect to="/admin" />
        }
      /> */}

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "UserUpdateProfile"
          // )}
          exact
          path="/admin/update-profile"
          component={
            //   role_components.indexOf("UserUpdateProfile") !== -1
            // ?
            UserUpdateProfile
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "ChangePassword"
          // )}
          exact
          path="/admin/change-password"
          component={
            //   role_components.indexOf("ChangePassword") !== -1
            // ?
            ChangePassword
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerNegotiates"
          // )}
          exact
          path="/admin/customer-negotiates"
          component={
            // role_components.indexOf("CustomerNegotiates") !== -1
            // ?
            CustomerNegotiates
            // : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "AllNotifications"
          )}
          exact
          path="/admin/notfication/"
          component={
            role_components.indexOf("AllNotifications") !== -1
              ? AllNotifications
              : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "SingleNotification"
          )}
          exact
          path="/admin/notfication/:id"
          component={
            role_components.indexOf("SingleNotification") !== -1
              ? SingleNotification
              : () => <Redirect to="/admin" />
          }
        />

        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/offline-chat/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "OfflineChat"
          // )}
          component={
            // role_components.indexOf("OfflineChat") !== -1
            // ?
            OfflineChat
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/customer/favorite-vehicles"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "CustomerFavoriteVehicles"
          )}
          component={
            // role_components.indexOf("CustomerFavoriteVehicles") !== -1
            // ?
            CustomerFavoriteVehicles
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/customer/vehicle/deposite"
          isDisabled={getDisabledComponent(
            { ds_components, role_components },
            "CustomerVehicleDeposite"
          )}
          component={
            // role_components.indexOf("CustomerVehicleDeposite") !== -1
            // ?
            (props) => (
              <CustomerVehicleDepositeProvider>
                <CustomerVehicleDeposite {...props} />
              </CustomerVehicleDepositeProvider>
            )

            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "SellerSingleDealPage"
          // )}
          exact
          path="/admin/customer-negotiates/deal/:id"
          component={
            // role_components.indexOf("SellerSingleDealPage") !== -1
            // ?
            CustomerSinglePage

            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "CustomerSingleInventoryInDealPage"
          // )}
          exact
          path="/admin/customer-negotiates/deal/inventory/:id"
          component={
            // role_components.indexOf("CustomerSingleInventoryInDealPage") !== -1
            // ?
            CustomerSingleInventoryInDealPage
            // : () => <Redirect to="/admin" />
          }
        />
      </Suspense>
    </div>
  );
  // }

  // history.replace("/signin");
};

export default CustomerRouter;
