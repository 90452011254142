import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import { useIsAuthState } from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import Loading from "../common/loading/loading";

const ChangePassword = React.lazy(() =>
  import("../admin-component/user-profile/user_change_password")
);
const PrivateRoute = React.lazy(() => import("./private_route"));
const DealerShipRegistrationRequest = React.lazy(() =>
  import("../hillz-component/hillz_registeration")
);
const AddNewDealership = React.lazy(() =>
  import("../hillz-component/add_new_dealership")
);
const HillzDealershipSiteSetting = React.lazy(() =>
  import("../hillz-component/dealerships-site/dealerships_site_setting")
);
const HillzDealershipPayments = React.lazy(() =>
  import("../hillz-component/payments/payment_list_dealerships")
);
const HillzDealershipPaymentStatus = React.lazy(() =>
  import("../hillz-component/payments/dealership_payment_status")
);
const HillzBandUsers = React.lazy(() =>
  import("../hillz-component/baned/hillz_band_users")
);

const HillzRoute = () => {
  const auth = useIsAuthState();

  const location = useLocation();
  if (location.pathname === "/admin") {
    return <Redirect to="/register" />;
  }
  return (
    <div className="p-3 full-main-page">
      <Suspense fallback={() => <Loading />}>
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/register"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "DealerShipRegistrationRequest"
          // )}
          component={
            // role_components.indexOf("DealerShipRegistrationRequest") !== -1
            // ?
            DealerShipRegistrationRequest
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/add-dealership"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "AddNewDealership"
          // )}
          component={
            // role_components.indexOf("AddNewDealership") !== -1
            // ?
            AddNewDealership
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/admin/change-password"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "ChangePassword"
          // )}
          component={
            // role_components.indexOf("ChangePassword") !== -1
            // ?
            ChangePassword
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/register/site-setting/:id"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "HillzDealershipSiteSetting"
          // )}
          component={
            // role_components.indexOf("HillzDealershipSiteSetting") !== -1
            // ?
            HillzDealershipSiteSetting
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/dealerships-payments"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "HillzDealershipSiteSetting"
          // )}
          component={
            // role_components.indexOf("HillzDealershipSiteSetting") !== -1
            // ?
            HillzDealershipPayments
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/dealerships-payments-status"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "HillzDealershipSiteSetting"
          // )}
          component={
            // role_components.indexOf("HillzDealershipSiteSetting") !== -1
            // ?
            HillzDealershipPaymentStatus
            // : () => <Redirect to="/admin" />
          }
        />
        <PrivateRoute
          isAuth={auth.isLoggedIn}
          exact
          path="/dealerships-users"
          // isDisabled={getDisabledComponent(
          //   { ds_components, role_components },
          //   "HillzDealershipSiteSetting"
          // )}
          component={
            // role_components.indexOf("HillzDealershipSiteSetting") !== -1
            // ?
            HillzBandUsers
            // : () => <Redirect to="/admin" />
          }
        />
      </Suspense>
    </div>
  );
  // }

  // history.replace("/signin");
};

export default HillzRoute;
