import { ErrorBoundary } from "react-error-boundary";
import { SendErrorToserverForLog } from "../../../utils/common/error_log_sender";
import ErrorBoundaryFallback from "./error_boundary_fallback";
import * as Sentry from "@sentry/react";
const ErrorBoundaryComp = (props) => {
  const { children, keys = null, Fallback = ErrorBoundaryFallback } = props;
  return (
    <ErrorBoundary
      onError={async (error, info) =>
        await SendErrorToserverForLog(error, info)
      }
      resetKeys={[keys]}
      FallbackComponent={Fallback}
      // fallback={Fallback}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryComp;
