import React from "react";

const DepositDetails = ({ formik }) => {
  return (
    <div className="w-100 row px-0 m-0 pt-0 pb-3">
      <div className="p-0 m-0 w-100 py-2 d-flex justify-content-between align-items-center">
        <h2 className="p-0 m-0 mx-2">DEPOSIT DETAILS</h2>
      </div>
      <div className="p-0 m-0 w-100 row p-2">
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Deposit Amount </label>
          <input
            id="depositAmount"
            name="depositAmount"
            placeholder="depositAmount"
            type="text"
            className="form-control rounded rounded-pill form_input-style mt-1 "
            value={formik.values.depositAmount}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Deposit Date</label>
          <input
            type="date"
            className="form-control rounded rounded-pill form_input-style mt-1 "
            id="depositDate"
            name="depositDate"
            value={formik.values.depositDate}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            min="1970-01-01"
                            max="9999-01-01"
          />
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Transaction ID/CHEQUE Number</label>
          <input
            id="transaction"
            name="transaction"
            placeholder="transaction"
            type="text"
            className="form-control rounded rounded-pill form_input-style mt-1 "
            value={formik.values.transaction}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Payment Method</label>
          <select
            id="paymentMethod"
            name="paymentMethod"
            placeholder="Payment Method"
            className="form-select rounded rounded-pill form_input-style mt-1 "
            value={formik.values.paymentMethod}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <option value="">Choose...</option>
            <option value="Credit-Card">Credit Card</option>
            <option value="Debit-Card">Debit Card</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
            <option value="Etransfer">Etransfer</option>
            <option value="Others">Others</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default DepositDetails;
