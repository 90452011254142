import { ApiCallGetMethod } from "../../../constant/base"

export const getAllCities = () => {
  return ApiCallGetMethod("city/get/all/info")
}

export const getAllProvinces = () => {
  return ApiCallGetMethod("province/getall")
}

export const getAllCitiesV2 = () => {
  return ApiCallGetMethod("city/v2/get/all/info")
}
