import { useContext, createContext, useReducer } from "react";
import * as types from "../constant/initial_state";

const BasketState = createContext();
const BasketDispatch = createContext();
const basketReducer = (state, action) => {
  switch (action.type) {
    case types.ADD_TO_BASKET:
      return { ...state, basketItem: [action.payload] };
    case types.ADD_DEPENDECE_TO_BASKET:
      return {
        ...state,
        basketDependedItem: [...state.basketDependedItem, action.payload],
      };
    case types.REMOVE_FROM_BASKET:
      let removeItemIndex = state.basketItem.findIndex(
        (item) => item.id === action.payload.id
      );
      if (removeItemIndex > -1) {
        return {
          ...state,
          basketItem: state?.basketItem?.filter(
            (_, index) => index !== removeItemIndex
          ),
        };
      }
      return state;
    case types.REMOVE_DEPENDECE_FROM_BASKET:
      let removeDependedItemIndex = state.basketDependedItem.findIndex(
        (item) => item.id === action.payload.id
      );
      if (removeDependedItemIndex > -1) {
        return {
          ...state,
          basketDependedItem: state?.basketDependedItem?.filter(
            (_, index) => index !== removeDependedItemIndex
          ),
        };
      }
      return state;
    case types.SET_PRICE:
      return { ...state, basketTotalPrice: action.payload };
    case types.DELETE_BASKET:
      return { basketItem: [], basketTotalPrice: 0 };
    default:
      return state;
  }
};
const BasketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(basketReducer, {
    basketItem: [],
    basketDependedItem: [],
    basketTotalPrice: 0,
  });
  return (
    <BasketState.Provider value={state}>
      <BasketDispatch.Provider value={dispatch}>
        {children}
      </BasketDispatch.Provider>
    </BasketState.Provider>
  );
};
export const addToBasket = (dispatch, item) => {
  dispatch({ type: types.ADD_TO_BASKET, payload: item });
};
export const addDependedToBasket = (dispatch, item) => {
  dispatch({ type: types.ADD_DEPENDECE_TO_BASKET, payload: item });
};
export const removeFromBasket = (dispatch, item) => {
  dispatch({ type: types.REMOVE_FROM_BASKET, payload: item });
};
export const removeDependedFromBasket = (dispatch, item) => {
  dispatch({ type: types.REMOVE_DEPENDECE_FROM_BASKET, payload: item });
};
export const setTotalPrice = (dispatch, price) => {
  dispatch({ type: types.SET_PRICE, payload: price });
};
export const deleteBasket = (dispatch) => {
  dispatch({ type: types.DELETE_BASKET });
};
export const useBasket = () => useContext(BasketState);
export const useBasketDispatch = () => useContext(BasketDispatch);

export default BasketProvider;
