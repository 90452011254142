const RootErrorBoundryFallback = () => {
  return (
    <div
      className="p-0 m-0 d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="p-0 m-0 d-flex flex-column align-items-center">
        <h2>Sorry , Something wrong accured!!</h2>
        <p>You can go back to home page</p>
        {/* <Link to="/admin"> */}
        <button
          onClick={() => {
            localStorage.removeItem("isLoggedIn");
            window.location.reload();
          }}
          className="btn px-5 shadow my-2 table_page_botton"
        >
          Home page
        </button>
        {/* </Link> */}
      </div>
    </div>
  );
};
export default RootErrorBoundryFallback;
