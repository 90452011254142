import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import { BASE_URL, MAX_AGE_COOKIE } from "../../constant/base";
import { checkCookieIsEnabled } from "../auth/check_navigator_cookie";
export const onSubmit = async (value) => {
  const cook = new Cookies();

  const body = {
    username: value.username,
    password: value.password,
    code: value.code,
    recaptchaToken: value.recaptchaToken,
  };
  // const devicetoken = cook.get("devicetoken");
  const devicetoken = localStorage.getItem("devicetoken");
  const _res = await fetch(`${BASE_URL}api/user/login`, {
    method: "POST",
    credentials: "include",
    headers: {
      "content-type": "application/json",
      "x-devicetoken": devicetoken,
    },
    body: JSON.stringify(body),
  });
  const _resJson = await _res.json();
  if (_res.ok) {
    toast.success("you are logged in");
    const token = await _resJson.token;
    //client cookie is enabled or not
    let isCookieEnabled = checkCookieIsEnabled();
    if (isCookieEnabled) {
      cook.set("token", token, {
        maxAge: MAX_AGE_COOKIE,
      });
      // cook.set("refToken", token.refToken, {
      //   maxAge: MAX_AGE_COOKIE,
      // });
    }
    return { status: 200, data: _resJson, token };
  } else {
    toast.error(_resJson?.message);
    return { status: _res.status, data: null };
  }
};
