import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BASE_URL } from "../../../constant/base";
import { useIsAuthDispatch } from "../../../hooks/useAuth";
import { hanldeConfirmSubmit } from "../../../utils/trusted-device/trusted_device";
import SimpleLoading from "../../common/loading/simple_loading";
import VerificationCodeInput from "../../common/verification-code/verification_code_component";

const TrustedDevice = (props) => {
  const { handleClose, show, username } = props;
  const [loading, setLoading] = useState(false);
  const setAuth = useIsAuthDispatch();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      confirm: "",
      trusted: false,
      username,
    },
    validationSchema: Yup.object({
      confirm: Yup.string()
        .required("Required")
        .length(8, "Confirm Code is not correct"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const token = await hanldeConfirmSubmit(values, handleClose, setLoading);
      const _res = await fetch(`${BASE_URL}api/user/info`, {
        credentials: "include",
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (_res.status === 200) {
        const _resJson = await _res.json();
        setAuth({ isLoggedIn: true, ..._resJson });
        localStorage.setItem(
          "isLoggedIn",
          JSON.stringify({
            isLoggedIn: true,
            components: _resJson?.components,
            ds_status: _resJson?.ds_status,
            user: { frk_role: _resJson?.user?.frk_role },
          })
        );
        //rolls
        if (_resJson.ds_status === 3) {
          history.replace("/admin");
        } else if (_resJson.ds_status === 4 || _resJson.ds_status === 8) {
          history.replace("/admin/pricing");
        } else {
          history.replace("/admin");
        }
      } else {
        toast.error("Please try again. your authorization is not confirmed");
      }
    },
    enableReinitialize: true,
  });
  return (
    <Modal size="md" centered show={show}>
      <Modal.Header className="vehicle_modal_header-style border-0">
        <Modal.Title></Modal.Title>
        <button
          className="btn-close"
          onClick={() => {
            handleClose();
          }}
        ></button>
      </Modal.Header>
      <div className="p-0 m-0">
        {/* <h4 className="inner_header mx-2">Trusted Your device</h4> */}

        <div className="p-0 m-0 d-flex flex-column align-items-center justify-content-center">
          <h2
            className="text-dark text-center my-3"
            style={{
              fontWeight: "bolder",
            }}
          >
            Verify it's you
          </h2>
          {/* <h6 className="text-secondary text-center my-3">
            For your security,we first need to verify your identity
          </h6> */}
          <ul className="p-0 px-0 m-0 ">
            <li
              className="mb-0 py-1 list-group-item border-0 text-center"
              style={{ fontSize: "14px" }}
            >
              There is not any activity report from this device over the last 7
              days
            </li>
            <li
              className="mb-2 py-1 list-group-item border-0 text-center"
              style={{ fontSize: "14px" }}
            >
              to show that it is really you, complete the task below
            </li>
          </ul>
        </div>
        <div className="p-0 m-0 w-100 d-none d-sm-flex align-items-center justify-content-center">
          <img
            src="/images/trusted-device/cell-phone.png"
            alt=""
            style={{
              width: "100px",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </div>
        <div className="p-0 m-0 d-flex flex-column align-items-center justify-content-start">
          <ul className="p-0 px-4 m-0 ">
            <h4
              className="text-dark my-3 px-3 text-left"
              style={{
                fontWeight: "bolder",
              }}
            >
              Enter a verification code
            </h4>
            <li className="mb-2 list-group-item border-0">
              A text message with a verification code was just sent it to you.
            </li>
          </ul>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="py-1 px-5 d-flex row align-items-center justify-content-start"
        >
          <div className="p-0 m-0 col-11 col-sm-10 col-lg-12">
            <input
              type="text"
              maxLength={8}
              name="confirm"
              id="confirm"
              // ref={(ref) => inputRef.current.push(ref)}
              onChange={formik.handleChange}
              // onKeyUp={(e) => handleNexStep(e, index)}
              className="mx-1 p-0 py-1 p-sm-1 text-center form-control border border-dark trusted_device-inputs"
              // onPaste={handlePasteValue}
              value={formik.values.confirm}
              placeholder="Enter the 8 digit code"
            />
            {/* <VerificationCode
              length={1}
              onComplete={(e) => formik.setFieldValue("confirm", e)}
              onRemove={(e) => formik.setFieldValue("confirm", e)}
            /> */}
          </div>
          <div className="row" />
          <div className="p-0 m-0 mt-1 col-11 col-sm-8 col-lg-6 text-danger">
            {formik.errors.confirm &&
              formik.touched.confirm &&
              formik.errors.confirm}
          </div>
          <div className="row" />

          <div className="d-flex justify-content-center p-0 mt-3 mb-3">
            {loading ? (
              <SimpleLoading />
            ) : (
              <button
                type="submit"
                className="btn w-100 text-white px-5 shadow "
                style={{
                  backgroundColor: "#1F334B",
                }}
              >
                Confirm
              </button>
            )}
          </div>
          <div className="p-0 m-0 pb-3 col-11 col-sm-8 col-lg-12">
            <div className="p-0 m-0 d-flex align-items-center justify-content-center">
              <div className="form-check my-2">
                <input
                  type="checkbox"
                  id="trusted"
                  checked={formik.values.trusted}
                  value={formik.values.trusted}
                  name="trusted"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className="form-check-input mt-2 p-2"
                  style={{
                    border: "1px solid #000",
                    cursor: "pointer",
                  }}
                />
                <label
                  htmlFor="trusted"
                  className="p-0 m-0 mb-1 form-check-label"
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bolder",
                    cursor: "pointer",
                  }}
                >
                  Don't ask again on this device
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
  // return (
  //   <Modal size="lg" centered show={show}>
  //     <Modal.Header className="vehicle_modal_header-style border-0">
  //       <Modal.Title></Modal.Title>
  //       <button
  //         className="btn-close"
  //         onClick={() => {
  //           handleClose();
  //         }}
  //       ></button>
  //     </Modal.Header>
  //     <div className="p-0 m-0">
  //       {/* <h4 className="inner_header mx-2">Trusted Your device</h4> */}
  //       <div className="p-0 m-0 w-100 d-none d-sm-flex align-items-center justify-content-center">
  //         <img
  //           src="/images/trusted-device/Code.png"
  //           alt=""
  //           style={{
  //             width: "300px",
  //             height: "auto",
  //             objectFit: "contain",
  //           }}
  //         />
  //       </div>
  //       <div className="p-0 m-0 d-flex flex-column align-items-center justify-content-center">
  //         <h2
  //           className="text-dark text-center my-3"
  //           style={{
  //             fontWeight: "bolder",
  //           }}
  //         >
  //           Enter your verification code
  //         </h2>
  //         <h6 className="text-secondary text-center my-3">
  //           For your security,we first need to verify your identity
  //         </h6>
  //         <ul className="p-0 px-4 m-0 ">
  //           <li className="mb-2">
  //             We just sent you a verification code in a text message
  //           </li>
  //           <li className="mb-2">
  //             Enter and verify your code down here &#128071;
  //           </li>
  //         </ul>
  //       </div>
  //       <form
  //         onSubmit={formik.handleSubmit}
  //         className="py-1 px-3 d-flex row align-items-center justify-content-center"
  //       >
  //         <div className="p-0 m-0 col-11 col-sm-8 col-lg-6">
  //           <VerificationCodeInput
  //             length={8}
  //             onComplete={(e) => formik.setFieldValue("confirm", e)}
  //             onRemove={(e) => formik.setFieldValue("confirm", e)}
  //           />
  //         </div>
  //         <div className="row" />
  //         <div className="p-0 m-0 mt-1 col-11 col-sm-8 col-lg-6 text-danger">
  //           {formik.errors.confirm &&
  //             formik.touched.confirm &&
  //             formik.errors.confirm}
  //         </div>
  //         <div className="row" />
  //         <div className="p-0 m-0  col-11 col-sm-8 col-lg-6">
  //           <div className="p-0 m-0 d-flex align-items-center justify-content-center">
  //             <div className="form-check my-2">
  //               <input
  //                 type="checkbox"
  //                 id="trusted"
  //                 checked={formik.values.trusted}
  //                 value={formik.values.trusted}
  //                 name="trusted"
  //                 onBlur={formik.handleBlur}
  //                 onChange={formik.handleChange}
  //                 className="form-check-input mt-1 p-2"
  //                 style={{
  //                   border: "1px solid #000",
  //                   cursor: "pointer",
  //                 }}
  //               />
  //               <label
  //                 htmlFor="trusted"
  //                 className="p-0 m-0 mb-1 form-check-label"
  //                 style={{
  //                   fontSize: "1.2rem",
  //                   fontWeight: "bolder",
  //                   cursor: "pointer",
  //                 }}
  //               >
  //                 Don't ask again on this device
  //               </label>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="d-flex justify-content-center mt-3 mb-3">
  //           {loading ? (
  //             <SimpleLoading />
  //           ) : (
  //             <button
  //               type="submit"
  //               className="btn rounded rounded-pill text-white px-5 shadow "
  //               style={{
  //                 backgroundColor: "#1F334B",
  //               }}
  //             >
  //               Confirm
  //             </button>
  //           )}
  //         </div>
  //         {/* <div className="d-flex justify-content-center mb-4">
  //           <span>Have not received?</span>
  //           <span
  //             className="mx-1"
  //             style={{
  //               textDecoration: "underline",
  //               color: "#1F334B",
  //             }}
  //           >
  //             Send it again
  //           </span>
  //         </div> */}
  //       </form>
  //     </div>
  //   </Modal>
  // );
};

export default TrustedDevice;
