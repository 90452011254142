import React, { createContext, useState, useContext } from "react";

const UserState = createContext();
const SetUserState = createContext();
const UesrInfoProvider = ({ children }) => {
  const [state, dispatch] = useState("");
  return (
    <UserState.Provider value={state}>
      <SetUserState.Provider value={dispatch}>{children}</SetUserState.Provider>
    </UserState.Provider>
  );
};

export const useUser = () => useContext(UserState);
export const useSetUserDispatch = () => {
  const dispatch = useContext(SetUserState);
  const prevData = useUser() || null;
  if (prevData) {
    const setState = (data) => {
      dispatch({ token: prevData.token, ...data });
    };
    return setState;
  }
  const setState = (data) => {
    dispatch(data);
  };
  return setState;
};

export default UesrInfoProvider;
