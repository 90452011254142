import imageCompression from "browser-image-compression";

export const compressImage = async (
  file,
  maxSizeMB = 0.3,
  maxWidthOrHeight = 1024,
  useWebWorker = true
) => {
  const options = {
    maxSizeMB,
    maxWidthOrHeight,
    useWebWorker,
  };
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};
