import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Getagreement,
  GetSystemMessage,
} from "../../utils/agreements/agreement";
import { toast } from "react-toastify";
import Loading from "../common/loading/loading";

const Agreement = () => {
  const [confirm, setConfirm] = useState(false);
  const route = useHistory();
  const history = useHistory();
  const text = route?.location?.pathname.split("agreement/");
  const queryClient = useQueryClient();
  const GetagreementQuery = useMutation("GetagreementQuery", Getagreement, {
    onSuccess: () => {
      history.push(`/signin`);
    },
  });
  const GetSystemMessageQuery = useQuery("GetSystemMessageQuery", () =>
    GetSystemMessage()
  );

  return (
    <>
      {GetSystemMessageQuery.status === "loading" ? (
        <Loading />
      ) : (
        <div className="p-5">
          <p>
            <input
              type="checkbox"
              name="confirm_finance_checkbox"
              id="confirm_finance_checkbox"
              className="m-0 mt-1 mx-4"
              onChange={(e) => {
                setConfirm(e.target.checked);
              }}
            />
            {GetSystemMessageQuery?.data?.message}
          </p>
          <button
            className="btn px-5 shadow table_page_botton"
            onClick={(e) => {
              if (confirm) {
                GetagreementQuery.mutate(text[1]);
              } else {
                toast.error("Accept This Agreement");
              }
            }}
          >
            Authorize
          </button>
        </div>
      )}
    </>
  );
};

export default Agreement;
