import React, { useState } from "react";
import { useQuery } from "react-query";
import { getCustomerDepositeVehicles } from "../utils/vehicle/vehicle_deposit";

export const DepositeVehicleContextState = React.createContext();
export const DepositeVehicleContextSetter = React.createContext();
export const DepositeVehicleContextData = React.createContext();
const CustomerVehicleDepositeProvider = ({ children }) => {
  const [choosenVehicle, setChoosenVehicle] = useState([]);
  const getDepositeVehicles = useQuery(
    "getDepositeVehicles",
    () => getCustomerDepositeVehicles(),
    {
      retry: 1,
      // refetchOnMount: false,
    }
  );
  return (
    <DepositeVehicleContextData.Provider
      value={getDepositeVehicles.data?.midVehicleDealerships}
    >
      <DepositeVehicleContextState.Provider value={choosenVehicle}>
        <DepositeVehicleContextSetter.Provider value={setChoosenVehicle}>
          {children}
        </DepositeVehicleContextSetter.Provider>
      </DepositeVehicleContextState.Provider>
    </DepositeVehicleContextData.Provider>
  );
};

export default CustomerVehicleDepositeProvider;
