import { useFormik } from "formik";
import { createContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { postRealTestDrives } from "../utils/test-drive/real_test_drive";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
// export const RealTestDriveState = createContext();
// export const RealTestDriveSetter = createContext();
export const RealTestDriveConfirmState = createContext();
export const RealTestDriveConfirmSetter = createContext();
export const RealTestDriveStepState = createContext();
export const RealTestDriveStepSetter = createContext();
export const RealTestDriveStepCheckState = createContext();
export const RealTestDriveStepCheckSetter = createContext();
export const RealTestDriveFormik = createContext();
export const RealTestDriveFormAddLoading = createContext();
const RealTestDriveContaxtProvider = ({ children }) => {
  // const [customer, setCustomer] = useState("");
  const history = useHistory();
  const [confirmUserId, setConfirmUserId] = useState("");
  const [step, setStep] = useState({});
  const [stepCheck, setStepCheck] = useState();
  const queryCilent = useQueryClient();
  const postRealTestDriveData = useMutation(
    "postRealTestDriveData",
    postRealTestDrives,
    {
      onSuccess: () => {
        queryCilent.invalidateQueries("getRealTestDrive");
        history.goBack(1);
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      user_salutation: "",
      testDrive_start_date: "",
      testDrive_start_time: "",
      testDrive_finish_date: "",
      testDrive_finish_time: "",
      driverLicenseImage: null,
      method_of_contact: "",
      requested_date: "",
      comment: "",
      status: 0,
      vehicle_id: "",
      user: confirmUserId,
    },
    validationSchema: Yup.object().shape({
      user_salutation: Yup.string().required("Required"),
      method_of_contact: Yup.string().required("Required"),
      testDrive_start_date: Yup.string().required("Required"),
      testDrive_start_time: Yup.string().required("Required"),
      testDrive_finish_date: Yup.string().required("Required"),
      testDrive_finish_time: Yup.string().required("Required"),
      requested_date: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const body = {
        ...values,
        user: values.user?.id ? values.user?.id : confirmUserId,
      };
      postRealTestDriveData.mutate(body);
    },
  });
  return (
    <RealTestDriveFormik.Provider value={formik}>
      <RealTestDriveStepState.Provider value={step}>
        <RealTestDriveStepSetter.Provider value={setStep}>
          <RealTestDriveStepCheckState.Provider value={stepCheck}>
            <RealTestDriveStepCheckSetter.Provider value={setStepCheck}>
              {/* <RealTestDriveState.Provider value={customer}>
                <RealTestDriveSetter.Provider value={setCustomer}>*/}
              <RealTestDriveConfirmState.Provider value={confirmUserId}>
                <RealTestDriveConfirmSetter.Provider value={setConfirmUserId}>
                  <RealTestDriveFormAddLoading.Provider
                    value={postRealTestDriveData.isLoading}
                  >
                    {children}
                  </RealTestDriveFormAddLoading.Provider>
                </RealTestDriveConfirmSetter.Provider>
              </RealTestDriveConfirmState.Provider>
              {/* </RealTestDriveSetter.Provider>
              </RealTestDriveState.Provider> */}
            </RealTestDriveStepCheckSetter.Provider>
          </RealTestDriveStepCheckState.Provider>
        </RealTestDriveStepSetter.Provider>
      </RealTestDriveStepState.Provider>
    </RealTestDriveFormik.Provider>
  );
};

export default RealTestDriveContaxtProvider;
