const getDisabledComponent = (allComponents, componentName) => {
  if (
    allComponents.ds_components.indexOf(componentName) === -1 &&
    allComponents.role_components.indexOf(componentName) !== -1
  ) {
    return true;
  }
  return false;
};
export { getDisabledComponent };
