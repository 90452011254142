import {
  ApiCallDeleteMethod,
  ApiCallGetMethod,
  ApiCallUploadMethodFormData,
  ApiCallUploadMethodJson,
} from "../../constant/base";

export const getDealershipTicketUsers = () => {
  return ApiCallGetMethod("api/all/ticket/users/by/dealership");
};
export const getDealershipUsers = () => {
  return ApiCallGetMethod("api/all/users/by/dealership");
};
export const deleteDealershipUsers = (id) => {
  return ApiCallDeleteMethod(`remove/users/${id}`);
};
export const updateDealershipUserActiveStatus = (id) => {
  return ApiCallGetMethod(`api/user/restrict/${id}`);
};
export const getSingelDealershipUser = (id) => {
  return ApiCallGetMethod(`api/user/single/${id}`);
};
export const updateDealershipUser = (body) => {
  const { id, value } = body;

  const formData = new FormData();
  if (typeof value.frk_city !== "undefined" && value.frk_city !== null) {
    formData.append("frk_city", value.frk_city);
  }
  if (typeof value.frk_role !== "undefined" && value.frk_role !== null) {
    formData.append("frk_role", value.frk_role?.value);
  }
  if (typeof value.f_name !== "undefined" && value.f_name !== null) {
    formData.append("f_name", value.f_name);
  }
  if (typeof value.l_name !== "undefined" && value.l_name !== null) {
    formData.append("l_name", value.l_name);
  }
  if (typeof value.full_name !== "undefined" && value.full_name !== null) {
    formData.append("full_name", value.full_name);
  }
  if (typeof value.email !== "undefined" && value.email !== null) {
    formData.append("email", value.email);
  }
  if (typeof value.mobile !== "undefined" && value.mobile !== null) {
    formData.append("mobile", value.mobile);
  }
  if (typeof value.address !== "undefined" && value.address !== null) {
    formData.append("address", value.address);
  }
  if (
    typeof value.aparteman_no !== "undefined" &&
    value.aparteman_no !== null
  ) {
    formData.append("aparteman_no", value.aparteman_no);
  }
  if (typeof value.postalcode !== "undefined" && value.postalcode !== null) {
    formData.append("postalcode", value.postalcode);
  }
  if (typeof value.gender !== "undefined" && value.gender !== null) {
    formData.append("gender", value.gender);
  }
  if (typeof value.birthdate !== "undefined" && value.birthdate !== null) {
    formData.append("birthdate", value.birthdate);
  }
  if (typeof value.is_active !== "undefined" && value.is_active !== null) {
    formData.append("is_active", value.is_active);
  }
  if (typeof value.avatar !== "undefined" && value.avatar !== null) {
    formData.append("avatar", value.avatar);
  }
  if (
    typeof value.driver_license_photo !== "undefined" &&
    value.driver_license_photo !== null
  ) {
    formData.append("driver_license_photo", value.driver_license_photo);
  }
  if (
    typeof value.driver_license_NO !== "undefined" &&
    value.driver_license_NO !== null
  ) {
    formData.append("driver_license_NO", value.driver_license_NO);
  }
  if (
    typeof value.driver_license_expire_date !== "undefined" &&
    value.driver_license_expire_date !== null
  ) {
    formData.append(
      "driver_license_expire_date",
      value.driver_license_expire_date
    );
  }
  if (
    typeof value.driver_license_issued_date !== "undefined" &&
    value.driver_license_issued_date !== null
  ) {
    formData.append(
      "driver_license_issued_date",
      value.driver_license_issued_date
    );
  }
  if (typeof value.fax_NO !== "undefined" && value.fax_NO !== null) {
    formData.append("fax_NO", value.fax_NO);
  }
  if (
    typeof value.signiture_URL !== "undefined" &&
    value.signiture_URL !== null
  ) {
    formData.append("signiture_URL", value.signiture_URL);
  }
  if (typeof value.password !== "undefined" && value.password !== null) {
    formData.append("password", value.password);
  }
  if (
    typeof value.register_number !== "undefined" &&
    value.register_number !== null
  ) {
    formData.append("register_number", value.register_number);
  }
  if (
    typeof value.company_name &&
    value.company_name !== null &&
    value.company_name !== undefined
  ) {
    formData.append("company_name", value.company_name);
  }
  if (typeof value.city && value.city !== null) {
    formData.append("city", value.city);
  }
  if (typeof value.province && value.province !== null) {
    formData.append("province", value.province);
  }
  if (
    typeof value.manager &&
    value.manager !== null &&
    value.manager !== undefined
  ) {
    formData.append("manager", value.manager);
  }
  if (value.bcid && value.bcid !== null) {
    formData.append("bcid", value.bcid);
  }
  if (value.customer_authentication_method && value.customer_authentication_method !== null) {
    formData.append("customer_authentication_method", value.customer_authentication_method?.value);
  }
  return ApiCallUploadMethodFormData(
    `api/ds/admin/user/update/${id}`,
    "PATCH",
    formData
  );
};
export const ucdaWizardCustomer = (body) => {
  const { id, frk_customer_id } = body;
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard/customer/${id}`,
    "PATCH",
    {
      frk_customer_id,
    }
  );
};
export const postDealershipUser = (values) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(values)) {
    formData.append(key, value);
  }
  return ApiCallUploadMethodFormData("api/ds/admin/userup", "POST", formData);
};
export const getDealershipRemainingUsers = () => {
  return ApiCallGetMethod("api/user/add/count");
};
export const getDealershipRols = () => {
  return ApiCallGetMethod("roles");
};

//Delete Customer in dealerPull//
export const DeleteCustomerDealerPull = ({ mvdBos, id }) => {
  return ApiCallDeleteMethod(
    `api/billofsale/ucda/wizard/customers/${mvdBos}/${id}`,
    "DELETE"
  );
};

//Save Customer in DealerPull//
export const DealerPullCustomer = (body) => {
  const { id, ids } = body;
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard/customers/${id}`,
    "POST",
    { ids }
  );
};
