import { Cookies } from "react-cookie";
const cook = new Cookies();
export const checkRefreshToken = async () => {
  // ap/user/refresh/token
  // const token = await cook.get("refToken");
  // console.log("reftoken", token);
  // const _res = await fetch(`${BASE_URL}api/user/refresh/token`, {
  //   method: "POST",
  //   headers: {
  //     "content-type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     token: token,
  //   }),
  // });
  // if (_res.status === 401) {
    cook.remove("token");
    // cook.remove("refToken");
    localStorage.removeItem('isLoggedIn')
    return window.location.replace("/#/signin");
  // } else {
  //   const { accessToken, refToken } = await _res.json();
  //   cook.set("token", accessToken);
  //   cook.set("refToken", refToken);
  //   return;
  // }
};
