import { ApiCallUploadMethodJson } from "../../constant/base";

const handleShow = (setForgetPassword) => {
  setForgetPassword(true);
};
const handleClose = (setForgetPassword) => {
  setForgetPassword(false);
};
const handleSubmit = async (values) => {
  return ApiCallUploadMethodJson("api/user/forgotPassword", "POST", values);
  // const { email } = await values;
  // const _res = await fetch(`${BASE_URL}api/user/forgotPassword`, {
  //   method: "POST",
  //   body: JSON.stringify({
  //     email,
  //   }),
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });
};
export { handleShow, handleClose, handleSubmit };
