import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { Query, useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { compressImage } from "../../../utils/common/compress_file";
import {
  getSingelDealershipUser,
  updateDealershipUser,
} from "../../../utils/dealership-users/dealership_users";
import Loading from "../../common/loading/loading";
import NumberFormat from "react-number-format";
import { BcIdCustomer } from "../../../utils/all-customers/all_customers";
import { reactSelectStyle } from "../../../constant/react_select_style";
import Select from "react-select";
import { useIsAuthState } from "../../../hooks/useAuth";

const EditCustomerFilter = (props) => {
  const { isDisabled, addId, toggleExpanded, setSelected } = props;
  const auth = useIsAuthState();
  const history = useHistory();
  const queryCilent = useQueryClient();
  const getSingleUser = useQuery(
    ["getSingleUser", addId],
    () => getSingelDealershipUser(addId),
    {
      refetchOnMount: false,
    }
  );

  const updateSingleUser = useMutation(
    "updateSingleUser",
    updateDealershipUser,
    {
      onSuccess: () => {
        queryCilent.invalidateQueries("all-customer");
        queryCilent.invalidateQueries(["getSingleUser", addId]);
        setSelected({
          id: getSingleUser?.data?.id,
          key: getSingleUser?.data?.id,
          label: getSingleUser?.data?.full_name
            ? `${getSingleUser?.data?.full_name}`
            : getSingleUser?.data?.f_name || getSingleUser?.data?.l_name
              ? `${getSingleUser?.data?.f_name} ${getSingleUser?.data?.l_name}`
              : "",
          value: getSingleUser?.data?.id,
          MidUcdaCustomers: {
            UserId: getSingleUser?.data?.id,
          },
        });
      },
    }
  );
  const BcIdCustomerQuery = useQuery("BcIdCustomerQuery", BcIdCustomer, {});
  const authenticationOptions = BcIdCustomerQuery?.data?.map((vehicle) => {
    return {
      label: vehicle,
      value: vehicle,
    };
  });
  const birthdate =
    getSingleUser.data?.birthdate !== null &&
      typeof getSingleUser.data?.birthdate !== "undefined"
      ? getSingleUser.data?.birthdate?.split("Z")
      : null;
  const driLicEXDate =
    getSingleUser.data?.driver_license_expire_date !== null &&
      typeof getSingleUser.data?.driver_license_expire_date !== "undefined"
      ? getSingleUser.data?.driver_license_expire_date?.split("Z")
      : null;
  const [checkBcId, setcheckBcId] = useState(
    getSingleUser.data?.customer_authentication_method
      ? getSingleUser.data?.customer_authentication_method
      : "Driver licence number"
  );
  const formik = useFormik({
    initialValues: {
      active: getSingleUser.data?.active,
      address: getSingleUser.data?.address,
      avatar: getSingleUser.data?.avatar,
      birthdate: birthdate !== null ? birthdate[0] : null,
      driver_license_NO: getSingleUser.data?.driver_license_NO,
      driver_license_expire_date:
        driLicEXDate !== null ? driLicEXDate[0] : null,
      driver_license_photo: getSingleUser.data?.driver_license_photo,
      email: getSingleUser.data?.email,
      f_name: getSingleUser.data?.f_name,
      fax_NO: getSingleUser.data?.fax_NO,
      gender: getSingleUser.data?.gender,
      is_active: getSingleUser.data?.is_active,
      l_name: getSingleUser.data?.l_name,
      mobile: getSingleUser.data?.mobile,
      postalcode: getSingleUser.data?.postalcode,
      signiture_URL: getSingleUser.data?.signiture_URL,
      username: getSingleUser.data?.username,
      full_name: getSingleUser.data?.full_name,
      company_name: getSingleUser.data?.LegalUser?.company_name,
      bcid: getSingleUser.data?.bcid,
      province: getSingleUser.data?.province,
      city: getSingleUser.data?.city,
      customer_authentication_method: {
        label: getSingleUser.data?.customer_authentication_method,
        value: getSingleUser.data?.customer_authentication_method,
      },
    },
    onSubmit: (value) => {
      const body = {
        value,
        id: addId,
      };
      updateSingleUser.mutate(body);
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    setcheckBcId(
      getSingleUser.data?.customer_authentication_method
        ? getSingleUser.data?.customer_authentication_method
        : "Driver licence number"
    );
  }, [getSingleUser?.data]);
  const handleAvatarChange = async (e) => {
    if (
      typeof e.target.files[0] !== "undefined" &&
      e.target.files.length !== 0 &&
      e.target.files[0] !== null
    ) {
      const file = await compressImage(e.target.files[0], 0.3, 1024);
      formik.setFieldValue("avatar", file);
    }
  };
  const handleLICnumberChange = async (e) => {
    if (
      typeof e.target.files[0] !== "undefined" &&
      e.target.files.length !== 0 &&
      e.target.files[0] !== null
    ) {
      const file = await compressImage(e.target.files[0], 0.3, 1024);
      formik.setFieldValue("driver_license_photo", file);
    }
  };
  const handleSignUrlChange = async (e) => {
    if (
      typeof e.target.files[0] !== "undefined" &&
      e.target.files.length !== 0 &&
      e.target.files[0] !== null
    ) {
      const file = await compressImage(e.target.files[0], 0.3, 1024);
      formik.setFieldValue("signiture_URL", file);
    }
  };
  const handleIsActiveCheck = () => {
    if (formik.values.is_active === 0) {
      formik.setFieldValue("is_active", 1);
    } else {
      formik.setFieldValue("is_active", 0);
    }
  };
  return (
    <div>
      {getSingleUser.isLoading ? (
        <Loading />
      ) : (
        <div className={`p-0 m-0 ${isDisabled && "disabled_button_pages"}`}>
          <div className="p-md-3 px-md-4 p-sm-2 p-1 px-3 m-0 first_page-container d-flex row align-items-scratch justify-content-start">
            <div className="p-0 m-0 my-2 col-12 d-flex align-items-center justify-content-between">
              <h2 className="mx-2">Personal profile</h2>

              <button
                className="btn px-5 shadow table_page_botton "
                onClick={toggleExpanded}
              >
                <span
                  className="p-0 m-0"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Cancel
                </span>
              </button>
            </div>
            <div className="p-0 m-0 mt-2 mb-5  bg-white shadow rounded">
              <form
                onSubmit={formik.handleSubmit}
                className="p-0 m-0 d-flex row justify-content-center align-items-start"
                onKeyDown={(e) => {
                  if ((e.charCode || e.keyCode) === 13) {
                    e.preventDefault();
                  }
                }}
              >
                <div className="p-0 m-0  w-100 d-flex row align-items-start justify-content-start">
                  <div className="p-0 m-0   d-flex justify-content-start align-items-center">
                    <span
                      className="p-0 m-0 mx-2 shadow condition_points "
                      style={{ backgroundColor: "#9D4EDD" }}
                    ></span>
                    <h4 className="inner_header my-3">Activity information</h4>
                  </div>
                  <div className="p-0 m-0 px-3">
                    <div className="form-check form-switch ">
                      <input
                        className="form-check-input"
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        id="is_active"
                        name="is_active"
                        checked={
                          formik.values.is_active === 0
                            ? false
                            : formik.values.is_active === 1
                              ? true
                              : false
                        }
                        value={
                          formik.values.is_active === 0
                            ? false
                            : formik.values.is_active === 1
                              ? true
                              : false
                        }
                        onBlur={formik.handleBlur}
                        onChange={handleIsActiveCheck}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="is_active"
                        style={{ cursor: "pointer" }}
                      >
                        Active user
                      </label>
                    </div>
                  </div>
                  <div className="p-0 m-0   d-flex justify-content-start align-items-center">
                    <span
                      className="p-0 m-0 mx-2 shadow condition_points "
                      style={{ backgroundColor: "#9D4EDD" }}
                    ></span>
                    <h4 className="inner_header my-3">Personal information</h4>
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="username"
                      name="username"
                      readOnly
                      value={formik.values.username}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>First name</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="f_name"
                      name="f_name"
                      value={formik.values.f_name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Last name</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="l_name"
                      name="l_name"
                      value={formik.values.l_name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Full Name</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="full_name"
                      name="full_name"
                      value={formik.values.full_name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Company/Cosigner</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="company_name"
                      name="company_name"
                      value={formik.values.company_name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Gender</label>
                    <select
                      type="text"
                      className="form-select rounded rounded-pill form_input-style mt-1 "
                      id="gender"
                      name="gender"
                      value={formik.values.gender}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    >
                      <option value="0">Male</option>
                      <option value="1">Female</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>birthdate</label>
                    <input
                      type="datetime-local"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="birthdate"
                      name="birthdate"
                      value={formik.values.birthdate}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="p-0 m-0 d-flex justify-content-start align-items-center">
                    <span
                      className="p-0 m-0 mx-2 shadow condition_points "
                      style={{ backgroundColor: "#5A189A" }}
                    ></span>
                    <h4 className="inner_header  my-3">Contact information</h4>
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>E-mail</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Mobile</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="mobile"
                      name="mobile"
                      value={formik.values.mobile}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {/* <NumberFormat
                      format="(###) ###-####"
                      mask="_"
                      allowEmptyFormatting={true}
                      onValueChange={(e) => {
                        formik.setFieldValue("mobile", e.value);
                      }}
                      placeholder="Cell Phone Number"
                      onBlur={formik.handleBlur}
                      name="mobile"
                      id="mobile"
                      className="form-control signup_input__container my-2"
                      value={formik.values.mobile}
                    /> */}
                    {formik.values.mobile?.length < 10 &&
                      formik.values.mobile?.length !== 0 ? (
                      <div className="text-danger">Wrong Mobile Number</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="p-0 m-0   d-flex justify-content-start align-items-center">
                    <span
                      className="p-0 m-0 mx-2 shadow condition_points "
                      style={{ backgroundColor: "#5A189A" }}
                    ></span>
                    <h4 className="inner_header  my-3">Address information</h4>
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Address</label>
                    <textarea
                      type="text"
                      className="form-control form_textarea-style "
                      id="address"
                      name="address"
                      value={formik.values.address}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Postalcode</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="postalcode"
                      name="postalcode"
                      value={formik.values.postalcode}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {auth?.dealershipInfo?.business_city?.Province?.id === 3 ? (
                    <>
                      <div className="form-group col-md-4 col-sm-6 col-12 ">
                        <label>Authentication Management</label>
                        <Select
                          options={
                            authenticationOptions ? authenticationOptions : []
                          }
                          id="customer_authentication_method"
                          name="customer_authentication_method"
                          value={formik.values.customer_authentication_method}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "customer_authentication_method",
                              e
                            );
                            setcheckBcId(e?.value);
                          }}
                          className="p-0 m-0 w-100"
                          styles={reactSelectStyle}
                          isClearable={true}
                        />
                      </div>
                      {checkBcId === "Driver licence number" && (
                        <div className="form-group col-md-4 col-sm-6 col-12 ">
                          <label>Driver license number</label>
                          <input
                            type="text"
                            className="form-control rounded rounded-pill form_input-style mt-1 "
                            id="driver_license_NO"
                            name="driver_license_NO"
                            value={formik.values.driver_license_NO}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </div>
                      )}
                      {checkBcId === "BCID" && (
                        <div className="form-group col-md-4 col-sm-6 col-12 ">
                          <label>BCID</label>
                          <input
                            type="text"
                            className="form-control rounded rounded-pill form_input-style mt-1 "
                            id="bcid"
                            name="bcid"
                            value={formik.values.bcid}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="form-group col-md-4 col-sm-6 col-12 ">
                      <label>Driver license number</label>
                      <input
                        type="text"
                        className="form-control rounded rounded-pill form_input-style mt-1 "
                        id="driver_license_NO"
                        name="driver_license_NO"
                        value={formik.values.driver_license_NO}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                  )}
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>driver license expiring date</label>
                    <input
                      type="date"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="driver_license_expire_date"
                      name="driver_license_expire_date"
                      value={formik.values.driver_license_expire_date}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      min="1970-01-01"
                      max="9999-01-01"
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-6 col-12 ">
                    <label>Fax number</label>
                    <input
                      type="text"
                      className="form-control rounded rounded-pill form_input-style mt-1 "
                      id="fax_NO"
                      name="fax_NO"
                      value={formik.values.fax_NO}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="p-0 m-0   d-flex justify-content-start align-items-center">
                    <span
                      className="p-0 m-0 mx-2 shadow condition_points "
                      style={{ backgroundColor: "#FF9E00" }}
                    ></span>
                    <h4 className="inner_header my-3">Images</h4>
                  </div>
                  <div className="form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12 ">
                    <label
                      htmlFor="avatar"
                      className="d-flex justify-content-between align-items-center"
                    >
                      Avatar
                      <OverlayTrigger
                        placement="bottom"
                        trigger={["hover", "focus"]}
                        overlay={<Tooltip className="p-0 m-0">Edit</Tooltip>}
                      >
                        <i className="p-0 m-0 edit_icon-style">
                          <FaEdit />
                        </i>
                      </OverlayTrigger>
                    </label>

                    <input
                      id="avatar"
                      type="file"
                      className="d-none"
                      onChange={handleAvatarChange}
                    />
                    <div
                      className="m-0 p-1 w-100"
                      style={{
                        border: "1px dashed #495057",
                        minHeight: "200px",
                      }}
                    >
                      {formik.values.avatar !== getSingleUser.data?.avatar &&
                        //   getSingleUser.data?.avatar !== null &&
                        typeof formik.values.avatar === "object" ? (
                        <img
                          src={URL.createObjectURL(formik.values.avatar)}
                          alt="avatar"
                          className="avatar-img w-100 h-100"
                        />
                      ) : (
                        <img
                          src={`https://hillzimage.blob.core.windows.net${getSingleUser.data?.avatar}`}
                          alt="avatar"
                          className="avatar-img w-100 h-100"
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12 ">
                    <label
                      htmlFor="driver_license_photo"
                      className="d-flex justify-content-between align-items-center"
                    >
                      Driver license
                      <OverlayTrigger
                        placement="bottom"
                        trigger={["hover", "focus"]}
                        overlay={<Tooltip className="p-0 m-0">Edit</Tooltip>}
                      >
                        <i className="p-0 m-0 edit_icon-style">
                          <FaEdit />
                        </i>
                      </OverlayTrigger>
                    </label>
                    <input
                      id="driver_license_photo"
                      type="file"
                      className="d-none"
                      onChange={handleLICnumberChange}
                    />
                    <div
                      className="m-0 p-1 w-100"
                      style={{
                        border: "1px dashed #495057",
                        minHeight: "200px",
                      }}
                    >
                      {formik.values.driver_license_photo !==
                        getSingleUser.data?.driver_license_photo &&
                        typeof formik.values.driver_license_photo === "object" ? (
                        <img
                          src={URL.createObjectURL(
                            formik.values.driver_license_photo
                          )}
                          alt="dirver license"
                          className="avatar-img w-100 h-100"
                        />
                      ) : (
                        <img
                          src={`https://hillzimage.blob.core.windows.net${getSingleUser.data?.driver_license_photo}`}
                          alt="avatar"
                          className="avatar-img w-100 h-100"
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12 ">
                    <label
                      htmlFor="signiture_URl"
                      className="d-flex justify-content-between align-items-center"
                    >
                      Signature
                      <OverlayTrigger
                        placement="bottom"
                        trigger={["hover", "focus"]}
                        overlay={<Tooltip className="p-0 m-0">Edit</Tooltip>}
                      >
                        <i className="p-0 m-0 edit_icon-style">
                          <FaEdit />
                        </i>
                      </OverlayTrigger>
                    </label>
                    <input
                      id="signiture_URl"
                      type="file"
                      className="d-none"
                      onChange={handleSignUrlChange}
                    />
                    <div
                      className="m-0 p-1 w-100"
                      style={{
                        border: "1px dashed #495057",
                        minHeight: "200px",
                      }}
                    >
                      {formik.values.signiture_URL !==
                        getSingleUser.data?.signiture_URL &&
                        typeof formik.values.signiture_URL === "object" ? (
                        <img
                          src={URL.createObjectURL(formik.values.signiture_URL)}
                          alt="avatar"
                          className="avatar-img w-100 h-100"
                        />
                      ) : (
                        <img
                          src={`https://hillzimage.blob.core.windows.net${getSingleUser.data?.signiture_URL}`}
                          alt="avatar"
                          className="avatar-img w-100 h-100"
                        />
                      )}
                    </div>
                  </div>
                  <div className="p-4 m-0 my-3 col-12 text-end">
                    <button
                      type="submit"
                      className="btn form_submit-btn px-5 shadow"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditCustomerFilter;
