import { useFormik } from "formik";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  checkPasswordAndConfirm,
  onSubmit,
} from "../../../utils/reset-password/reset_password";
const Confirm = () => {
  const confirmInputRef = React.useRef(null);
  const { token } = useParams();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Required")
        .min(8, "At least 8 characters "),
    }),
    onSubmit: async (values) => {
      const isConfirm = checkConfirm(
        confirmInputRef.current.value,
        values,
        formik
      );
      if (isConfirm) {
        const { status, message } = await onSubmit(
          confirmInputRef.current.value,
          values,
          token
        );
        if (status === 200) {
          toast.success(message);
          history.replace("/signin");
        } else {
          toast.error(message);
        }
      }
    },
  });
  const checkConfirm = React.useCallback(
    (confirmPassword, password, formik) =>
      checkPasswordAndConfirm(confirmPassword, password, formik),
    []
  );
  React.useEffect(() => {
    return () => {};
  });
  return (
    <div
      style={{ minHeight: "90vh" }}
      className="container d-flex align-items-center justify-content-center "
    >
      <div className="row p-0 m-0">
        <form
          onSubmit={formik.handleSubmit}
          className="card p-0 m-0 col-12 shadow "
        >
          <div className="card  custome-card-header p-3 mb-3">
            Reset Old Password
          </div>
          <div className="row p-0 m-3">
            <div className=" col-12  form-group p-1  pl-2 pr-2">
              <input
                className="form-control"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Password"
              />
              {formik.errors.password && formik.touched.password && (
                <p className="text-danger mt-3">{formik.errors.password}</p>
              )}
            </div>
            <div className=" col-12  form-group  p-1  pl-2 pr-2 mt-4">
              <input
                ref={confirmInputRef}
                className="form-control"
                name="confirm"
                placeholder="Confirm Password"
              />
              {formik.errors.confirm && (
                <p className="mt-3 text-danger">{formik.errors.confirm}</p>
              )}
            </div>
            <button className="btn btn-primary m-1 text-white mt-3 col-4">
              Set Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Confirm;
