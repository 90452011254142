import { createContext, useState } from "react";
import { useMutation } from "react-query";
import { getAllCarsSold } from "../utils/all-cars/all_cars";


export const AllCarsState = createContext();
export const AllCarsDispatch = createContext();

const InventoryVehicle = ({ children }) => {
  const [allCars, setAllCars] = useState();
  // useEffect(() => {
  //   checkUserDealVehicles();
  // }, []);
  // const checkUserDealVehicles = async () => {
  //   if (cook.get("token")) {
  //     const _res = await fetch(`${BASE_URL}deal/advance/search`, {
  //       method: "POST",
  //       credentials: "include",
  //       headers: {
  //         authorization: `Bearer ${cook.get("token")}`,
  //       },
  //       body: {},
  //     });
  //     const _resJson = await _res.json();
  //     if (_res.status === 200) {
  //       setAllCars(_resJson);
  //     }
  //   } else return setAllCars(false);
  // };
  const getAllVehicleSold = useMutation("getVehicles", getAllCarsSold, {
    onSuccess: (data, variable) => {
      setAllCars(data);
      // allCarsDeal = data;
    },
  });
  const checkUserDealVehicles = () => {
    getAllVehicleSold.mutate();
  };
  return (
    <AllCarsState.Provider
      value={{ setAllCars, allCars, checkUserDealVehicles, getAllVehicleSold }}
    >
      {children}
    </AllCarsState.Provider>
  );
};
export default InventoryVehicle;
