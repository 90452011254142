import React, { useEffect, useState } from "react";
import { useGetAllCustomers } from "../../../../utils/all-customers/all_customers";
import { reactSelectStyle } from "../../../../constant/react_select_style";
import Select from "react-select";
import SearchCity from "../../all-customers/searchcity";
import { useQuery } from "react-query";
import { getAllCitiesV2 } from "../../../../utils/common/city/city";
import { getSingelDealershipUser } from "../../../../utils/dealership-users/dealership_users";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useGetAllCitiesWithDetail } from "../../../../hooks/useGetAllCitiesWithDetail";
import { useIsAuthState } from "../../../../hooks/useAuth";
import SearchCityDeposit from "./searchCityDeposit";
import { Modal, OverlayTrigger, Tooltip, Tabs } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import BillOfSaleSingleVehicleNewCustomer from "../bill-of-sale-single-vehicle/bill_of_sale_single_vehicle_new_customer";
import EditCustomerFilter from "../../all-customers/edit_customer_filter";
import { Tab } from "react-tabs";

const ChooseCustomer = ({ formik }) => {
  const momentTimezone = require("moment-timezone");
  const { id } = useParams();
  const auth = useIsAuthState();

  const { isLoading, isFetching, isError, isSuccess, data } =
    useGetAllCustomers();
  const [coustomer, setCustomer] = useState();
  const [selected, setSelected] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [provinceOption, setProvinceOption] = useState([]);
  const [getcityOption, setGetcityOption] = useState();
  const [displaysearch, setdisplaysearch] = useState(false);
  const [countryName, setCounrayName] = useState("");
  const [selectValue, setSelectValue] = useState(null);
  const getAllCitiesDataV2 = useQuery("getAllCitiesDataV2", getAllCitiesV2, {});
  const [provinceName, setProvinceName] = useState({
    label: "",
    value: "",
  });
  const [simplesearchname, setsimplesearchname] = useState(null);
  const { options, loading } = useGetAllCitiesWithDetail();
  const [showBOF, setShowBOF] = useState(false);
  const [showCustomers, setShowcustomers] = useState(false);
  const [userId, setUserId] = useState(null);
  const [addId, setAddId] = useState();
  const [key, setKey] = useState(1);

  const handleBOFClose = () => {
    setShowBOF(false);
  };
  const handleBOFOpen = (originalId) => {
    setShowBOF(true);
  };
  const toggleExpanded = () => {
    setShowcustomers((showCustomers) => !showCustomers);
    setActiveIndex(0);
  };
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // Set default tab to the first tab

    setActiveIndex(0);
  }, []);

  const handleSelect = (key) => {
    setActiveIndex(key);
  };

  const handleTabClose = (index) => {
    // Get the next tab
    const nextTab = index === 0 ? 0 : index - 1;

    // Set active tab to the next tab
    setActiveIndex(nextTab);
  };
  useEffect(() => {
    setProvinceName({
      label: "",
      value: "",
    });
  }, []);
  let provOption = [];

  useEffect(() => {
    options?.map((opt) => {
      if (!provOption.includes(opt?.province)) {
        provOption.push(opt?.province);
      }
    });
    if (provOption && provinceOption?.length === 0)
      setProvinceOption(provOption);
  }, [options]);
  useEffect(() => {
    formik.setFieldValue("city", simplesearchname);
  }, [simplesearchname]);

  useEffect(() => {
    formik.setFieldValue("province", provinceName?.value);
  }, [provinceName]);

  const handleChange = (event) => {
    if (event.target.value.length > 0) {
      setdisplaysearch(true);
    } else {
      setdisplaysearch(false);
    }
    setSearchTerm(event.target.value?.toLowerCase());
    setsimplesearchname(event.target.value);
  };

  let doubles = getAllCitiesDataV2?.data?.map((city) => {
    return {
      value: city.city,
      abbreviation: city?.Province?.abbreviation,
      province: city?.Province?.province,
      country: city?.Province?.Country?.country,
    };
  });

  useEffect(() => {
    if (doubles) {
      setTimeout(() => {
        const result2 = doubles?.filter((city) =>
          city?.value?.toLowerCase()?.includes(searchTerm)
        );

        let result3 = result2?.filter((data, index) => {
          return result2.indexOf(data) === index;
        });
        setSearchResults(result3);
      }, 300);
    }
  }, [searchTerm]);
  const simplesearches = () => {
    setdisplaysearch(false);
  };
  useEffect(() => {
    const options = data?.map((customer) => {
      return {
        id: customer.id,
        key: customer.id,
        label: customer?.full_name
          ? `${customer?.full_name}`
          : customer?.f_name || customer?.l_name
          ? `${customer?.f_name} ${customer?.l_name}`
          : "",
        value: customer?.id,
        MidUcdaCustomers: {
          UserId: customer?.id,
        },
      };
    });
    setCustomer(options);
  }, [data]);
  const handleSelectChange = (e) => {
    setSelected(e);
  };
  useEffect(() => {
    if (selected?.length !== 0) {
      const matchingItem = data.find((item) => item.id === selected?.id);
      let names = "";
      if (formik?.values?.l_name && formik?.values?.f_name) {
        names = formik?.values?.f_name + " " + formik?.values?.l_name;
      } else if (formik?.values?.f_name) {
        names =
          formik?.values?.f_name +
          " " +
          (matchingItem?.l_name && matchingItem?.l_name);
      } else if (formik?.values?.l_name) {
        names =
          (matchingItem?.f_name && matchingItem?.f_name) +
          " " +
          formik?.values?.l_name;
      } else {
        names =
          (matchingItem?.f_name && matchingItem?.f_name) +
          " " +
          (matchingItem?.l_name && matchingItem?.l_name);
      }
      const name = names;
      formik.setFieldValue("f_name", matchingItem?.f_name);
      formik.setFieldValue("l_name", matchingItem?.l_name);
      formik.setFieldValue(
        "full_name",
        matchingItem?.full_name
          ? matchingItem?.full_name
          : name && name !== "undefined undefined"
          ? name
          : ""
      );
      formik.setFieldValue(
        "driver_license_NO",
        matchingItem?.driver_license_NO
      );
      formik.setFieldValue(
        "driver_license_expire_date",
        matchingItem?.driver_license_expire_date
          ? momentTimezone
              .tz(
                `${matchingItem?.driver_license_expire_date}`,
                `${auth?.dealershipInfo?.business_city?.timezone}`
              )
              .startOf("day")
              .add(12, "hours")
              .format("YYYY-MM-DD")
          : null
      );
      formik.setFieldValue(
        "city",
        matchingItem?.city &&
          matchingItem?.city !== undefined &&
          matchingItem?.city !== "undefined"
          ? matchingItem?.city
          : ""
      );
      setsimplesearchname(
        matchingItem?.city &&
          matchingItem?.city !== undefined &&
          matchingItem?.city !== "undefined"
          ? matchingItem?.city
          : ""
      );
      formik.setFieldValue(
        "province",
        matchingItem?.province &&
          matchingItem?.province !== undefined &&
          matchingItem?.province !== "undefined"
          ? matchingItem?.province
          : ""
      );
      setProvinceName({
        label:
          matchingItem?.province &&
          matchingItem?.province !== undefined &&
          matchingItem?.province !== "undefined"
            ? matchingItem?.province
            : "",
        value:
          matchingItem?.province &&
          matchingItem?.province !== undefined &&
          matchingItem?.province !== "undefined"
            ? matchingItem?.province
            : "",
      });
      formik.setFieldValue("postalcode", matchingItem?.postalcode);
      formik.setFieldValue("mobile", matchingItem?.mobile);
      formik.setFieldValue("fax_NO", matchingItem?.fax_NO);
      formik.setFieldValue("email", matchingItem?.email);
      formik.setFieldValue("address", matchingItem?.address);
    }
  }, [selected, data]);
  useEffect(() => {
    const matchingItem = data?.find((item) => item.id === selected?.id);
    let names = "";
    if (formik?.values?.l_name && formik?.values?.f_name) {
      names = formik?.values?.f_name + " " + formik?.values?.l_name;
    } else if (formik?.values?.f_name) {
      names =
        formik?.values?.f_name +
        " " +
        (matchingItem?.l_name && matchingItem?.l_name);
    } else if (formik?.values?.l_name) {
      names =
        (matchingItem?.f_name && matchingItem?.f_name) +
        " " +
        formik?.values?.l_name;
    } else {
      names =
        (matchingItem?.f_name && matchingItem?.f_name) +
        " " +
        (matchingItem?.l_name && matchingItem?.l_name);
    }
    const name = names;
    formik.setFieldValue("full_name", name);
  }, [formik?.values?.f_name, formik?.values?.l_name, data]);
  return (
    <>
      <Modal
        show={showBOF}
        onHide={handleBOFClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName=""
      >
        <Modal.Header className="vehicle_modal_header-style border-0">
          <Modal.Title className="inner_header">New Customer</Modal.Title>
          <button
            className="btn-close"
            onClick={() => {
              handleBOFClose();
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={activeIndex}
            onSelect={handleSelect}
            className="mb-3 d-flex flex-row "
          >
            <Tab eventKey="0" title="Customer">
              <BillOfSaleSingleVehicleNewCustomer
                toggleExpanded={toggleExpanded}
                setAddId={setAddId}
                setUserId={setUserId}
                handleBOFClose={handleBOFClose}
                setSelected={setSelected}
              />
            </Tab>
            {showCustomers === true && (
              <Tab eventKey="1" title="Edit Customer">
                <EditCustomerFilter
                  addId={addId}
                  setKey={setKey}
                  toggleExpanded={toggleExpanded}
                  setSelected={setSelected}
                  handleBOFClose={handleBOFClose}
                />
              </Tab>
            )}
          </Tabs>
        </Modal.Body>
      </Modal>
      <div className="p-0 m-0 w-100 row">
        <div className="p-0 m-0 w-100 py-2 d-flex justify-content-between align-items-center">
          <h2 className="p-0 m-0 mx-2">Buyer's Information</h2>
        </div>
        <div className="p-0 m-0 w-100 p-2 row">
          <div className="p-0 m-0 col-4 p-2">
            <div className="p-0 m-0 w-100 d-flex justify-content-start align-items-center">
              <Select
                styles={reactSelectStyle}
                options={coustomer ? coustomer : []}
                id="customerInfo"
                name="customerInfo"
                className="col-9"
                onChange={handleSelectChange}
                value={selected}
              />
              <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                overlay={<Tooltip className="">Add New Customer</Tooltip>}
              >
                <button
                  className="btn p-0 px-3 m-0 mx-5 col-2 table_page_botton shadow"
                  onClick={(e) => {
                    e.preventDefault();
                    handleBOFOpen();
                  }}
                >
                  <FaPlus />
                </button>
              </OverlayTrigger>
            </div>
          </div>
          <div className="col-12" />
          {/* <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>Full Name</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="full_name"
              name="full_name"
              value={formik.values.full_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div> */}
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>First Name</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="f_name"
              name="f_name"
              value={formik.values.f_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="l_name"
              name="l_name"
              value={formik.values.l_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>Driver license number</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="driver_license_NO"
              name="driver_license_NO"
              value={formik.values.driver_license_NO}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>driver license expiring date</label>
            <input
              type="date"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="driver_license_expire_date"
              name="driver_license_expire_date"
              value={formik.values.driver_license_expire_date}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              min="1970-01-01"
                            max="9999-01-01"
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>Address</label>
            <textarea
              type="text"
              className="form-control form_textarea-style "
              id="address"
              name="address"
              value={formik.values.address}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>City</label>
            <input
              className="form-control rounded rounded-pill form_input-style mt-1"
              type="text"
              placeholder="Search..."
              // defaultValue={getSingleUser?.data?.city}
              onChange={handleChange}
              value={simplesearchname}
            />

            <div
              className={`p-1 m-0 bg-white ${
                displaysearch
                  ? "d-flex p-0 m-0 result-input-simple-search rounded"
                  : "d-none"
              } flex-column `}
              style={{
                zIndex: 20000,
              }}
            >
              {searchResults?.map((item) => (
                <SearchCityDeposit
                  key={`ChooseCustomerSearch${item?.idd}`}
                  item={item}
                  simplesearches={simplesearches}
                  setsimplesearchname={setsimplesearchname}
                  formik={formik}
                  setCounrayName={setCounrayName}
                  setProvinceName={setProvinceName}
                  setSelectValue={setSelectValue}
                  setdisplaysearch={setdisplaysearch}
                  setvalue={formik.setFieldValue}
                />
              ))}
            </div>
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>Province/State</label>
            <Select
              styles={reactSelectStyle}
              id="province"
              name="province"
              value={provinceName}
              options={provinceOption?.map((opt) => ({
                label: opt,
                value: opt,
              }))}
              className="mt-1 w-100"
              onChange={(e) => {
                setProvinceName(e);
                if (e !== null) {
                  setGetcityOption(
                    options.filter((data) => data?.province === e.label)
                  );
                }
              }}
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>Postalcode</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="postalcode"
              name="postalcode"
              value={formik.values.postalcode}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>Mobile</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="mobile"
              name="mobile"
              value={formik.values.mobile}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>Fax number</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="fax_NO"
              name="fax_NO"
              value={formik.values.fax_NO}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
            <label>E-mail</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="email"
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseCustomer;
