import SimpleLoading from "../../../common/loading/simple_loading";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { physicalLeadCustomerSignUp } from "../../../../utils/lead-center/lead_center";
import {
  useGetAddressWithPostalCode,
  useGetAllCitiesWithDetail,
} from "../../../../hooks/useGetAllCitiesWithDetail";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { compressImage } from "../../../../utils/common/compress_file";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaSearchLocation } from "react-icons/fa";
import Select, { components } from "react-select";
import { reactSelectStyle } from "../../../../constant/react_select_style";
import {
  BcIdCustomer,
  FilterCustomer,
  getAllCustomer,
} from "../../../../utils/all-customers/all_customers.js";
import FilterCustomerResualt from "../../../admin-component/all-customers/filterCustomer";
import { getAllCitiesV2 } from "../../../../utils/common/city/city";
import SearchCity from "../../all-customers/searchcity";
import NumberFormat from "react-number-format";
import { useIsAuthState } from "../../../../hooks/useAuth.jsx";

const BillOfSaleSingleVehicleNewCustomer = (props) => {
  const { setUserId, handleBOFClose, toggleExpanded, setAddId, setSelected, userId, setShowBOF } =
    props;

  console.log({ userId })
  const auth = useIsAuthState();
  const queryCilent = useQueryClient();
  const getAllCustomerQuery = useMutation(
    "getAllCustomerQuery",
    getAllCustomer,
    {}
  );


  const postNewCustomerForBOSSingleVehicle = useMutation(
    "postNewCustomerForBOSSingleVehicle",
    physicalLeadCustomerSignUp,
    {
      onSuccess: (data) => {
        setUserId(data?.Id);
        queryCilent.invalidateQueries("all-customer");
        // queryCilent.invalidateQueries("getAllCustomerQuery");
        queryCilent.invalidateQueries("getAllCustomerQuery");
        // getAllCustomerQuery.mutate()

        // console.log("77777777777777777777", data)

        setSelected({
          id: data?.Id,
          key: data?.Id,
          label: data?.user?.full_name
            ? `${data?.user?.full_name}`
            : data?.user?.f_name || data?.user?.l_name
              ? `${data?.user?.f_name} ${data?.user?.l_name}`
              : "",
          value: data?.user?.id,
          MidUcdaCustomers: {
            UserId: data?.user?.id,
          },
        });
        formik.handleReset();
        handleBOFClose();
        setShowBOF(false)
      },
    }
  );
  const { options, loading } = useGetAllCitiesWithDetail();
  const { query, queryIsLoading } = useGetAddressWithPostalCode({
    successFunction: (data) => {
      const { option, restOfData } = data;
      if (option) {
        formik.setFieldValue("frk_city", option);
        formik.setFieldValue("city", data?.option?.city);
        setProvinceName(option?.province);
        setCounrayName(option?.country);
      }
      if (restOfData?.street) {
        formik.setFieldValue("address", restOfData?.street);
      }
    },
  });
  // add new city//
  const getAllCitiesDataV2 = useQuery("getAllCitiesDataV2", getAllCitiesV2, {});

  const [simplesearchname, setsimplesearchname] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [displaysearch, setdisplaysearch] = useState(false);

  const [selectValue, setSelectValue] = useState(null);
  const NoOptionsMessage = (props) => {
    useEffect(() => {
      setSelectValue(props?.selectProps?.inputValue);
    }, [props?.selectProps?.inputValue]);
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Results Found </span>
      </components.NoOptionsMessage>
    );
  };
  useEffect(() => {
    formik.setFieldValue("city", simplesearchname);
  }, [simplesearchname]);

  const handleChange = (event) => {
    if (event.target.value.length > 0) {
      setdisplaysearch(true);
    } else {
      setdisplaysearch(false);
    }
    setSearchTerm(event.target.value?.toLowerCase());
    setsimplesearchname(event.target.value);
  };

  let doubles = getAllCitiesDataV2?.data?.map((city) => {
    return {
      value: city.city,
      abbreviation: city?.Province?.abbreviation,
      province: city?.Province?.province,
      country: city?.Province?.Country?.country,
    };
  });

  useEffect(() => {
    if (doubles) {
      const result2 = doubles?.filter((city) =>
        city?.value?.toLowerCase()?.includes(searchTerm)
      );

      let result3 = result2?.filter((data, index) => {
        return result2.indexOf(data) === index;
      });
      setSearchResults(result3);
    }
  }, [searchTerm]);
  const simplesearches = () => {
    setdisplaysearch(false);
  };

  const [name, setName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [countryName, setCounrayName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [checkBcId, setcheckBcId] = useState("Driver licence number");
  const BcIdCustomerQuery = useQuery("BcIdCustomerQuery", BcIdCustomer, {});
  const authenticationOptions = BcIdCustomerQuery?.data?.map((vehicle) => {
    return {
      label: vehicle,
      value: vehicle,
    };
  });

  const handlePostalCodeApi = () => query(formik.values.postalcode);
  const formik = useFormik({
    initialValues: {
      f_name: "",
      l_name: "",
      full_name: "",
      email: "",
      mobile: "",
      address: "",
      province: "",
      gender: 0,
      birthdate: "",
      postalcode: "",
      driver_license_NO: "",
      driver_license_expire_date: "",
      driver_license_issue_date: "",
      aparteman_no: "",
      driver_license_photo: null,
      company_name: "",
      city: null,
      country: "",
      bcid: "",
      customer_authentication_method: "",
    },

    onSubmit: (value) => {
      postNewCustomerForBOSSingleVehicle.mutate(value);
    },
    enableReinitialize: true,
  });

  const handelImageChange = async (e) => {
    if (e !== null && e.target.files.length !== 0) {
      const file = await compressImage(e.target.files[0], 0.3, 1024);
      formik.setFieldValue("driver_license_photo", file);
    }
  };
  // const showErrorDate = () => {
  //   const exDate = new Date(formik.values.driver_license_expire_date).getTime();
  //   const issuedDate = new Date(
  //     formik.values.driver_license_issue_date
  //   ).getTime();
  //   if (exDate > issuedDate || isNaN(exDate) || isNaN(issuedDate)) {
  //     return false;
  //   }
  //   return true;
  // };
  //filter customer//
  const FilterCustomerQuery = useQuery(
    "FilterCustomerQuery",
    FilterCustomer,
    {}
  );

  const filteredData = FilterCustomerQuery?.data?.data?.filter((item) => {
    return (
      item?.f_name?.toLowerCase()?.includes(name?.toLowerCase()) &&
      item?.l_name?.toLowerCase()?.includes(lName?.toLowerCase()) &&
      item?.email?.toLowerCase()?.includes(email?.toLowerCase()) &&
      item?.mobile?.toString()?.includes(phone?.toString()) &&
      item?.address?.toLowerCase()?.includes(address?.toLowerCase()) &&
      item?.postalcode?.toString()?.includes(postalCode?.toString())
    );
  });

  const [getcityOption, setGetcityOption] = useState();
  const [provinceOption, setProvinceOption] = useState([]);
  let provOption = [];
  useEffect(() => {
    options?.map((opt) => {
      if (!provOption.includes(opt?.province)) {
        provOption.push(opt?.province);
      }
    });
    if (provOption && provinceOption?.length === 0)
      setProvinceOption(provOption);
  }, [options]);
  // const isInitialMountRent = useRef(true);
  // const getAllCitiesDataV2 = useQuery("getAllCitiesDataV2", getAllCitiesV2, {});
  // const option = getAllCitiesDataV2?.data?.map((city) => {
  //   return {
  //     value: city.city,
  //     label: (
  //       <div>
  //         <span>{city?.city},</span>
  //         <span style={{ fontSize: "12px", color: "#858585" }}>
  //           {" "}
  //           {city?.Province?.abbreviation} {/* {city?.province} */}
  //         </span>
  //       </div>
  //     ),
  //     province: city?.Province?.province,
  //     country: city?.Province?.Country?.country,
  //   };
  // });

  // const NoOptionsMessage = (props) => {
  //   useEffect(() => {
  //     setSelectValue(props?.selectProps?.inputValue);
  //   }, [props?.selectProps?.inputValue]);
  //   return (
  //     <components.NoOptionsMessage {...props}>
  //       <span className="custom-css-class">No Results Found </span>
  //     </components.NoOptionsMessage>
  //   );
  // };
  // useEffect(() => {
  //   if (isInitialMountRent.current) {
  //     isInitialMountRent.current = false;
  //   } else {
  //     if (selectValue !== "") {
  //       formik.setFieldValue("city", selectValue);
  //     }
  //   }
  // }, [selectValue]);
  // const customSingleValue = ({ children }) => {
  //   return <div>{formik?.values?.city === children}</div>;
  // };
  useEffect(() => {
    setEmail(formik.values.email);
  }, [formik.values.email]);
  useEffect(() => {
    setPhone(formik.values.mobile);
  }, [formik.values.mobile]);
  useEffect(() => {
    setAddress(formik.values.address);
  }, [formik.values.address]);
  useEffect(() => {
    setPostalCode(formik.values.postalcode);
  }, [formik.values.postalcode]);
  useEffect(() => {
    setName(formik.values.f_name);
  }, [formik.values.f_name]);
  useEffect(() => {
    setLName(formik.values.l_name);
  }, [formik.values.l_name]);
  useEffect(() => {
    if (provinceName?.value) {
      formik.setFieldValue("province", provinceName);
    } else {
      formik.setFieldValue("province", {
        label: provinceName,
        value: provinceName,
      });
    }
  }, [provinceName]);

  return (
    <div className="p-0 m-0 d-flex flex-warp justify-content-start align-items-start">
      <form
        onSubmit={formik.handleSubmit}
        className={`${name || lName || email || phone || address || postalCode
          ? "ss p-1 m-0 col-lg-8 col-7 d-flex row justify-content-start align-items-start"
          : "ss p-1 m-0 col-12 d-flex row justify-content-start align-items-start"
          }`}
      >
        <div className="p-0 m-0  d-flex justify-content-start align-items-center">
          <span
            className="p-0 m-0 mx-2 shadow condition_points "
            style={{ backgroundColor: "#9D4EDD" }}
          ></span>
          <h4 className="inner_header my-1">Personal information</h4>
        </div>
        <div className="p-0 m-0  w-100 d-flex row align-items-start justify-content-start">
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>First name</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="f_name"
              name="f_name"
              value={formik.values.f_name}
              onBlur={formik.handleBlur}
              // onChange={(e) => setName(e.target.value)}
              onChange={formik.handleChange}
            />
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>Last name</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="l_name"
              name="l_name"
              value={formik.values.l_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            // onChange={(e) => setLName(e.target.value)}
            />
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>Full Name</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="full_name"
              name="full_name"
              value={formik.values.full_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>Company/Cosigner</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="company_name"
              name="company_name"
              value={formik.values.company_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>Gender</label>
            <select
              type="text"
              className="form-select rounded rounded-pill form_input-style mt-1 "
              id="gender"
              name="gender"
              value={formik.values.gender}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            >
              <option value="0">Male</option>
              <option value="1">Female</option>
            </select>
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>Birthdate</label>
            <input
              type="date"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="birthdate"
              name="birthdate"
              value={formik.values.birthdate}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              min="1000-01-01"
              max="9999-01-01"
            />
          </div>
          <div className="p-0 m-0   d-flex justify-content-start align-items-center">
            <span
              className="p-0 m-0 mx-2 shadow condition_points "
              style={{ backgroundColor: "#5A189A" }}
            ></span>
            <h4 className="inner_header  my-1">Contact information</h4>
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>E-mail</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="email"
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              // onChange={(e) => setEmail(e.target.value)}
              onChange={formik.handleChange}
            />
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>Mobile</label>
            <input
              type="text"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="mobile"
              name="mobile"
              value={formik.values.mobile}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                // setPhone(e.value);
                formik.setFieldValue("mobile", e.target.value);
              }}
            />
            {/* <NumberFormat
              // format='(###) ###-####'
              mask='_'
              allowEmptyFormatting={true}
              onValueChange={(e) => {
                // setPhone(e.value);
                formik.setFieldValue("mobile", e.value)
              }}
              placeholder=''
              onBlur={formik.handleBlur}
              name='mobile'
              id='mobile'
              className="form-control rounded rounded-pill form_input-style mt-1 "
              value={formik.values.mobile}
              // onChange={(e) => setPhone(e.target.value)}
            /> */}
            {/* {formik.values.mobile?.length < 10 &&
            formik.values.mobile?.length !== 0 ? (
              <div className="text-danger">Wrong Mobile Number</div>
            ) : (
              ""
            )} */}
          </div>
          <div className="p-0 m-0   d-flex justify-content-start align-items-center">
            <span
              className="p-0 m-0 mx-2 shadow condition_points "
              style={{ backgroundColor: "#5A189A" }}
            ></span>
            <h4 className="inner_header  my-1">Address information</h4>
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-1 m-0 col-12 col-xl-6  d-flex flex-column justify-content-center align-items-start"
              : "form-group p-1 m-0 col-12 col-md-6  d-flex flex-column justify-content-center align-items-start"
              }`}
          >
            <label>Mailing address</label>
            <textarea
              type="text"
              id="address"
              name="address"
              placeholder="Enter Vendor Address"
              className="form-control form_textarea-style "
              value={formik.values.address}
              onBlur={formik.handleBlur}
              // onChange={(e) => setAddress(e.target.value)}
              onChange={formik.handleChange}
            />
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              : "form-group p-1 m-0 col-12  col-md-3   d-flex flex-column justify-content-center align-items-start"
              }`}
          >
            <label>APT.Number</label>
            <input
              type="text"
              id="aparteman_no"
              name="aparteman_no"
              placeholder="Enter Vendor APT .Number"
              className="form-control rounded rounded-pill form_input-style mt-1"
              value={formik.values.aparteman_no}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "p-1 m-0 col-md-3 col-12"
              : "p-1 m-0 col-xl-3 col-12"
              }`}
          >
            <div className="d-flex align-items-end justify-content-start w-100">
              <div className="form-group">
                <label>Postalcode/Zip</label>
                <input
                  type="text"
                  className="form-control rounded rounded-pill form_input-style mt-1 "
                  id="postalcode"
                  name="postalcode"
                  value={formik.values.postalcode}
                  onBlur={formik.handleBlur}
                  // onChange={(e) => setPostalCode(e.target.value)}
                  onChange={formik.handleChange}
                />
              </div>

              <button
                className="p-0 m-2 bg-white border-0"
                type="button"
                onClick={handlePostalCodeApi}
                disabled={formik.values.postalcode?.length === 0}
              >
                {queryIsLoading ? (
                  <SimpleLoading />
                ) : (
                  <OverlayTrigger
                    placement="bottom"
                    trigger={["hover", "focus"]}
                    overlay={<Tooltip>Search for address</Tooltip>}
                  >
                    <i className="p-0 m-0 edit_icon-style">
                      <FaSearchLocation />
                    </i>
                  </OverlayTrigger>
                )}
              </button>
            </div>
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group col-12 col-md-4 p-1 m-0"
              }`}
          >
            <label>City</label>
            {/* <Select
              styles={reactSelectStyle}
              components={{
                NoOptionsMessage,
                SingleValue: customSingleValue,
              }}
              placeholder={selectValue ? selectValue : " Choose your city"}
              id="city"
              name="city"
              value={formik.values.city}
              options={option}
              onBlur={formik.handleBlur}
              className="mt-1 cityInput"
              onChange={(e) => {
                formik.setFieldValue("city", e.value);
                setSelectValue(e.value);
                setProvinceName({ label: e?.province, value: e?.province });
                setCounrayName(e?.country);
              }}
            /> */}
            <input
              className="form-control rounded rounded-pill form_input-style mt-1"
              type="text"
              placeholder="Search..."
              defaultValue={formik.values.city}
              onChange={handleChange}
              value={formik.values.city}
            />

            <div
              className={`p-1 m-0 bg-white ${displaysearch
                ? "d-flex p-0 m-0 result-input-simple-search rounded"
                : "d-none"
                } flex-column `}
              style={{
                zIndex: 20000,
              }}
            >
              {searchResults?.map((item) => (
                <SearchCity
                  key={`homepageSearchBillOfSale${item?.idd}`}
                  item={item}
                  simplesearches={simplesearches}
                  setsimplesearchname={setsimplesearchname}
                  formik={formik}
                  setCounrayName={setCounrayName}
                  setProvinceName={setProvinceName}
                  setSelectValue={setSelectValue}
                  setdisplaysearch={setdisplaysearch}
                  setvalue={formik.setFieldValue}
                />
              ))}
            </div>
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-1 m-0 col-12  col-md-4   d-flex flex-column justify-content-center align-items-star"
              }`}
          >
            <label>Province/State</label>
            <Select
              // styles={reactSelectStyle}
              // placeholder="Choose your city"
              styles={reactSelectStyle}
              id="Province/State"
              name="Province/State"
              value={formik.values.province}
              options={provinceOption?.map((opt) => ({
                label: opt,
                value: opt,
              }))}
              // onBlur={formik.handleBlur}
              className="mt-1 w-100"
              onChange={(e) => {
                setProvinceName(e);
                if (e !== null) {
                  setGetcityOption(
                    options.filter(
                      (data) => data?.province === e.label
                      // console.log({ data: data?.province === e.label })
                    )
                  );
                  // formik.setFieldValue("businessCityId", e);
                  // setProvinceName(e?.province);
                  // setCounrayName(e?.country);
                }
              }}
            />
          </div>
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-1 m-0 col-12  col-xl-4   d-flex flex-column justify-content-center align-items-start "
              : "form-group p-1 m-0 col-12  col-md-4   d-flex flex-column justify-content-center align-items-start"
              }`}
          >
            <label>Country</label>
            <input
              type="text"
              id="Country"
              name="Country"
              className="form-control rounded rounded-pill form_input-style mt-1"
              value={formik.values.country}
              disabled
            />
          </div>
          <div className="p-0 m-0   d-flex justify-content-start align-items-center">
            <span
              className="p-0 m-0 mx-2 shadow condition_points "
              style={{ backgroundColor: "#5A189A" }}
            ></span>
            <h4 className="inner_header  my-1">Driver license information</h4>
          </div>

          {auth?.dealershipInfo?.business_city?.Province?.id === 3 ? (
            <>
              <div
                className={`${name || lName || email || phone || address || postalCode
                  ? "form-group p-1 m-0 col-12  col-xl-4   d-flex flex-column justify-content-center align-items-start "
                  : "form-group p-1 m-0 col-12  col-md-4   d-flex flex-column justify-content-center align-items-start"
                  }`}
              >
                <label>Authentication Management</label>
                <Select
                  options={authenticationOptions ? authenticationOptions : []}
                  id="customer_authentication_method"
                  name="customer_authentication_method"
                  value={formik.values.customer_authentication_method}
                  onChange={(e) => {
                    formik.setFieldValue("customer_authentication_method", e);
                    setcheckBcId(e?.value);
                  }}
                  className="p-0 m-0 w-100"
                  styles={reactSelectStyle}
                  isClearable={true}
                />
              </div>
              {checkBcId === "Driver licence number" && (
                <div
                  className={`${name || lName || email || phone || address || postalCode
                    ? "form-group p-1 m-0 col-12  col-xl-4   d-flex flex-column justify-content-center align-items-start "
                    : "form-group p-1 m-0 col-12  col-md-4   d-flex flex-column justify-content-center align-items-start"
                    }`}
                >
                  <label>Driver license number</label>
                  <input
                    type="text"
                    className="form-control rounded rounded-pill form_input-style mt-1 "
                    id="driver_license_NO"
                    name="driver_license_NO"
                    value={formik.values.driver_license_NO}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
              )}
              {checkBcId === "BCID" && (
                <div
                  className={`${name || lName || email || phone || address || postalCode
                    ? "form-group p-1 m-0 col-12  col-xl-4   d-flex flex-column justify-content-center align-items-start "
                    : "form-group p-1 m-0 col-12  col-md-4   d-flex flex-column justify-content-center align-items-start"
                    }`}
                >
                  <label>BCID</label>
                  <input
                    type="text"
                    className="form-control rounded rounded-pill form_input-style mt-1 "
                    id="bcid"
                    name="bcid"
                    value={formik.values.bcid}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
              )}
            </>
          ) : (
            <div
              className={`${name || lName || email || phone || address || postalCode
                ? "form-group p-1 m-0 col-12  col-xl-4   d-flex flex-column justify-content-center align-items-start "
                : "form-group p-1 m-0 col-12  col-md-4   d-flex flex-column justify-content-center align-items-start"
                }`}
            >
              <label>Driver license number</label>
              <input
                type="text"
                className="form-control rounded rounded-pill form_input-style mt-1 "
                id="driver_license_NO"
                name="driver_license_NO"
                value={formik.values.driver_license_NO}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </div>
          )}

          {/* <div
            className={`${
              name || lName || email || phone || address || postalCode
                ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
                : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
            }`}
          >
            <label>driver license issued date</label>
            <input
              type="date"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="driver_license_issue_date"
              name="driver_license_issue_date"
              value={formik.values.driver_license_issue_date}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div> */}
          <div
            className={`${name || lName || email || phone || address || postalCode
              ? "form-group p-px-3 py-2 m-0 col-xl-4  col-12"
              : "form-group p-px-3 py-2 m-0 col-md-4 col-sm-6 col-12"
              }`}
          >
            <label>driver license expiring date</label>
            <input
              type="date"
              className="form-control rounded rounded-pill form_input-style mt-1 "
              id="driver_license_expire_date"
              name="driver_license_expire_date"
              value={formik.values.driver_license_expire_date}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              min="1000-01-01"
              max="9999-01-01"
            />
          </div>
          <div className="d-flex  flex-column justify-content-start align-items-start p-2 col-12 col-sm-10 col-md-12">
            <label
              htmlFor="driver_license_photo"
              className="btn form_submit-btn px-5 shadow"
            >
              driver license image
            </label>
            <input
              type="file"
              id="driver_license_photo"
              name="driver_license_photo"
              onChange={handelImageChange}
              className="d-none"
            />
            {formik.values.driver_license_photo !== null && (
              <img
                src={URL.createObjectURL(formik.values.driver_license_photo)}
                alt=""
                className="p-0 m-0 mt-2"
                style={{ width: "200px", height: "auto" }}
              />
            )}
          </div>

          <div className="p-4 m-0 my-3 d-flex justify-content-end">
            {postNewCustomerForBOSSingleVehicle.isLoading ? (
              <SimpleLoading />
            ) : (
              <button
                type="submit"
                className="btn form_submit-btn px-5 shadow"
              // disabled={showErrorDate()}
              >
                Add
              </button>
            )}
          </div>
          {/* {showErrorDate() && ( */}
          {/* <div className="p-0 m-0 my-3 text-end">
              <span className="p-0 m-0 mx-5 text-danger">
                Please check license dates
              </span>
            </div> */}
          {/* )} */}
        </div>
      </form>
      {name || lName || email || phone || address || postalCode ? (
        <div
          className="col-lg-4 col-5 d-flex flex-column"
          style={{ border: " 0.5px solid #80808033", borderRadius: "10px" }}
        >
          {filteredData?.map((item) => (
            <FilterCustomerResualt
              keys={item?.id}
              item={item}
              name={name}
              phone={phone}
              lName={lName}
              email={email}
              address={address}
              postalCode={postalCode}
              toggleExpanded={toggleExpanded}
              setAddId={setAddId}
            />
          ))}
        </div>
      ) : undefined}
    </div>
  );
};

export default BillOfSaleSingleVehicleNewCustomer;
