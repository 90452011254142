import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAllCities,
  getAllCitiesV2,
  getAllProvinces,
} from "../utils/common/city/city";
import { getAddressDetail } from "../utils/common/city/postal_code";

export const useGetAllCitiesWithDetail = (defaultCityId) => {
  const getAllCitiesData = useQuery("getAllCitiesData", getAllCities, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const cityOptions = useMemo(() => {
    const options = getAllCitiesData.data?.map((city) => ({
      label: city?.city,
      value: city?.id,
      city: city?.city,
      province: city?.Province?.province,
      country: city?.Province?.Country?.country,
      provinceId: city?.Province?.id,
    }));
    return options;
  }, [getAllCitiesData]);
  let defaultCityOption = {};
  getAllCitiesData.data?.map((city) => {
    if (defaultCityId && +city?.id === +defaultCityId) {
      Object.assign(defaultCityOption, {
        label: city?.city,
        value: city?.id,
        city: city?.city,
        province: city?.Province?.province,
        country: city?.Province?.Country?.country,
        provinceId: city?.Province?.id,
      });
    }
    return {};
  });
  return {
    data: getAllCitiesData.data,
    options: cityOptions,
    loading: getAllCitiesData.isLoading,
    defaultCityOption,
  };
};
export const useGetAllCitiesWithDetailV2 = (defaultCityId) => {
  const getAllCitiesDataV2 = useQuery("getAllCitiesDataV2", getAllCitiesV2, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const cityOptions = useMemo(() => {
    const options = getAllCitiesDataV2.data?.map((city) => ({
      label: city?.city,
      value: city?.id,
      city: city?.city,
      province: city?.Province?.province,
      country: city?.Province?.Country?.country,
      provinceId: city?.Province?.id,
    }));
    return options;
  }, [getAllCitiesDataV2]);
  let defaultCityOption = {};
  getAllCitiesDataV2.data?.map((city) => {
    if (defaultCityId && +city?.id === +defaultCityId) {
      Object.assign(defaultCityOption, {
        label: city?.city,
        value: city?.id,
        city: city?.city,
        province: city?.Province?.province,
        country: city?.Province?.Country?.country,
        provinceId: city?.Province?.id,
      });
    }
    return {};
  });
  return {
    data: getAllCitiesDataV2.data,
    options: cityOptions,
    loading: getAllCitiesDataV2.isLoading,
    defaultCityOption,
  };
};

export const useGetAllProvincesWithDetail = (defaultCityId) => {
  const getAllProvincesData = useQuery("getAllProvincesData", getAllProvinces, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const provinceOptions = useMemo(() => {
    const options = getAllProvincesData.data?.map((province) => ({
      label: province?.province,
      value: province?.id,
      province: province?.province,
      country_id: province?.frk_country,
    }));
    return options;
  }, [getAllProvincesData]);

  let defaultProvinceOption = {};
  getAllProvincesData.data?.map((province) => {
    if (defaultCityId && +province?.id === +defaultCityId) {
      Object.assign(defaultProvinceOption, {
        label: province?.province,
        value: province?.id,
        province: province?.province,
        country_id: province?.frk_country,
      });
    }
    return {};
  });

  return {
    data: getAllProvincesData.data,
    options: provinceOptions,
    loading: getAllProvincesData.isLoading,
    defaultProvinceOption: defaultProvinceOption,
  };
};

export const useGetAddressWithPostalCode = ({
  successFunction,
  errorFunction,
}) => {
  const { options } = useGetAllCitiesWithDetail();
  const queryClient = useQueryClient();
  const getAddressDetailData = useMutation(
    "getAddressDetailData",
    getAddressDetail,
    {
      onSuccess: (data) => {
        const city = data?.data?.address?.city
          ?.toString()
          ?.toLowerCase()
          ?.replace(/\./g, "")
          ?.replace(/\s/g, "");
        const cityOption = options.find(
          (option) =>
            option?.city
              ?.toString()
              .toLowerCase()
              .replace(/\./g, "")
              .replace(/\s/g, "") === city
        );
        if (cityOption && cityOption !== null) {
          return successFunction({
            option: cityOption,
            restOfData: data?.data?.address,
            positionData: data?.data?.position,
          });
        } else {
          const newOption = {
            label: city?.city,
            // +
            // "," +
            // city?.Province?.province +
            // "," +
            // city?.Province?.Country?.country,
            value: city?.id,
            city: city?.city,
            province: city?.Province?.province,
            country: city?.Province?.Country?.country,
            provinceId: data?.cityData?.city?.Province?.id,
          };
          queryClient.setQueryData("getAllCitiesData", (oldData) => {
            return [...oldData, data?.cityData];
          });
          return successFunction({
            option: newOption,
            restOfData: data?.data?.address,
            positionData: data?.data?.position,
          });
        }
      },
      onError: errorFunction,
    }
  );
  const query = (zipCode) => {
    getAddressDetailData.mutate({ zipCode });
  };
  return { query, queryIsLoading: getAddressDetailData.isLoading };
};
