import React, { useState, useContext, createContext } from "react";

export const ContractState = createContext();
export const ContractDispatch = createContext();

const ContractProvider = ({ children }) => {
  const [state, dispatch] = useState("");
  return (
    <ContractState.Provider value={state}>
      <ContractDispatch.Provider value={dispatch}>
        {children}
      </ContractDispatch.Provider>
    </ContractState.Provider>
  );
};
export default ContractProvider;

export const useContract = () => useContext(ContractState);
export const useContractDispatch = () => {
  const dispatch = useContext(ContractDispatch);
  const setState = (data) => {
    return dispatch(data);
  };
  return setState;
};
