import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useGetYears } from "../../../../hooks/useGetYears";
import {
  reactSelectColorStyle,
  reactSelectStyle,
} from "../../../../constant/react_select_style";
import { useMutation, useQuery } from "react-query";
import {
  getBodyStyle,
  getColors,
  getVehicleTypes,
} from "../../../../utils/vehicle/vehicle";
import { getSingleVehicleForFile } from "../../../../utils/vehicle/vehicle_manage";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import NumberFormat from "react-number-format";

const VehicleDescription = ({ formik, vehicleData }) => {
  const { id } = useParams();
  const yearOptions = useGetYears();
  const isInitialMount1 = useRef(true);
  const [bodyStylesOptions, setbodyStylesOptions] = useState([]);
  const [type, setType] = useState();
  const getSingleVehicleInfoInv = useQuery(
    ["getSingleVehicleInfoInv", id],
    () => getSingleVehicleForFile(id),
    {
      refetchOnMount: false,
    }
  );

  const getBodyStyleData = useMutation(
    ["getBodyStyleData", type],
    () => getBodyStyle({ vehicleType: type?.value }),
    {
      onSuccess: (data) => {
        setbodyStylesOptions(
          data?.map((option) => ({
            label: option?.name,
            value: option?.id,
          }))
        );
      },
    }
  );
  const getVehicleTypesQuery = useQuery(["getVehicleTypesQuery"], () =>
    getVehicleTypes()
  );
  const getColorsData = useQuery("getColorsData", () => getColors());
  const handleYear = (e) => {
    if (e !== null) {
      formik.setFieldValue("model_year", e);
    }
  };
  const handleBodyStyle = (e) => {
    if (e !== null) {
      formik.setFieldValue("frk_bodyStyle", e);
    }
  };
  const handleExteriorColor = (e) => {
    if (e !== null) {
      formik.setFieldValue("frk_exterior_color", e);
    }
  };
  const colorOptions = getColorsData.data?.map((option) => ({
    label: option?.name,
    colorObject: option,
    value: option?.id,
  }));
  const defaultYear = yearOptions?.filter(
    (option) =>
      option?.value === +vehicleData?.midVehicleDealership?.Vehicle?.model_year
  );
  const defaultBodyStyle = bodyStylesOptions?.filter(
    (option) =>
      option?.value ===
      +vehicleData?.midVehicleDealership?.Vehicle?.frk_bodyStyle
  );
  const defaultExteriorColor = colorOptions?.filter(
    (option) =>
      option?.value ===
      +vehicleData?.midVehicleDealership?.Vehicle?.frk_exterior_color
  );
  useEffect(() => {
    const vehicleType = getVehicleTypesQuery?.data?.map((vehicleType) => {
      return {
        label: vehicleType?.type,
        value: vehicleType?.id,
      };
    });
    const typeLable = vehicleType?.filter(
      (option) =>
        option?.value ===
        +getSingleVehicleInfoInv?.data?.midVehicleDealership?.vehicleType
    );
    setType(typeLable && typeLable[0]);
  }, [
    getSingleVehicleInfoInv?.data?.midVehicleDealership?.vehicleType,
    getVehicleTypesQuery?.data,
  ]);
  useEffect(() => {
    if (type?.value) {
      if (isInitialMount1.current) {
        getBodyStyleData.mutate({ vehicleType: type?.value });
      } else {
        isInitialMount1.current = false;
      }
    }
  }, [type?.value]);
  useEffect(() => {
    setbodyStylesOptions(
      getBodyStyleData.data?.map((option) => ({
        label: option?.name,
        value: option?.id,
      }))
    );
  }, [getBodyStyleData?.data]);

  useEffect(() => {
    formik.setFieldValue(
      "make",
      vehicleData?.midVehicleDealership?.Vehicle?.make
    );
    formik.setFieldValue(
      "model",
      vehicleData?.midVehicleDealership?.Vehicle?.model
    );
    formik.setFieldValue("model_year", defaultYear ? defaultYear[0] : null);
    formik.setFieldValue(
      "frk_bodyStyle",
      defaultBodyStyle ? defaultBodyStyle[0] : null
    );
    formik.setFieldValue(
      "frk_exterior_color",
      defaultExteriorColor ? defaultExteriorColor[0] : null
    );
    formik.setFieldValue(
      "stock_NO",
      vehicleData?.midVehicleDealership?.stock_NO
    );
    formik.setFieldValue(
      "vin_number",
      vehicleData?.midVehicleDealership?.Vehicle?.vin_number
    );
    formik.setFieldValue(
      "fuel_type",
      vehicleData?.midVehicleDealership?.Vehicle?.fuel_type
    );
    formik.setFieldValue(
      "odometer",
      vehicleData?.midVehicleDealership?.odometer
    );
    formik.setFieldValue(
      "odometer_type",
      vehicleData?.midVehicleDealership?.odometer_type
    );
    // }
  }, [bodyStylesOptions]);
  return (
    <div className="w-100 row p-0 m-0">
      <div className="p-0 m-0 w-100 py-2 d-flex justify-content-between align-items-center">
        <h2 className="p-0 m-0 mx-2">DESCRIPTION OF Vehicle</h2>
      </div>
      <div className="p-0 m-0 w-100 row p-2">
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Year</label>
          <Select
            styles={reactSelectStyle}
            placeholder="Year"
            id="model_year"
            name="model_year"
            value={formik.values.model_year}
            options={yearOptions}
            onBlur={formik.handleBlur}
            className="mt-1 w-100"
            onChange={handleYear}
          />
          {formik.errors.model_year && (
            <div className="text-danger">{formik.errors.model_year}</div>
          )}
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Make</label>
          <input
            id="make"
            name="make"
            placeholder="Make"
            type="text"
            className="form-control rounded rounded-pill form_input-style mt-1 "
            value={formik.values.make}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.make && formik.touched.make && (
            <div className="text-danger">{formik.errors.make}</div>
          )}
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Model</label>
          <input
            id="model"
            name="model"
            placeholder="Model"
            type="text"
            className="form-control rounded rounded-pill form_input-style mt-1 "
            value={formik.values.model}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.model && formik.touched.model && (
            <div className="text-danger">{formik.errors.model}</div>
          )}
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Body style</label>

          <Select
            styles={reactSelectStyle}
            placeholder="Body style"
            id="frk_bodyStyle"
            name="frk_bodyStyle"
            value={formik.values.frk_bodyStyle}
            options={bodyStylesOptions}
            onBlur={formik.handleBlur}
            className="mt-1 w-100"
            onChange={handleBodyStyle}
          />
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label className="mx-2">
            {type?.value !== 3 && "Exterior "}Color
          </label>
          <Select
            styles={reactSelectColorStyle}
            placeholder={`${type?.value !== 3 ? "Exterior " : ""}Color`}
            id="frk_exterior_color"
            name="frk_exterior_color"
            value={formik.values.frk_exterior_color}
            options={colorOptions}
            onBlur={formik.handleBlur}
            onChange={handleExteriorColor}
          />
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Stock NO. </label>
          <input
            id="stock_NO"
            name="stock_NO"
            placeholder="stock_NO"
            type="text"
            className="form-control rounded rounded-pill form_input-style mt-1 "
            value={formik.values.stock_NO}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Vin</label>
          <input
            id="vin_number"
            name="vin_number"
            placeholder="Vin"
            type="text"
            className="form-control rounded rounded-pill form_input-style mt-1 "
            value={formik?.values?.vin_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // readOnly={userInfo === 410 ? false : true}
          />
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Fuel type</label>
          <select
            id="fuel_type"
            name="fuel_type"
            placeholder="Fuel type"
            // type="text"
            className="form-select rounded rounded-pill form_input-style mt-1 "
            value={formik.values.fuel_type}
            //   value={formik.values.vin_number}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <option value="">Choose...</option>
            <option value="Diesel">Diesel</option>
            <option value="Electric">Electric</option>
            <option value="Gasoline">Gasoline</option>
            <option value="Flex">Flex</option>
            <option value="Hybrid">Hybrid</option>
            <option value="Petrol">Petrol</option>
            <option value="Plug-in-Hybrid">Plug-in-Hybrid</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group p-0 m-0 col-12 col-md-6 col-lg-3 p-2">
          <label>Odometer</label>

          <NumberFormat
            value={formik.values.odometer === 0 ? "" : formik.values.odometer}
            thousandSeparator={true}
            id="odometer"
            name="odometer"
            suffix={` ${formik.values.odometer_type === 1 ? "MI" : "KM"}`}
            className="form-control rounded rounded-pill form_input-style mt-1 "
            inputMode="numeric"
            placeholder="Odometer"
            onValueChange={(e) => {
              formik.setFieldValue("odometer", e.value === "" ? 0 : e.value);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleDescription;
