import { createContext, useReducer } from "react";
import QuickBooksReducer from "./quickBooksReducer";

const initialValues = { step: 0, linkedIncomes: {}, linkedCosts: {} };

export const quickbooksContext = createContext(initialValues);

const QuickBooksContext = ({ children }) => {
  const [data, dispatch] = useReducer(QuickBooksReducer, initialValues);
  return (
    <quickbooksContext.Provider value={{ ...data, dispatch }}>
      {children}
    </quickbooksContext.Provider>
  );
};
export default QuickBooksContext;
