import { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const FilterCustomerResualt = (props) => {
  const {
    item,
    phone,
    email,
    lName,
    key,
    name,
    address,
    postalCode,
    toggleExpanded,
    setAddId,
    keys
  } = props;

  return (
    <div key={keys} className="search_back p-0 m-0">
      <div key={key} className="w-100 py-1 pt-3 px-md-3 px-0 m-0 my-1  ">
        <div className="p-0 m-0 p-2 row justify-content-between align-items-center ">
          <div className="p-0 m-0 d-none d-lg-flex">
            <b
              className="p-0 m-0 pr-2"
              dangerouslySetInnerHTML={{
                __html: item?.f_name.replace(
                  new RegExp(name, "gi"),
                  (match) => `<span class="filterCustomerName">${match}</span>`
                ),
              }}
            ></b>
            <b
              dangerouslySetInnerHTML={{
                __html: item?.l_name.replace(
                  new RegExp(lName, "gi"),
                  (match) => `<span class="filterCustomerName">${match}</span>`
                ),
              }}
              style={{ paddingLeft: "24px" }}
            ></b>
          </div>
          <b
            className="p-0 m-0  d-flex flex-wrap d-lg-none"
            dangerouslySetInnerHTML={{
              __html: item?.f_name.replace(
                new RegExp(name, "gi"),
                (match) => `<span class="filterCustomerName">${match}</span>`
              ),
            }}
          ></b>
          <b
            className="p-0 m-0 d-flex flex-wrap d-lg-none"
            dangerouslySetInnerHTML={{
              __html: item?.l_name.replace(
                new RegExp(lName, "gi"),
                (match) => `<span class="filterCustomerName">${match}</span>`
              ),
            }}
          ></b>
          <div className="p-0 m-0 d-none d-xl-flex pt-2">
            <span
              className="p-0 m-0"
              style={{ fontSize: "14px" }}
              dangerouslySetInnerHTML={{
                __html: item?.mobile.replace(
                  new RegExp(phone, "gi"),
                  (match) => `<span class="filterCustomerName">${match}</span>`
                ),
              }}
            ></span>
            <span
              style={{ fontSize: "14px", paddingLeft: "24px" }}
              dangerouslySetInnerHTML={{
                __html: item?.email.replace(
                  new RegExp(email, "gi"),
                  (match) => `<span class="filterCustomerName">${match}</span>`
                ),
              }}
            ></span>
          </div>
          <span
            className="p-0 m-0 d-flex  d-xl-none"
            style={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{
              __html: item?.mobile.replace(
                new RegExp(phone, "gi"),
                (match) => `<span class="filterCustomerName">${match}</span>`
              ),
            }}
          ></span>
          <span
            className="d-flex flex-wrap d-xl-none p-0 m-0"
            style={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{
              __html: item?.email.replace(
                new RegExp(email, "gi"),
                (match) => `<span class="filterCustomerName">${match}</span>`
              ),
            }}
          ></span>

          {item?.address === "null" ? (
            " "
          ) : (
            <span
              className="p-0 m-0"
              style={{ fontSize: "14px" }}
              dangerouslySetInnerHTML={{
                __html: item?.address.replace(
                  new RegExp(address, "gi"),
                  (match) => `<span class="filterCustomerName">${match}</span>`
                ),
              }}
            ></span>
          )}
          <span
            className="p-0 m-0"
            style={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{
              __html: item?.postalcode.replace(
                new RegExp(postalCode, "gi"),
                (match) => `<span class="filterCustomerName">${match}</span>`
              ),
            }}
          ></span>

          {/* <FaAngleRight /> */}
        </div>
        <div className="px-2 px-md-0 py-0 m-0 d-flex justify-content-end">
          <div
            // href={`/admin/edit-customer/${item?.id}`}
            onClick={() => {
              toggleExpanded();
              setAddId(item?.id);
            }}
            className="btn table_page_botton_2 my-1 shadow"
            style={{ fontSize: "14px" }}
          >
            {" "}
            View
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterCustomerResualt;
