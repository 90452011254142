import { Cookies } from "react-cookie";
import {
  ApiCallGetMethod,
  ApiCallUploadMethodJson,
  BASE_URL,
} from "../../constant/base";
const handleChangeRoleSubmit = async (roleId, setLoading) => {
  setLoading(true);
  const cook = new Cookies();
  const _res = await fetch(`${BASE_URL}api/change/role/${roleId}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${cook.get("token")}`,
    },
    body: JSON.stringify({}),
  });
  return _res;
};
export const handleChangeUserRole = async (
  roleId,
  userAuthSetter,
  history,
  setLoading
) => {
  try {
    const changedRoleResponse = await handleChangeRoleSubmit(
      roleId,
      setLoading
    );
    if (changedRoleResponse.status === 200) {
      const _res = await ApiCallGetMethod("api/user/info");
      userAuthSetter({ isLoggedIn: true, ..._res });
      localStorage.setItem(
        "isLoggedIn",
        JSON.stringify({
          isLoggedIn: true,
          components: _res?.components,
          ds_status: _res?.ds_status,
          user: { frk_role: _res?.user?.frk_role },
        })
      );
      setLoading(false);
      history.replace("/admin");
    } else {
      throw new Error("error happend when role changed");
    }
  } catch (e) {
    throw new Error(e);
  }
};

//branching//
export const updateBranchingUser = (id) => {
  return ApiCallUploadMethodJson(`user/switch/branch/${id}`, "PATCH");
};

//logout//
export const apiLogout = async () => {
  return ApiCallUploadMethodJson(
    `api/user/logout`,
    "POST",
    {},
    false
  );
};

//apiOffline//
export const apiOffline = async () => {
  return ApiCallUploadMethodJson(
    `api/user/offline`,
    "POST", undefined, undefined, undefined, true
  );
};