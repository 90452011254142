import { Cookies } from "react-cookie";

const cook = new Cookies();
export const logout = (history, authSetter, auth) => {
  const logoutPromis = new Promise((resolve, reject) => {
    cook.remove("token");

    localStorage.setItem("setPage", 0);
    localStorage.setItem(
      "setPageSize",
      auth?.dealershipInfo?.id === 329
        ? 50
        : auth?.dealershipInfo?.id === 458
        ? 25
        : 10
    );
    // cook.remove("refToken");
    authSetter({
      user: null,
      components: null,
      ds_status: 0,
      isLoggedIn: false,
    });
    localStorage.setItem(
      "isLoggedIn",
      JSON.stringify({
        isLoggedIn: false,
        components: null,
        ds_status: null,
        user: { frk_role: null },
      })
    );
  });
  logoutPromis.then(() => {
    history.replace("/signin");
  });
};
