import { useFormik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { handleSubmit } from "../../../utils/forget-password/forget_password";
import SimpleLoading from "../../common/loading/simple_loading";
const ForgetPassword = (props) => {
  const { show, handleClose } = props;
  const postForgetPasswordQuery = useMutation(
    "postForgetPasswordQuery",
    handleSubmit,
    {
      onSuccess: () => {
        handleClose();
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("E-mail is required")
        .email("E-mail format is invalid"),
      username: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      postForgetPasswordQuery.mutate(values);
    },
  });
  return (
    <Modal onHide={handleClose} show={show}>
      <Modal.Header className="vehicle_modal_header-style border-0">
        <Modal.Title>Forget your Password?</Modal.Title>
        <button
          className="btn-close"
          onClick={() => {
            handleClose();
          }}
        ></button>
      </Modal.Header>
      <div className="d-flex flex-column bg-white p-4 pt-0  rounded">
        <img
          src="/images/forget-password.png"
          alt=""
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group mt-3">
            <label>Username</label>
            <input
              type="text"
              name="username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control rounded rounded-pill form_input-style mt-1"
              id="forget-password-email"
              placeholder="Username"
            />
            {formik.errors.username && formik.touched.username && (
              <p className="text-danger">{formik.errors.username}</p>
            )}
          </div>
          <div className="form-group mt-1">
            <label>E-mail</label>
            <input
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control rounded rounded-pill form_input-style mt-1"
              id="forget-password-email"
              placeholder="E-mail"
            />
            {formik.errors.email && formik.touched.email && (
              <p className="text-danger">{formik.errors.email}</p>
            )}
          </div>
          <div className="mt-4 mb-4 d-flex justify-content-end">
            {postForgetPasswordQuery.isLoading ? (
              <SimpleLoading />
            ) : (
              <button type="submit" className="btn form_submit-btn px-5 shadow">
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default React.memo(ForgetPassword);
