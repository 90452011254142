import { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { SocketContext } from "../../../context/socket";


const ScrollToTop = () => {
  const { pathname } = useLocation();
  const history = useHistory()
  const { setHistory } = useContext(SocketContext)
  useEffect(() => {

    setHistory(history)
    console.log("scroloed top")

  }, [])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
export default ScrollToTop;
