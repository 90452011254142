import { createContext, useState } from "react";
import { useMutation } from "react-query";
import {
  getVehicleCaching,
  getVehicleManageV3,
} from "../utils/vehicle/vehicle_manage";
import { createHash } from "crypto";


export const AllCarsDealState = createContext();
export const AllCarsDealDispatch = createContext();

const DealInventoryVehicle = ({ children }) => {
  let [allCarsDeal, setAllCarsDeal] = useState();
  let [caching, setcaching] = useState();
  let [vehiclesCach, setvehiclesCach] = useState();

  const getVehiclesCach = useMutation("getVehicleCaching", getVehicleCaching, {
    onSuccess: (data) => {
      setvehiclesCach(data.hash);
    },
  });
  const getVehicles = useMutation("getVehicles", getVehicleManageV3, {
    onSuccess: (data, variable) => {
      setAllCarsDeal(data);
      setcaching(
        createHash("sha256")
          .update(JSON.stringify(data?.vehicles))
          .digest("hex")
      );
    },
  });
  const checkUserVehicles = () => {
    getVehicles.mutate();
  };
  const checkVehiclesCaching = () => {
    getVehiclesCach.mutate();
  };

  return (
    <AllCarsDealState.Provider
      value={{
        setAllCarsDeal,
        allCarsDeal,
        checkUserVehicles,
        getVehicles,
        checkVehiclesCaching,
        vehiclesCach,
        caching,
      }}
    >
      {children}
    </AllCarsDealState.Provider>
  );
};
export default DealInventoryVehicle;
