const HillzLogoIcon = ({ className, style }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="28.000000pt"
      height="28.000000pt"
      viewBox="0 0 28.000000 28.000000"
      preserveAspectRatio="xMidYMid meet"
      className="sidebar_svg_icons"
    >
      <g
        transform="translate(0.000000,28.000000) scale(0.005600,-0.005600)"
        // fill="#000000"
        stroke="none"
      >
        <path d="M2450 2500 l0 -1650 65 0 65 0 0 1650 0 1650 -65 0 -65 0 0 -1650z" />
        <path
          d="M2734 4126 c-2 -6 -3 -229 -2 -496 l3 -485 243 -3 242 -2 0 145 c0
    80 3 145 8 145 4 0 63 -55 130 -123 l123 -123 -298 -280 c-164 -153 -327 -306
    -362 -339 -35 -33 -70 -74 -77 -92 -12 -26 -14 -178 -14 -828 l0 -795 63 0
    c118 0 341 72 512 165 329 179 619 501 752 835 49 123 93 287 93 348 l0 42
    -230 0 c-269 0 -234 18 -303 -160 -58 -150 -104 -240 -161 -317 -54 -72 -212
    -223 -233 -223 -12 0 -13 56 -11 353 3 332 4 354 23 384 24 38 393 394 643
    620 150 136 182 170 182 192 0 33 -60 160 -130 271 -249 403 -648 674 -1120
    764 -52 9 -73 10 -76 2z"
        />
        <path
          d="M2165 4114 c-130 -28 -314 -94 -335 -122 -19 -24 -20 -43 -20 -649
    l0 -623 -237 2 -238 3 -5 465 -5 464 -69 -79 c-388 -449 -505 -1072 -311
    -1652 68 -203 188 -404 321 -538 l59 -60 5 455 5 455 238 3 237 2 0 -617 c0
    -475 3 -624 13 -643 10 -20 39 -33 177 -77 123 -39 181 -52 228 -53 l62 0 0
    1640 0 1640 -27 -1 c-16 0 -59 -7 -98 -15z"
        />
      </g>
    </svg>
  );
};

export default HillzLogoIcon;
