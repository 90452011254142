import React, { useState } from "react";

export const DrawerContextState = React.createContext();
export const DrawerContextSetter = React.createContext();

const DrawerContextProvider = ({ children }) => {
  const [drawer, setDrawer] = useState(false);
  return (
    <DrawerContextState.Provider value={drawer}>
      <DrawerContextSetter.Provider value={setDrawer}>
        {children}
      </DrawerContextSetter.Provider>
    </DrawerContextState.Provider>
  );
};

export default DrawerContextProvider;
