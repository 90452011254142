import { useState, createContext } from "react";

export const LeadState = createContext();
export const LeadDispatch = createContext();

const LeadContext = ({ children }) => {
  const [leadData, setLeadData] = useState(null);
  return (
    <LeadState.Provider value={leadData}>
      <LeadDispatch.Provider value={setLeadData}>
        {children}
      </LeadDispatch.Provider>
    </LeadState.Provider>
  );
};
export default LeadContext;

