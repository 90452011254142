import { useFormik } from "formik";
import { createContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { postPhysicalLead } from "../utils/lead-center/lead_center";
// export const RealTestDriveState = createContext();
// export const RealTestDriveSetter = createContext();
export const PhysicalLeadConfirmState = createContext();
export const PhysicalLeadConfirmSetter = createContext();
export const PhysicalLeadStepState = createContext();
export const PhysicalLeadStepSetter = createContext();
export const PhysicalLeadStepCheckState = createContext();
export const PhysicalLeadStepCheckSetter = createContext();
export const PhysicalLeadFormik = createContext();
export const PhysicalLeadFormAddLoading = createContext();
const PhysicalLeadContaxtProvider = ({ children }) => {
  // const [customer, setCustomer] = useState("");
  const history = useHistory();
  const [confirmUserId, setConfirmUserId] = useState("");
  const [step, setStep] = useState({});
  const [stepCheck, setStepCheck] = useState();
  const queryCilent = useQueryClient();
  const postPhysicalLeadData = useMutation(
    "postPhysicalLeadData",
    postPhysicalLead,
    {
      onSuccess: () => {
        queryCilent.invalidateQueries("getRealTestDrive");
        history.goBack(1);
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      method_of_contact: 1,
      requested_date: "",
      how_it_made: 1,
      comment: "",
      user: confirmUserId,
    },
    validationSchema: Yup.object().shape({
      method_of_contact: Yup.string().required("Required"),
      how_it_made: Yup.string().required("Required"),
      requested_date: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const body = {
        method_of_contact: values?.method_of_contact,
        requested_date: values?.requested_date,
        how_it_made: values?.how_it_made,
        comment: values?.comment,
        frk_user_id: values.user?.id ? values.user?.id : confirmUserId,
      };
      postPhysicalLeadData.mutate(body);
    },
  });
  return (
    <PhysicalLeadFormik.Provider value={formik}>
      <PhysicalLeadStepState.Provider value={step}>
        <PhysicalLeadStepSetter.Provider value={setStep}>
          <PhysicalLeadStepCheckState.Provider value={stepCheck}>
            <PhysicalLeadStepCheckSetter.Provider value={setStepCheck}>
              <PhysicalLeadConfirmState.Provider value={confirmUserId}>
                <PhysicalLeadConfirmSetter.Provider value={setConfirmUserId}>
                  <PhysicalLeadFormAddLoading.Provider
                    value={postPhysicalLeadData.isLoading}
                  >
                    {children}
                  </PhysicalLeadFormAddLoading.Provider>
                </PhysicalLeadConfirmSetter.Provider>
              </PhysicalLeadConfirmState.Provider>
            </PhysicalLeadStepCheckSetter.Provider>
          </PhysicalLeadStepCheckState.Provider>
        </PhysicalLeadStepSetter.Provider>
      </PhysicalLeadStepState.Provider>
    </PhysicalLeadFormik.Provider>
  );
};

export default PhysicalLeadContaxtProvider;
