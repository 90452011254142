/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { BASE_URL } from "../constant/base";
import { Cookies } from "react-cookie";
import { checkRefreshToken } from "../utils/auth/checkRefreshToken";
import { AllCarsState } from "../context/inventory";
import { AllCarsDealState } from "../context/dealInventory";
import { useIsAuthState } from "./useAuth";
const cook = new Cookies();

const getAllNotification = async () => {
  const token = cook.get("token");
  const _res = await fetch(`${BASE_URL}api/dealership/notifications`, {
    credentials: "include",
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  if (_res.status === 401) {
    //set error message
    checkRefreshToken();
    throw new Error("Unauthorized");
  } else {
    const _resJson = await _res.json();
    return _resJson;
  }
};
const getSingleNotification = async (id) => {
  const token = cook.get("token");
  const _res = await fetch(
    `${BASE_URL}api/dealership/notification/single/${id}`,
    {
      credentials: "include",
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  if (_res.status === 401) {
    checkRefreshToken();
    throw new Error("Unauthorized");
  } else {
    const _resJson = await _res.json();
    return _resJson;
  }
};
export const useGetAllNotificaions = () => {
  const queryClient = useQueryClient();
  const useAllCarsDealState = useContext(AllCarsDealState);
  const useAllCarsState = useContext(AllCarsState);
  const userInfo = useIsAuthState();

  // const { isSuccess, isError, data, isFetching, isLoading, status } = useQuery(
  //   "notifications",
  //   getAllNotification,
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: true,
  //     initialData: queryClient.getQueryData("notifications"),
  //     onSuccess: (fetchedData) => {
  //       if (fetchedData?.notifications) {
  //         fetchedData.notifications.forEach((notif) =>
  //           queryClient.setQueryData(["notifications", notif.id], notif, {
  //             defaultOptions: {
  //               queries: {
  //                 refetchOnWindowFocus: false,
  //                 refetchOnMount: false,
  //               },
  //             },
  //           })
  //         );
  //       }

  //       // console.log(">>>>>>>>>>>", window.location);
  //       if (window.location.hash === `#/admin`) {
  //         if (
  //           useAllCarsDealState.caching === undefined ||
  //           useAllCarsDealState.caching !== useAllCarsDealState.vehiclesCach
  //         ) {
  //         }
  //         useAllCarsDealState.checkVehiclesCaching();
  //         useAllCarsState.checkUserDealVehicles();
  //       }
  //     },
  //   }
  // );
  const getPopUpNotificationQuery = useMutation(
    "notifications",
    getAllNotification,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      initialData: queryClient.getQueryData("notifications"),
      onSuccess: (fetchedData) => {
        if (fetchedData?.notifications) {
          fetchedData.notifications.forEach((notif) =>
            queryClient.setQueryData(["notifications", notif.id], notif, {
              defaultOptions: {
                queries: {
                  refetchOnWindowFocus: false,
                  refetchOnMount: false,
                },
              },
            })
          );
        }

        // console.log(">>>>>>>>>>>", window.location);
        if (window.location.hash === `#/admin`) {
          if (
            useAllCarsDealState.caching === undefined ||
            useAllCarsDealState.caching !== useAllCarsDealState.vehiclesCach
          ) {
            // useAllCarsDealState.checkUserVehicles();
          }
          useAllCarsDealState.checkVehiclesCaching();
          useAllCarsState.checkUserDealVehicles();
        }
      },
    }
  );
  useEffect(() => {
    if (userInfo.isLoggedIn) {
      getPopUpNotificationQuery.mutate();
    }
  }, [userInfo]);
  return {
    isSuccess: getPopUpNotificationQuery?.isSuccess,
    isError: getPopUpNotificationQuery?.isError,
    data: getPopUpNotificationQuery?.data,
    isFetching: getPopUpNotificationQuery?.isFetching,
    isLoading: getPopUpNotificationQuery?.isLoading,
  };
};

export const useGetSingleNotification = (id) => {
  const queryClient = useQueryClient();

  const { data, isError, isFetching, isLoading, isSuccess } = useQuery(
    ["notfication", id],
    () => getSingleNotification(id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      initialData: () => queryClient.getQueryData(["notifications", id]),
      onSuccess: () => {
        handleInvalidateNotfications(queryClient);
      },
    }
  );
  return { data, isError, isFetching, isLoading, isSuccess };
};

export const handleInvalidateNotfications = (queryClient) => {
  queryClient.invalidateQueries("notifications");
};
