import * as Yup from "yup";
import { TICKET_VALID_FILE_FORMAT } from "../base";

export const SIGNIN_VALIDATION_SCHEMA = Yup.object({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

export const PRICING_VALIDATOIN_SCHEMA = Yup.object({
  ssl_first_name: Yup.string()
    .required("Required")
    .matches(/^[A-Za-z ]+$/, "invalid name"),
  ssl_last_name: Yup.string()
    .required("Required")
    .matches(/^[A-Za-z ]+$/, "invalid last name"),
  month: Yup.string()
    .required("Required")
    .matches(/^(0?[1-9]|1[012])$/, "Month must be in rage of 1 to 12")
    .min(2, "should be 2 char")
    .max(2, "should be 2 char"),
  year: Yup.string()
    .required("Required")
    .matches(/^\d+$/, "must be numbers")
    .min(2, "should be 2 char")
    .max(2, "should be 2 char"),
  ssl_cvv2cvc2: Yup.string()
    .required("Required")
    .matches(/^\d+$/, "must be numbers")
    .min(3, "minimum 3 characters")
    .max(4, "maximum 4 characters"),
  number: Yup.string().required("Required").matches(/^\d+$/, "must be numbers"),
});

export const SELLER_ADD_CUSTOMER_VALIDATION = Yup.object({
  f_name: Yup.string().required("Required"),
  l_name: Yup.string().required("Required"),
  email: Yup.string().required().email("email format is invalid"),
  mobile: Yup.string().required("Required").matches(/^\d+$/, "must be numbers"),
});

export const OFFLINE_CHAT_VALIDATION = Yup.object({
  comment: Yup.string(),
  images: Yup.mixed()
    .test("images", "please use jpg jpeg png and pdf file", (value) => {
      let valid = true;
      if (value) {
        value.map((file) => {
          if (!TICKET_VALID_FILE_FORMAT.includes(file.type)) {
            valid = false;
          }
        });
      }
      return valid;
    })
    .test("fileSize", "File Size is too large", (value) => {
      let valid = true;
      if (value) {
        for (let i in value) {
          if (value[i].size <= 1 * 1024 * 1024) {
            return valid;
          } else {
            valid = false;
          }
        }
      }
      return valid;
    }),
});
