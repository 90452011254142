import { useEffect, useState } from "react";

import { FaAngleRight } from "react-icons/fa";
const SearchCity = (props) => {
  const {
    item,
    simplesearches,
    setsimplesearchname,
    key,
    formik,
    setCounrayName,
    setProvinceName,
    setSelectValue,
    setdisplaysearch,
    setvalue
  } = props;
  return (
    <div
    onClick={(e) => {
        setProvinceName({ label: item?.province, value: item?.province });
        // setCounrayName(item?.country);
        setSelectValue(item?.value);
        setsimplesearchname(e.target.innerText.split(",")[0] );
        setdisplaysearch(false);
        setvalue("province", item?.province);
        setvalue("country", item?.country);
        setvalue("city", item.value);
      }}
      key={key}
      className="p-0 m-0 py-1 d-flex rounded justify-content-between align-items-center"
    >
      <div>
        <span>{item?.value}</span>
        <span style={{ fontSize: "12px", color: "#858585" }}>
          {" "}
          ,{item?.abbreviation} {/* {city?.province} */}
        </span>
      </div>
    </div>
  );
};

export default SearchCity;
