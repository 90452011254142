export const useGetYears = () => {
  let currentYear = new Date().getFullYear() + 1;
  const yearsArray = Array(100)
    .fill(0)
    .map(() => {
      const value = currentYear--;
      return {
        label: value,
        value: value,
      };
    });
  return yearsArray;
};
