import { Cookies } from "react-cookie";
const cook = new Cookies();
const get = () => cook.get("token");
export const decode = (payload) => {
  return JSON.parse(atob(payload));
};
export const getPayload = (token) => {
  if (token) {
    const payload = token.split(".")[1];
    return decode(payload);
  } else {
    return false;
  }
};
export const isValidToken = () => {
  const token = get();
  if (!token) return false;
  if (token) {
    const payload = getPayload(token);
    const date = getTokenExpirationDate(payload);
    return new Date().valueOf() < date.valueOf();
  } else {
    return true;
  }
};
export const getUserInfo = () => {
  const token = get();
  if (!token) return [];
  const payload = token;
  return decode(payload);
};
const getTokenExpirationDate = (payload) => {
  if ("exp" in payload) {
    const date = new Date(0);
    date.setUTCSeconds(payload["exp"]);
    return date;
  } else {
    return null;
  }
};
