import { NavLink } from "react-router-dom";
import { FaHandshake, FaStar, FaFileInvoiceDollar } from "react-icons/fa";
import { useDrawerSetter, useDrawerState } from "../../hooks/useDrawer";

const CustomerDrawer = () => {
  const drawer = useDrawerState();
  const setDrawerState = useDrawerSetter();
  return (
    <div
      className={`d-flex flex-column justify-content-start align-items-start sidenav-container ${
        drawer ? "sidenavOpen" : "sidenavClose"
      } `}
      onMouseOver={() => setDrawerState(true)}
      onMouseLeave={() => setDrawerState(false)}
    >
      <div className="p-2 m-0 mt-2 mb-5 sidebar_ul d-flex flex-column justify-content-center align-items-start">
        <NavLink
          to="/admin/customer-negotiates"
          className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
          activeClassName="sidebar_navlink"
          onClick={() => {
            setDrawerState(false);
          }}
        >
          {/* <i className="sidebar_icons d-flex align-items-center justify-content-center"> */}

          <FaHandshake className="sidebar_icons" />

          {/* </i> */}
          <p
            className={`${drawer ? "" : "d-none"} p-0 m-0`}
            style={{ fontSize: "1.1rem" }}
          >
            DEAL
          </p>
        </NavLink>
        <NavLink
          to="/customer/favorite-vehicles"
          className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
          activeClassName="sidebar_navlink"
          onClick={() => {
            setDrawerState(false);
          }}
        >
          {/* <i className="sidebar_icons d-flex align-items-center justify-content-center"> */}

          <FaStar className="sidebar_icons" />

          {/* </i> */}
          <p
            className={`${drawer ? "" : "d-none"} p-0 m-0`}
            style={{ fontSize: "1.1rem" }}
          >
            FAVORITES
          </p>
        </NavLink>
        {/* <NavLink
          to="/customer/vehicle/deposite"
          className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
          activeClassName="sidebar_navlink"
          onClick={() => {
            setDrawerState(false);
          }}
        >

          <FaFileInvoiceDollar className="sidebar_icons" />

          <p
            className={`${drawer ? "" : "d-none"} p-0 m-0`}
            style={{ fontSize: "1.1rem" }}
          >
            DEPOSITE
          </p>
        </NavLink> */}
      </div>
    </div>
  );
};

export default CustomerDrawer;
