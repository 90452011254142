/* eslint-disable array-callback-return */
import { useMutation, useQuery } from "react-query";
import {
  ApiCallGetMethod,
  ApiCallUploadMethodJson,
} from "../../constant/base";

const getAllCars = async () => {
  const _res = await ApiCallGetMethod(
    "api/mid/negotiate/vehicle/get/advance/search"
  );
  return _res;
};

export const useGetAllCars = () => {
  return useQuery("all-cars", getAllCars, { refetchOnWindowFocus: false });
};
export const getAllSellers = async () => {
  const _res = await ApiCallGetMethod("api/dealership/sellers");
  return _res;
};
export const useGetAllDealerSeller = () => {
  return useQuery("all-seller", getAllSellers);
};
export const useGetAllDealershipUsersCars = () => {
  const allCars = getAllCarsSold();

  return { allCars };
};

export const getFiltredData = async ({
  values,
  isDealership,
  isSellerFilterSlectede,
}) => {
  if (isDealership) {
    let negotiate = {};
    let mid_negotiate_vehicle = {};
    let mid_vehicle_dealership = {};
    let vehicle = {};
    let user = {};
    let seller = {};
    let calc_data = {};
    const notEmptyInput = Object.keys(values)
      .filter((input) => values[input] !== "")
      .map((input) => {
        if (
          input === "lead_type" ||
          input === "negotiate_status" ||
          input === "customer_type" ||
          input === "success_forcast" ||
          input === "dealership_comment"
        ) {
          return { negotiate: { [input]: values[input] } };
        }
        if (
          input === "dealership_opinion" ||
          input === "mid_success_forcast" ||
          input === "car_on_negotiate_status"
        ) {
          return {
            mid_negotiate_vehicle: {
              [input]: values[input],
            },
          };
        }
        if (
          input === "odometer" ||
          input === "odometer_type" ||
          input === "vehicle_condition" ||
          input === "is_it_special" ||
          input === "sell_price" ||
          input === "special_price" ||
          input === "vehicle_status"
        ) {
          return { mid_vehicle_dealership: { [input]: values[input] } };
        }
        if (
          input === "vin_number" ||
          input === "make" ||
          input === "model" ||
          input === "model_year" ||
          input === "body_style" ||
          input === "drive_type" ||
          input === "engine_cylinders" ||
          input === "doors" ||
          input === "engine_type" ||
          input === "horse_power" ||
          input === "engine_size" ||
          input === "trim" ||
          input === "fuel_type" ||
          input === "fuel_capacity" ||
          input === "high_msrp"
        ) {
          return { vehicle: { [input]: values[input] } };
        }
        if (
          input === "email" ||
          input === "mobile" ||
          input === "gender" ||
          input === "f_name" ||
          input === "l_name"
        ) {
          return { user: { [input]: values[input] } };
        }
        if (input === "id") {
          return { seller: { [input]: values[input] } };
        }
        if (
          input === "sale_price" ||
          input === "waranty" ||
          input === "financeAmount" ||
          input === "term" ||
          input === "profit" ||
          input === "payments"
        ) {
          return { calc_data: { [input]: values[input] } };
        }
      });
    notEmptyInput.forEach((item) => {
      if (item.negotiate) {
        for (let i in item.negotiate) {
          negotiate = { ...negotiate, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.mid_negotiate_vehicle) {
        for (let i in item.mid_negotiate_vehicle) {
          mid_negotiate_vehicle = { ...mid_negotiate_vehicle, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.mid_vehicle_dealership) {
        for (let i in item.mid_vehicle_dealership) {
          mid_vehicle_dealership = {
            ...mid_vehicle_dealership,
            [i]: values[i],
          };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.vehicle) {
        for (let i in item.vehicle) {
          vehicle = { ...vehicle, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.user) {
        for (let i in item.user) {
          user = { ...user, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.seller) {
        for (let i in item.seller) {
          seller = { ...seller, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.calc_data) {
        for (let i in item.calc_data) {
          calc_data = { ...calc_data, [i]: values[i] };
        }
      }
    });
    const body = {};
    const keyCollection = {
      negotiate: Object.entries(negotiate).length,
      mid_negotiate_vehicle: Object.entries(mid_negotiate_vehicle).length,
      mid_vehicle_dealership: Object.entries(mid_vehicle_dealership).length,
      vehicle: Object.entries(vehicle).length,
      user: Object.entries(user).length,
      calc_data: Object.entries(calc_data).length,
      seller: Object.entries(seller).length,
    };
    for (let i in keyCollection) {
      if (keyCollection[i] !== 0) {
        if (i === "seller") {
          body[i] = { ...seller };
        }
        if (i === "negotiate") {
          body[i] = { ...negotiate };
        }
        if (i === "mid_negotiate_vehicle") {
          body[i] = { ...mid_negotiate_vehicle };
        }
        if (i === "mid_vehicle_dealership") {
          body[i] = { ...mid_vehicle_dealership };
        }
        if (i === "vehicle") {
          body[i] = { ...vehicle };
        }
        if (i === "user") {
          body[i] = { ...user };
        }
        if (i === "calc_data") {
          body[i] = { ...calc_data };
        }
      }
    }
    // if (isSellerFilterSlectede) {
    //   const body = {
    //     negotiate,
    //     mid_negotiate_vehicle,
    //     mid_vehicle_dealership,
    //     vehicle,
    //     user,
    //     seller,
    //     calc_data,
    //   };
    //   const _res = await ApiCallUploadMethodJson(
    //     "api/mid/negotiate/vehicle/advance/search",
    //     "POST",
    //     body
    //   );
    //   return _res;
    // } else {
    // const body = {
    //   negotiate,
    //   mid_negotiate_vehicle,
    //   mid_vehicle_dealership,
    //   vehicle,
    //   user,
    // };
    const _res = await ApiCallUploadMethodJson(
      "api/mid/negotiate/vehicle/advance/search",
      "POST",
      body
    );
    return _res;
    // }
  } else {
    let negotiate = {};
    let mid_negotiate_vehicle = {};
    let mid_vehicle_dealership = {};
    let vehicle = {};
    let user = {};
    let calc_data = {};
    const notEmptyInput = Object.keys(values)
      .filter((input) => values[input] !== "")
      .map((input) => {
        if (
          input === "lead_type" ||
          input === "negotiate_status" ||
          input === "customer_type" ||
          input === "success_forcast" ||
          input === "dealership_comment"
        ) {
          return { negotiate: { [input]: values[input] } };
        }
        if (
          input === "dealership_opinion" ||
          input === "mid_success_forcast" ||
          input === "car_on_negotiate_status"
        ) {
          return {
            mid_negotiate_vehicle: {
              [input]: values[input],
            },
          };
        }
        if (
          input === "odometer" ||
          input === "odometer_type" ||
          input === "vehicle_condition" ||
          input === "is_it_special" ||
          input === "sell_price" ||
          input === "special_price" ||
          input === "vehicle_status"
        ) {
          return { mid_vehicle_dealership: { [input]: values[input] } };
        }
        if (
          input === "vin_number" ||
          input === "make" ||
          input === "model" ||
          input === "model_year" ||
          input === "body_style" ||
          input === "drive_type" ||
          input === "engine_cylinders" ||
          input === "doors" ||
          input === "engine_type" ||
          input === "horse_power" ||
          input === "engine_size" ||
          input === "trim" ||
          input === "fuel_type" ||
          input === "fuel_capacity" ||
          input === "high_msrp"
        ) {
          return { vehicle: { [input]: values[input] } };
        }
        if (
          input === "email" ||
          input === "mobile" ||
          input === "gender" ||
          input === "f_name" ||
          input === "l_name"
        ) {
          return { user: { [input]: values[input] } };
        }
        if (
          input === "sale_price" ||
          input === "waranty" ||
          input === "financeAmount" ||
          input === "term" ||
          input === "profit" ||
          input === "payments"
        ) {
          return { calc_data: { [input]: values[input] } };
        }
      });
    notEmptyInput.forEach((item) => {
      if (item.negotiate) {
        for (let i in item.negotiate) {
          negotiate = { ...negotiate, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.mid_negotiate_vehicle) {
        for (let i in item.mid_negotiate_vehicle) {
          mid_negotiate_vehicle = { ...mid_negotiate_vehicle, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.mid_vehicle_dealership) {
        for (let i in item.mid_vehicle_dealership) {
          mid_vehicle_dealership = {
            ...mid_vehicle_dealership,
            [i]: values[i],
          };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.vehicle) {
        for (let i in item.vehicle) {
          vehicle = { ...vehicle, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.user) {
        for (let i in item.user) {
          user = { ...user, [i]: values[i] };
        }
      }
    });
    notEmptyInput.forEach((item) => {
      if (item.calc_data) {
        for (let i in item.calc_data) {
          calc_data = { ...calc_data, [i]: values[i] };
        }
      }
    });
    const body = {};
    const keyCollection = {
      negotiate: Object.entries(negotiate).length,
      mid_negotiate_vehicle: Object.entries(mid_negotiate_vehicle).length,
      mid_vehicle_dealership: Object.entries(mid_vehicle_dealership).length,
      vehicle: Object.entries(vehicle).length,
      user: Object.entries(user).length,
      calc_data: Object.entries(calc_data).length,
    };
    for (let i in keyCollection) {
      if (keyCollection[i] !== 0) {
        if (i === "negotiate") {
          body[i] = { ...negotiate };
        }
        if (i === "mid_negotiate_vehicle") {
          body[i] = { ...mid_negotiate_vehicle };
        }
        if (i === "mid_vehicle_dealership") {
          body[i] = { ...mid_vehicle_dealership };
        }
        if (i === "vehicle") {
          body[i] = { ...vehicle };
        }
        if (i === "user") {
          body[i] = { ...user };
        }
        if (i === "calc_data") {
          body[i] = { ...calc_data };
        }
      }
    }

    // const body = {
    //   negotiate,
    //   mid_negotiate_vehicle,
    //   mid_vehicle_dealership,
    //   vehicle,
    //   user,
    // };
    const _res = await ApiCallUploadMethodJson(
      "api/mid/negotiate/vehicle/advance/search",
      "POST",
      body
    );
    return _res;
  }
};

export const useFilterAllCars = (setFiltredData) => {
  return useMutation(getFiltredData, {
    onSuccess: (data) => setFiltredData(data),
  });
};

//deal sold//
export const getAllCarsSold = async () => {
  return ApiCallUploadMethodJson("deal/advance/search", "POST", {}, false);
  // return ApiCallGetMethod("api/deal/vehicles");
};
