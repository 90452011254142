const QuickBooksReducer = (state, action) => {
  if (typeof action === "string") action = { type: action };
  switch (action.type) {
    case "nextStep":
      return {
        ...state,
        step: state.step < 2 ? state.step + 1 : 2,
      };
    case "prevStep":
      return {
        ...state,
        step: state.step !== 0 ? state.step - 1 : 0,
      };
    case "setStep":
      return {
        ...state,
        step: action.step,
      };
    case "setVehicle": {
      const incomes = action?.financial?.filter(
        (data) => +data?.document_type === 1
      );
      const costs = action?.financial?.filter(
        (data) => +data?.document_type !== 1
      );
      return {
        ...state,
        vehicle: action.vehicle,
        step: state.step < 2 ? state.step + 1 : 2,
        incomes,
        costs,
      };
    }
    case "linkIncome":
      return {
        ...state,
        linkedIncomes: {
          ...state.linkedIncomes,
          [action.incomeId]: action.salesReceipt,
        },
      };

    case "unlinkIncome":
      return {
        ...state,
        linkedIncomes: {
          ...state.linkedIncomes,
          [action.incomeId]: null,
        },
      };

    case "linkCost":
      return {
        ...state,
        linkedCosts: {
          ...state.linkedCosts,
          [action.costId]: action.purchase,
        },
      };
    case "unlinkCost":
      return {
        ...state,
        linkedCosts: {
          ...state.linkedCosts,
          [action.costId]: null,
        },
      };

    case "resetModal":
      return {
        ...state,
        vehicle: undefined,
        step: 0,
        linkedIncomes: {},
        linkedCosts: {},
      };
    default: {
      return state;
    }
  }
};
export default QuickBooksReducer;
