import { useContext } from "react";
import { IsAuthDispatch, IsAuthState } from "../context/auth";
import { AllCarsDealState } from "../context/dealInventory";
import { isValidToken } from "../utils/token/check_token";
import { Cookies } from "react-cookie";
const cook = new Cookies();
export const useIsAuthState = () => useContext(IsAuthState);
export const useIsAuthDispatch = () => {
  const dispatch = useContext(IsAuthDispatch);
  const setState = (auth) => {
    return dispatch(auth);
  };
  return setState;
};
export const useIsDealInventoryVehicle = () => useContext(AllCarsDealState);
