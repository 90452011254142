export const reactSelectStyle = {
  control: (base, state) => ({
    ...base,
    borderRadius: "40px",
    paddingLeft: "5px",
    paddingRight: "5px",
    boxShadow: state.isFocused ? "0px 0px 0px 4px rgba(28, 191, 128, 0.3)" : "",
    border: state.isFocused ? "" : "",
  }),
};

export const reactSelectStyleSeller = {
  control: (base, state) => ({
    ...base,
    borderRadius: "40px",
    paddingLeft: "5px",
    paddingRight: "5px",
    boxShadow: state.isFocused ? "0px 0px 0px 4px rgba(28, 191, 128, 0.3)" : "",
    border: state.isFocused ? "" : "",
  }),
  menu: (provided) => ({ ...provided, zIndex: "15", backgroundColor: "#fff" }),
};

export const reactSelectStylePurchase = {
  control: (base, state) => ({
    ...base,
    borderRadius: "40px",
    paddingLeft: "5px",
    paddingRight: "5px",
    boxShadow: state.isFocused ? "0px 0px 0px 4px rgba(28, 191, 128, 0.3)" : "",
    border: state.isFocused ? "" : "",
  }),
  placeholder: (styles) => ({ ...styles, opacity: "1", color: "#000" }),
};
export const reactSelectDealerpullStyle = {
  control: (base, state) => ({
    ...base,
    borderRadius: "20px",
    paddingLeft: "5px",
    paddingRight: "5px",
    boxShadow: state.isFocused ? "0px 0px 0px 4px rgba(28, 191, 128, 0.3)" : "",
    border: state.isFocused ? "" : "",
  }),
  option: (style) => {
    return {
      ...style,
      paddingLeft: "5px",
      paddingRight: "5px",
      borderBottom: "1px solid #a3a5a7",
      position: "relative",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 20, position: "relative" }),
};
const colorBoxForReactSelect = (color = "#000") => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    backgroundColor: `#${color}`,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 20,
    width: 20,
    border: "1px dashed #49505799",
  },
});
export const reactSelectColorStyle = {
  control: (base, state) => ({
    ...base,
    borderRadius: "20px",
    paddingLeft: "5px",
    paddingRight: "5px",
    boxShadow: state.isFocused ? "0px 0px 0px 4px rgba(28, 191, 128, 0.3)" : "",
    border: state.isFocused ? "" : "",
  }),
  option: (style, { data, isSelected }) => {
    return {
      ...style,
      ...colorBoxForReactSelect(data?.colorObject?.code),
      backgroundColor: "trasparent",
      color: "#000",
      cursor: "pointer",
    };
  },
  input: (styles) => {
    return {
      ...styles,
      ...colorBoxForReactSelect(),
    };
  },
  placeholder: (styles) => ({ ...styles, ...colorBoxForReactSelect() }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...colorBoxForReactSelect(data?.colorObject?.code),
  }),
};
