import React, { useEffect } from "react";

const CloseWindow = () => {
  useEffect(() => {
    window.close();
  }, []);
  return <div>Succeed</div>;
};

export default CloseWindow;
