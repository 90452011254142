import {
  ApiCallGetMethod,
  ApiCallUploadMethodFormData,
  ApiCallUploadMethodJson,
} from "../../constant/base";

export const postVehicleDeposit = async (body) => {
  const formData = await new FormData();
  formData.append("midVehicleDealershipFinancial", body.image_file);
  formData.append("real_done_date", body.real_done_date);
  formData.append("price", body.price);
  formData.append("mid_vehicle_id", body.mid_vehicle_id);
  formData.append("deadline", body.deadline);
  formData.append("user_id", body.user_id);
  return ApiCallUploadMethodFormData(
    "api/vehicle/dealership/financial/deposite/add",
    "POST",
    formData
  );
};
export const getCustomerUsers = () => {
  return ApiCallGetMethod("api/users/by/dealership");
};
export const getCustomerDepositeVehicles = () => {
  return ApiCallGetMethod("api/dealership/vehicles");
};
export const getDealershipDeposite = () => {
  return ApiCallGetMethod("api/vehicle/dealership/financial/deposite/all");
};
export const getCustomerDeposite = () => {
  return ApiCallGetMethod("api/vehicle/deposite/all");
};
export const updateDealerShipDeposites = (body) => {
  const { value, id } = body;
  return ApiCallUploadMethodJson(
    `api/vehicle/dealership/financial/deposite/update/${id}`,
    "PATCH",
    value
  );
};
