import React, { memo, useCallback, useContext, useState } from "react"
import { useEffect } from "react"
import { SocketContext } from "../../../context/socket"
import { Button } from "react-bootstrap"
import { useIsAuthState } from "../../../hooks/useAuth"



const ChatRooms = React.lazy(() => import("./chat_rooms"))
const ChatMessages = React.lazy(() => import("./chat_messages"))

const Chat = () => {
  const [showNotificationButton, setShowNotificationButton] = useState(
    "Notification" in window && Notification.permission === "default"
  )
  const auth = useIsAuthState()


  const { status, chat, history } = useContext(SocketContext)
  const {
    messages,
    getAllMessagesByRoom,
    sendMessage,
    getAllRooms,
    getRoomById,
    rooms,
    requestLead,
    seenMessage,
    selectedChat,
    setSelectedChat,
    isTyping,
    stopIsTyping,
    typing,
    setUserStatus,
    handleGetRooms
  } = chat

  const isOnline = status === "online"
  // console.log("status",status,isOnline)

  useEffect(() => {
    getAllRooms()

  }, [getAllRooms, selectedChat, handleGetRooms])


  const requestNotificationPermission = useCallback(() => {
    Notification.requestPermission().then(() => {
      setShowNotificationButton(() => false)
    })
  }, [])

  return (
    <>
     
        <div className='p-md-3 px-md-4 p-sm-2 p-1 m-0 first_page-container d-flex row align-items-scratch justify-content-start'>
          <h2 className='p-2 m-0 col-12'>Chat</h2>
          {isOnline === true ? <>
            <div className='p-2 m-1 mt-3  bg-white shadow rounded'>
              <div className='m-0 shadow-none p-1 px-3 shadow rounded inventory_vehicles_statistics_container fs-5'>
                <span className='me-2'>{rooms?.length}</span>
                Chats
              </div>
            </div>
            <div className='p-0 m-1 bg-white shadow rounded row chat'>
              <div
                className={`col col-md-12 col-lg-3 p-0 ${selectedChat ? "d-none d-lg-block" : ""
                  }`}
              >
                <ChatRooms
                  setSelectedChat={setSelectedChat}
                  selectedChat={selectedChat}
                  rooms={rooms}
                  history={history}
                />
              </div>
              <div className='col col-md-12 col-lg-9 position-relative p-0'>
                {selectedChat ? (
                  <ChatMessages
                    selectedChat={selectedChat}
                    setSelectedChat={setSelectedChat}
                    messages={messages}
                    getAllMessagesByRoom={getAllMessagesByRoom}
                    sendMessage={sendMessage}
                    getRoomById={getRoomById}
                    requestLead={requestLead}
                    seenMessage={seenMessage}
                    isTyping={isTyping}
                    stopIsTyping={stopIsTyping}
                    typing={typing}
                    history={history}
                  />
                ) : showNotificationButton ? (
                  <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Button onClick={requestNotificationPermission}>
                      Send me notification
                    </Button>
                  </div>
                ) : (
                  <p className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    No Chat selected
                  </p>
                )}
              </div>
            </div>
          </>
            : <div className=" p-0 m-1 bg-white shadow rounded row    py-5 mt-5">
              <div className="d-flex flex-column justify-content-center py-3 p-0 m-0 align-items-center">

                <img src="/images/chat.png" className="w-25" />


                <div className=" py-2">
                  You are offline
                </div>
              </div>
              <div className='w-100 d-flex align-items-center justify-content-center'>
                <Button onClick={() => {
                  setUserStatus("online")
                }}>
                  Back to Online
                </Button>
              </div>


            </div>
          }
        </div>
    
    </>

  )
}

export default memo(Chat)
