/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import {
  ApiCallDeleteMethod,
  ApiCallGetMethod,
  ApiCallUploadMethodFormData,
  ApiCallUploadMethodJson,
} from "../../constant/base";
export const getVehicleManage = async ({
  statusFilter,
  page = 0,
  limit = 10,
  body,
  keywords,
  sorting,
}) => {
  return ApiCallUploadMethodJson(
    `panel/advance/search?page=${page + 1}&limit=${limit}&kind=${statusFilter}${
      keywords && keywords !== "" ? `&keywords=${keywords}` : ""
    }${
      sorting.length !== 0 && sorting[0].id === "Vehicle.age"
        ? `&sortAge=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.Vehicle.model_year"
        ? `&sortYear=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.Vehicle.make"
        ? `&sortMake=${sorting[0].desc === false ? "ASC" : "DESC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.Vehicle.model"
        ? `&sortModel=${sorting[0].desc === false ? "ASC" : "DESC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.stock_NO"
        ? `&sortStock=${sorting[0].desc === false ? "ASC" : "DESC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.odometer"
        ? `&sortOdometer=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.sell_price"
        ? `&sortPrice=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.special_price"
        ? `&sortSpecial=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.is_active"
        ? `&sortActive=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.sold_date"
        ? `&sortSoldDate=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.delivery_date"
        ? `&sortDeliveryDate=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 &&
          sorting[0].id === "Vehicle.MidVDSFinancials[0].real_done_date"
        ? `&sortPurchaseDate=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : ""
      // : sorting.length !== 0 && sorting[0].id === "Vehicle.age"
      // ? `&sortAge=${sorting[0].desc === false ? "DESC" : "ASC"}`
    }&order=createdAt,DESC`,
    "POST",
    body,
    false
  );
};

export const getVehicleManageV3 = () => {
  return ApiCallUploadMethodJson(`v4/panel/advance/search`, "POST", {}, false);
};
export const getVehicleCaching = () => {
  return ApiCallGetMethod(`panel/advance/search/get/version`);
};

export const getVehiclePrintManage = async ({
  statusFilter,
  page = 0,
  limit = 10,
  body,
  keywords,
  sorting,
}) => {
  return ApiCallUploadMethodJson(
    `panel/advance/search?page=${page + 1}&limit=${limit}&kind=${statusFilter}${
      keywords && keywords !== "" ? `&keywords=${keywords}` : ""
    }${
      sorting.length !== 0 && sorting[0].id === "Vehicle.age"
        ? `&sortAge=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.Vehicle.model_year"
        ? `&sortYear=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.Vehicle.make"
        ? `&sortMake=${sorting[0].desc === false ? "ASC" : "DESC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.Vehicle.model"
        ? `&sortModel=${sorting[0].desc === false ? "ASC" : "DESC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.stock_NO"
        ? `&sortStock=${sorting[0].desc === false ? "ASC" : "DESC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.odometer"
        ? `&sortOdometer=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.sell_price"
        ? `&sortPrice=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.special_price"
        ? `&sortSpecial=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : sorting.length !== 0 && sorting[0].id === "Vehicle.is_active"
        ? `&sortActive=${sorting[0].desc === false ? "DESC" : "ASC"}`
        : ""
      // : sorting.length !== 0 && sorting[0].id === "Vehicle.age"
      // ? `&sortAge=${sorting[0].desc === false ? "DESC" : "ASC"}`
    }&print=1&order=createdAt,DESC`,
    "POST",
    body,
    false
  );
};
export const getVehicleDealerPull = async ({ body }) => {
  return ApiCallUploadMethodJson(
    `panel/advance/search?print=1&order=createdAt,DESC`,
    "POST",
    body,
    false
  );
};
export const ucdaWizardVehicle = (body) => {
  const { id, mvdId, tradeIn } = body;
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard/set/vehicle/${id}`,
    "PATCH",
    {
      mvdId,
      tradeIn,
    }
  );
};
export const getVehicleManageCampaign = async (statusFilter) => {
  return ApiCallGetMethod(
    `api/vehicle/dealership/mid/admin?kind=${statusFilter}&order=createdAt,DESC`
  );
};
export const getVehicleManageAdvance = async ({
  statusFilter,
  page = 1,
  limit = 50,
}) => {
  return ApiCallGetMethod(
    `panel/advance/search${
      statusFilter == 8
        ? "?sold=1"
        : statusFilter == 9
        ? "?is_coming_soon=1"
        : statusFilter == 5
        ? "?pending=1"
        : statusFilter == 6
        ? "?finance=1"
        : statusFilter == 11
        ? "?delete=1"
        : ""
    }`
  );
};
export const getVehiclesStatistics = async () => {
  return ApiCallGetMethod(`api/vehicle/dealership/mid/new/admin`);
};
export const getNewSatusForUc = async () => {
  return ApiCallGetMethod(`mid/vehicle/extra/status`);
};

export const getSingleVehicleForFile = async (id) => {
  return ApiCallGetMethod(`api/vehicle/dealership/admin/mid/single/${id}`);
};
export const editStandardOption = async ({ id, json }) => {
  return ApiCallUploadMethodJson(`api/vehicle/update/${id}`, "PATCH", {
    standard: json,
  });
};

export const getConfirmRevertFromSoldInfo = async (ids) => {
  return ApiCallGetMethod(`api/unsold?ids=${ids}`);
};
export const confirmRevertFromSold = async (ids) => {
  return ApiCallUploadMethodJson(`api/unsold`, "POST", { ids });
};

export const creatWizardBillOfSale = async (showNote) => {
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard`,
    "POST",
    {},
    showNote
  );
};
export const wizardCalculate = async (body) => {
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard/set/calculate/${body.id}`,
    "PATCH",
    body.value
  );
};
export const wizardCalculateTax = async (body) => {
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard/tax/rate/${body.id}`,
    "PATCH",
    body.value
  );
};
export const wizardCalculateDeleteTax = async (body) => {
  return ApiCallDeleteMethod(
    `api/billofsale/ucda/wizard/tax/rate/${body.id}/${body?.RateId}`
  );
};
export const wizardDisclouser = async (body) => {
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard/disclosure/${body.id}`,
    "PATCH",
    body.value
  );
};
export const wizardDelivery = async (body) => {
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard/delivery/${body.id}`,
    "PATCH",
    body.value
  );
};
export const wizardDeliverySeller = async () => {
  return ApiCallGetMethod(`api/billofsale/ucda/sellers/list`);
};
export const getCreatWizardBillOfSale = async () => {
  return ApiCallGetMethod("api/billofsale/ucda/wizard");
};
export const deleteWizardBillOfSale = async (body) => {
  return ApiCallDeleteMethod(
    `api/billofsale/ucda/wizard/${body?.id}`,
    "DELETE"
  );
};
export const getSingleCreatWizardBillOfSale = async ({ id }) => {
  return ApiCallGetMethod(`api/billofsale/ucda/wizard/${id}`);
};
export const getVehicleWizardBillOfSale = async () => {
  return ApiCallGetMethod("api/wizard/billofsale/vehicles/all");
};
export const addVehicleWizardBillOfSale = async (body) => {
  return ApiCallUploadMethodJson("api/billofsale/decode/vehicle", "POST", body);
};
export const addMidVehicleWizardBillOfSale = async (body) => {
  return ApiCallUploadMethodJson(
    "api/billofsale/new/mid/vehicle",
    "POST",
    body
  );
};

export const getVehiclesForCaringList = async (mode, id, status) => {
  return ApiCallGetMethod(
    `api/get/accept/vehicles/craigslist${
      mode === null && id !== null
        ? `?cityId=${id}`
        : status === null
        ? `?clvehicle=1&done=${mode}`
        : `?clvehicle=1&done=${mode}&status=${status}`
    }`
  );
};
export const getVehiclesAppCaringList = async () => {
  return ApiCallGetMethod(`api/download/application`);
};
export const sendToCaringListByMode = async ({
  update = [],
  renew = [],
  deletdted = [],
}) => {
  return ApiCallUploadMethodJson(`api/mid/vehicles/to/craigslist`, "POST", {
    update,
    renew,
    delete: deletdted,
  });
};
export const cancellToCaringListByMode = async ({ id = [] }) => {
  return ApiCallUploadMethodJson(`api/craigslist/cancel`, "PATCH", {
    ids: id,
  });
};
export const cancellToFacebookByMode = async ({ id = [] }) => {
  return ApiCallUploadMethodJson(`api/facebook/cancel`, "PATCH", {
    ids: id,
  });
};

export const getVehiclesForFacebookList = async (mode, status) => {
  return ApiCallGetMethod(
    `api/get/accept/vehicles/facebook${
      mode === null
        ? ``
        : status === null
        ? `?clvehicle=1&done=${mode}`
        : `?clvehicle=1&done=${mode}&status=${status}`
    }`
  );
};
export const sendToFaceBookByMode = async ({
  update = [],
  renew = [],
  deletdted = [],
}) => {
  return ApiCallUploadMethodJson(`api/mid/vehicles/to/facebook`, "POST", {
    update,
    renew,
    delete: deletdted,
  });
};

//Rent//

export const RentPost = async (body) => {
  return ApiCallUploadMethodJson(`rent`, "POST", body);
};

//Rent Details//
export const RentDetailsGet = async (id) => {
  return ApiCallGetMethod(`rent/mid/vehicle/${id}`);
};
//DecodeVin//
export const addVehicleWizardDealerPull = async (body) => {
  return ApiCallUploadMethodJson(
    "api/billofsale/ucda/wizard/tradein",
    "POST",
    body
  );
};
//DecodeVin AddedWanted//
export const addVehicleWizardDealerAddedWanted = async (body) => {
  return ApiCallUploadMethodJson("decode/vin/number", "POST", body);
};

export const submitDealerAddedWanted = async (body) => {
  return ApiCallUploadMethodJson("added-wanted", "POST", body);
};

export const updateDealerAddedWanted = async (body, id) => {
  return ApiCallUploadMethodJson(`added-wanted/${id}`, "PATCH", body);
};

export const getAddedAndWanted = async (type) => {
  return ApiCallGetMethod(`added-wanted?type=${type}`);
};

export const deleteVehiclesAddedAndWanted = (id) => {
  return ApiCallDeleteMethod(`added-wanted/${id}`, "DELETE");
};

//submit TradeIn//
export const SubmitTrade = async ({ id, body }) => {
  return ApiCallUploadMethodJson(
    `api/billofsale/ucda/wizard/tradein/${id}`,
    "PATCH",
    {
      ...body,
    }
  );
};
//Print//
export const getPrintAndDownload = async (id) => {
  return ApiCallGetMethod(`api/billofsale/wizard/export/${id}`);
};
//Note Details//
export const NoteDetailsGet = async (id) => {
  return ApiCallGetMethod(`api/vehicle/dealership/note/${id}`);
};
export const NoteDetailsPost = async (value) => {
  const formData = await new FormData();
  formData.append("title", value.title);
  formData.append("note", value.note);
  if (value.attaches && value.attaches?.length !== 0) {
    value.attaches.map((file) => {
      formData.append("attaches", file);
    });
  }
  formData.append("mid_v_ds_id", value.mid_v_ds_id);
  return ApiCallUploadMethodFormData(
    `api/vehicle/dealership/note/add`,
    "POST",
    formData
  );
};
export const NoteDetailsEdit = async ({ value, id }) => {
  return ApiCallUploadMethodJson(
    `api/vehicle/dealership/note/update/${id}`,
    "PATCH",
    value
  );
};
export const NoteDetailsImgEdit = async ({ value, id }) => {
  const formData = await new FormData();
  value.attachess.map((file) => {
    formData.append("attaches", file);
  });
  return ApiCallUploadMethodFormData(
    `api/vehicle/dealership/note/add/attach/${id}`,
    "POST",
    formData
  );
};
export const NoteDetailsDelete = async ({ id }) => {
  return ApiCallDeleteMethod(
    `api/vehicle/dealership/note/delete/${id}`,
    "DELETE"
  );
};
export const NoteDetailsImgDelete = async ({ id }) => {
  return ApiCallDeleteMethod(
    `api/vehicle/dealership/note/delete/attach/${id}`,
    "DELETE"
  );
};

export const SubmitPurchesFrom = async ({ body }) => {
  return ApiCallUploadMethodJson(`api/dealerweb/market/step-add/new`, "POST", {
    ...body,
  });
};
export const AddInventorySubmitPurchesFrom = async ({ id }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/market/step-add/new/${id}`,
    "POST"
  );
};

export const SubmitPurchesFromWholeSale = async ({ body }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/market/wholesale/step-add`,
    "POST",
    {
      ...body,
    }
  );
};

export const SubmitPurchesFromDealer = async (body) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/market/wholesale/add/new`,
    "POST",
    body
  );
};

export const UpdatePurchesFromDealer = async (body) => {
  const { value } = body;
  return ApiCallUploadMethodJson(
    `api/dealerweb/market/wholesale/update/${body?.id}`,
    "PATCH",
    { ...value }
  );
};

export const GetPurchesFromDealer = async ({ id }) => {
  return ApiCallGetMethod(`api/dealerweb/market/wholesale/single/${id}`);
};

// Note for Deal Inventory
export const NoteDealGet = async (id) => {
  return ApiCallGetMethod(`deal/dealership/comment/${id}`);
};
export const getPurchesFrom = async () => {
  return ApiCallGetMethod(`api/dealerweb/market/get/all`);
};

export const getPurchesFromWholeSale = async () => {
  return ApiCallGetMethod(`api/dealerweb/market/get/all?wholesale=1`);
};

export const NoteDealPost = async (value) => {
  const formData = await new FormData();
  formData.append("title", value.title);
  formData.append("comment", value.comment);
  if (value.file && value.file?.length !== 0) {
    value.file.map((file) => {
      formData.append("file", file);
    });
  }
  formData.append("mvd_bos_id", value.mvd_bos_id);
  return ApiCallUploadMethodFormData(
    `attach/lead/dealership/comment`,
    "POST",
    formData
  );
};

export const addCarPurchesFromDealer = async ({ id, body }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/market/wholesale/add/new/${id}`,
    "POST",
    body
  );
};

export const deleteVehiclesInPurcahse = (id) => {
  return ApiCallDeleteMethod(`api/dealerweb/market/mark-deleted/${id}`, "POST");
};
export const getPurchesFromDeleted = async () => {
  return ApiCallGetMethod(`api/dealerweb/market/get/all?deleted=1`);
};
export const revertVehiclesInPurcahse = (id) => {
  return ApiCallUploadMethodJson(`api/dealerweb/market/restore/${id}`, "PATCH");
};

export const getDeletedPurchesFromWholeSale = async () => {
  return ApiCallGetMethod(`api/dealerweb/market/get/all?wholesale=1&deleted=1`);
};
