import { OverlayTrigger, Popover } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  FaCar,
  FaRoad,
  FaTasks,
  FaHandshake,
  FaThLarge,
  FaEnvelopeOpenText,
  FaTachometerAlt,
  FaUsers,
  FaPlus,
  FaDollarSign,
  FaHouseDamage,
  FaBan,
  // FaSms,
  // FaEnvelopeOpen,
} from "react-icons/fa";
import { useDrawerSetter, useDrawerState } from "../../hooks/useDrawer";
import { useIsAuthState } from "../../hooks/useAuth";

const HillzDrawer = () => {
  const drawer = useDrawerState();
  const setDrawerState = useDrawerSetter();
  const user = useIsAuthState().user;
  return (
    <div
      className={`d-flex flex-column justify-content-start align-items-start sidenav-container ${drawer ? "sidenavOpen" : "sidenavClose"
        } `}
      onMouseOver={() => setDrawerState(true)}
      onMouseLeave={() => setDrawerState(false)}
    >
      <div className="p-2 m-0 mt-2 mb-5 sidebar_ul d-flex flex-column justify-content-center align-items-start">
        <NavLink
          to="/register"
          className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
          activeClassName="sidebar_navlink"
          onClick={() => {
            setDrawerState(false);
          }}
        >
          <FaTachometerAlt className="sidebar_icons" />
          <p
            className={`${drawer ? "" : "d-none"} p-0 m-0`}
            style={{ fontSize: "1.1rem" }}
          >
            Dealerships
          </p>
        </NavLink>
        {(user?.id === 529 || user?.id === 1 || user?.id === 328) && (
          <>
            <NavLink
              to="/add-dealership"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <FaPlus className="sidebar_icons" />
              <p
                className={`${drawer ? "" : "d-none"} p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                Add Dealerships
              </p>
            </NavLink>
            {(user?.id === 529 || user?.id === 1) && (
              <>
                <NavLink
                  to="/dealerships-payments-status"
                  className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
                  activeClassName="sidebar_navlink"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <FaHouseDamage className="sidebar_icons" />
                  <p
                    className={`${drawer ? "" : "d-none"} p-0 m-0`}
                    style={{ fontSize: "1.1rem" }}
                  >
                    Payment status
                  </p>
                </NavLink>
                <NavLink
                  to="/dealerships-payments"
                  className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
                  activeClassName="sidebar_navlink"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <FaDollarSign className="sidebar_icons" />
                  <p
                    className={`${drawer ? "" : "d-none"} p-0 m-0`}
                    style={{ fontSize: "1.1rem" }}
                  >
                    Payments
                  </p>
                </NavLink>
              </>
            )}
            <NavLink
              to="/dealerships-users"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <FaBan className="sidebar_icons" />
              <p
                className={`${drawer ? "" : "d-none"} p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                Unbanned Users
              </p>
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

export default HillzDrawer;
