const SimpleLoading = () => {
  return (
    <div className="p-0 m-0">
      <div className="m-0 custom_loading-style">
        <div
          className="p-0 m-0 vehicle_avatar-image"
          style={{
            backgroundColor: "transparent",
            backgroundImage: `url(/images/hillz_big_logo.png)`,
            height: "30px",
            width: "30px",
            zIndex: 2,
          }}
        ></div>
      </div>
    </div>
  );
};

export default SimpleLoading;
