import { BASE_URL, MAX_AGE_COOKIE } from "../../constant/base";
import { checkCookieIsEnabled } from "../auth/check_navigator_cookie";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
export const hanldeConfirmSubmit = async (values, handleClose, setLoading) => {
  const cook = new Cookies();
  const devicetoken = localStorage.getItem("devicetoken");
  const _res = await fetch(`${BASE_URL}api/user/device/confirm`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "Application/json",
      "x-devicetoken": devicetoken,
    },
    body: JSON.stringify({ ...values, trusted: Boolean(values.trusted) }),
  });

  if (_res.status === 200) {
    const _resJson = await _res.json();
    const token = await _resJson.token;
    const devicetoken = await _resJson.deviceToken;
    let isCookieEnabled = checkCookieIsEnabled();
    if (isCookieEnabled) {
      localStorage.setItem("devicetoken", devicetoken);
    }
    if (isCookieEnabled) {
      cook.set("token", token, {
        maxAge: MAX_AGE_COOKIE,
      });
    }
    handleClose();
    setLoading(false);
    return token;
  } else if (_res.status === 403) {
    toast.error("Wrong code.Please try again.");
    setLoading(false);
  } else if (_res.status === 500) {
    toast.error("Server error");
    setLoading(false);
  } else {
    toast.error("Something went wrong");
    setLoading(false);
  }
};
