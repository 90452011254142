import { toast } from "react-toastify";
import { BASE_URL } from "../../constant/base";
import { Cookies } from "react-cookie";
export const SendErrorToserverForLog = async (error, info) => {
  const cook = new Cookies();
  const token = cook.get("token");
  const _res = await fetch(BASE_URL + "api/errors", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: "Something went wrong",
      message: `${error + info?.componentStack}`,
      from: "Front-end",
    }),
  });
  if (_res.status === 200) {
    toast.success(
      "We Send A report for this error to our developers and they've trying to solve it"
    );
  }
};
