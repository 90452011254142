import { createContext, useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { BASE_URL } from "../constant/base";

export const IsAuthState = createContext();
export const IsAuthDispatch = createContext();

const cook = new Cookies();

const AuthProvider = ({ children }) => {
  //befor initialization is {isLoggedIn:false}
  const [isAuth, setAuth] = useState(
    () =>
      JSON.parse(localStorage.getItem("isLoggedIn")) || { isLoggedIn: false }
  );
  useEffect(() => {
    // const valid = isValidToken();
    const checkUserAuth = async () => {
      if (cook.get("token")) {
        const _res = await fetch(`${BASE_URL}api/user/info`, {
          credentials: "include",
          headers: {
            authorization: `Bearer ${cook.get("token")}`,
          },
        });
        const _resJson = await _res.json();
        if (_res.status === 200) {
          setAuth({ isLoggedIn: true, ..._resJson });
          localStorage.setItem(
            "isLoggedIn",
            JSON.stringify({
              isLoggedIn: true,
              components: _resJson?.components,
              ds_status: _resJson?.ds_status,
              user: { frk_role: _resJson?.user?.frk_role },
            })
          );
          // return true;
        }
        // setAuth(false);
      } else return setAuth(false);
    };
    // if (!valid) {
    //   setAuth(false);
    // } else {
      checkUserAuth();
    // }
  }, []);
  return (
    <IsAuthState.Provider value={isAuth}>
      <IsAuthDispatch.Provider value={setAuth}>
        {children}
      </IsAuthDispatch.Provider>
    </IsAuthState.Provider>
  );
};
export default AuthProvider;
