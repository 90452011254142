import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getSingleVehicleForFile } from "../../../utils/vehicle/vehicle_manage";
import ChooseCustomer from "./deposit-form/chooseCustomer";
import VehicleDescription from "./deposit-form/vehicleDescription";
import DepositDetails from "./deposit-form/depositDetails";
import SimpleLoading from "../../common/loading/simple_loading";
import { FaDownload } from "react-icons/fa";
import { PDFDocument, rgb } from "pdf-lib";
import download from "downloadjs";
import { IMAGE_CDN_DOMAIN } from "../../../constant/base";
import { useIsAuthState } from "../../../hooks/useAuth";
import { priceComma } from "../../../constant/price_handler";
import { getSingleDepositFormPDF } from "../../../utils/vehicle/vehicle";
import moment from "moment";

const DepositForm = () => {
  const { id } = useParams();
  const [printLoading, setPrintLoading] = useState(false);
  const auth = useIsAuthState();

  const getSingleVehicleInfoInv = useQuery(
    ["getSingleVehicleInfoInv", id],
    () => getSingleVehicleForFile(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: vehicleData, isLoading: isVehicleLoading } =
    getSingleVehicleInfoInv;

  const formik = useFormik({
    initialValues: {
      full_name: "",
      driver_license_NO: "",
      driver_license_expire_date: "",
      city: "",
      province: "",
      postalcode: "",
      mobile: "",
      fax_NO: "",
      email: "",
      address: "",
      make: "",
      model: "",
      model_year: "",
      body_style: "",
      frk_bodyStyle: "",
      frk_exterior_color: "",
      stock_NO: "",
      vin_number: "",
      fuel_type: "",
      odometer: "",
      odometer_type: "",
      depositAmount: "",
      transaction: "",
      depositDate: "",
      paymentMethod: "",
      date_header: moment().format("YYYY-MM-DD"),
      date_footer: moment().format("YYYY-MM-DD"),
      f_name: null,
      l_name: null
    },
    onSubmit: (value, { resetForm }) => {
      resetForm();
    },
    enableReinitialize: true,
  });
  const handlePrint = async () => {
    let allDelivaryFiled = [
      [
        {
          part_front_name: "Vin Number",
          part_name: "vin_number",
          part_type: "text",
          value: formik?.values?.vin_number ? formik?.values?.vin_number : "",
        },
        {
          part_front_name: "Vehicle Year",
          part_name: "model_year",
          part_type: "text",
          value: formik?.values?.model_year
            ? formik?.values?.model_year?.label
            : "",
        },
        {
          part_front_name: "Vehicle Model",
          part_name: "model",
          part_type: "text",
          value: formik?.values?.model ? formik?.values?.model : "",
        },
        {
          part_front_name: "Vehicle Make",
          part_name: "make",
          part_type: "text",
          value: formik?.values?.make ? formik?.values?.make : "",
        },
        {
          part_front_name: "Vehicle Odometer",
          part_name: "odometer",
          part_type: "text",
          value: formik?.values?.odometer ? formik?.values?.odometer : "",
        },
        {
          part_front_name: "Color",
          part_name: "exterior_color",
          part_type: "text",
          value: formik?.values?.frk_exterior_color
            ? formik?.values?.frk_exterior_color?.label
            : "",
        },
        {
          part_front_name: "Full Name",
          part_name: "full_name",
          part_type: "text",
          value: formik?.values?.full_name ? formik?.values?.full_name : "",
        },
        {
          part_front_name: "Driver License NO",
          part_name: "driver_license_NO",
          part_type: "text",
          value: formik?.values?.driver_license_NO
            ? formik?.values?.driver_license_NO
            : "",
        },
        {
          part_front_name: "Driver License Expire Date",
          part_name: "driver_license_expire_date",
          part_type: "text",
          value: formik?.values?.driver_license_expire_date
            ? formik?.values?.driver_license_expire_date
            : "",
        },
        {
          part_front_name: "City",
          part_name: "city",
          part_type: "text",
          value: formik?.values?.city ? formik?.values?.city : "",
        },
        {
          part_front_name: "Province",
          part_name: "province",
          part_type: "text",
          value: formik?.values?.province ? formik?.values?.province : "",
        },
        {
          part_front_name: "Postal Code",
          part_name: "postalcode",
          part_type: "text",
          value: formik?.values?.postalcode ? formik?.values?.postalcode : "",
        },
        {
          part_front_name: "Mobile",
          part_name: "mobile",
          part_type: "text",
          value: formik?.values?.mobile ? formik?.values?.mobile : "",
        },
        {
          part_front_name: "Fax NO",
          part_name: "fax_NO",
          part_type: "text",
          value: formik?.values?.fax_NO ? formik?.values?.fax_NO : "",
        },
        {
          part_front_name: "Email",
          part_name: "email",
          part_type: "text",
          value: formik?.values?.email ? formik?.values?.email : "",
        },
        {
          part_front_name: "Address",
          part_name: "address",
          part_type: "text",
          value: formik?.values?.address ? formik?.values?.address : "",
        },
        {
          part_front_name: "Body Style",
          part_name: "body_style",
          part_type: "text",
          value: formik?.values?.frk_bodyStyle?.label
            ? formik?.values?.frk_bodyStyle?.label
            : "",
        },
        {
          part_front_name: "Stock NO",
          part_name: "stock_NO",
          part_type: "text",
          value: formik?.values?.stock_NO ? formik?.values?.stock_NO : "",
        },
        {
          part_front_name: "Fuel Type",
          part_name: "fuel_type",
          part_type: "text",
          value: formik?.values?.fuel_type ? formik?.values?.fuel_type : "",
        },
        {
          part_front_name: "Deposit Amount",
          part_name: "depositAmount",
          part_type: "text",
          value: formik?.values?.depositAmount
            ? formik?.values?.depositAmount
            : "",
        },
        {
          part_front_name: "Transaction",
          part_name: "transaction",
          part_type: "text",
          value: formik?.values?.transaction ? formik?.values?.transaction : "",
        },
        {
          part_front_name: "Deposit Date",
          part_name: "depositDate",
          part_type: "text",
          value: formik?.values?.depositDate ? formik?.values?.depositDate : "",
        },
        {
          part_front_name: "Footer Date",
          part_name: "date_footer",
          part_type: "text",
          value: formik?.values?.date_footer ? formik?.values?.date_footer : "",
        },
        {
          part_front_name: "Header Date",
          part_name: "date_header",
          part_type: "text",
          value: formik?.values?.date_header ? formik?.values?.date_header : "",
        },
      ],
    ];
    let allcheckBox = [
      {
        part_front_name: "Payment Method",
        part_name: "paymentMethod",
        part_type: "checkBox",
        value: formik?.values?.paymentMethod
          ? formik?.values?.paymentMethod
          : "",
      },
      {
        part_front_name: "Vehicle Odometer Type",
        part_name: "odometer_type",
        part_type: "checkBox",
        value: formik?.values?.odometer_type
          ? formik?.values?.odometer_type
          : "",
      },
    ];

    try {
      //get existing form
      setPrintLoading(true);
      const arrayBuffer = await getSingleDepositFormPDF()
        .then((res) => res.blob())
        .then((res) => res.arrayBuffer())
        .catch((err) => console.log(err));
      //load existing form
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pageCount = pdfDoc.getPageCount();
      const form = pdfDoc.getForm();
      //add header to form

      //get images
      const logoHeight = 40;
      const logoUrl = IMAGE_CDN_DOMAIN + auth?.dealershipInfo?.logo_url;

      const arrayBufferImage =
        auth?.dealershipInfo?.logo_url !== null
          ? await fetch(logoUrl)
              .then((response) => response.blob())
              .then((res) => res.arrayBuffer())
          : null;
      const logoImage =
        arrayBufferImage !== null
          ? await pdfDoc.embedPng(arrayBufferImage)
          : null;

      //draw images
      for (let i = 0; i < pageCount; i++) {
        // add img to header
        if (logoImage !== null) {
          form.getButton("logo_button").setImage(logoImage);
        }
        // add buiness name
        pdfDoc.getPage(i).drawText(`${auth?.dealershipInfo?.bussiness_name}`, {
          x: 100 + logoHeight + 20,
          y: pdfDoc.getPage(i).getSize().height - 40,
          lineHeight: 13,
          size: 16,
          color: rgb(0, 0, 0),
        });
        // business address phone
        pdfDoc
          .getPage(i)
          .drawText(
            `Phone:${auth?.dealershipInfo?.business_phone} \n${auth?.dealershipInfo?.business_street},${auth?.dealershipInfo?.business_city?.city},${auth?.dealershipInfo?.business_city?.Province?.province},${auth?.dealershipInfo?.business_postal}`,
            {
              x: 100 + logoHeight + 20,
              y: pdfDoc.getPage(i).getSize().height - 53,
              lineHeight: 13,
              size: 9,
              color: rgb(0, 0, 0),
            }
          );
      }
      allcheckBox.map((item) => {
        if (item.part_name === "odometer_type") {
          if (item.value === 1) {
            const checkbox = form.getCheckBox("MILES_2");
            checkbox.check();
          }
          if (item.value === 2) {
            const checkbox = form.getCheckBox("KMS_2");
            checkbox.check();
          }
        }
        if (item.part_name === "paymentMethod") {
          if (item.value === "Credit-Card") {
            const checkbox = form.getCheckBox("paymentMethod");
            checkbox.check();
          }
          if (item.value === "Debit-Card") {
            const checkbox = form.getCheckBox("paymentMethod1");
            checkbox.check();
          }
          if (item.value === "Cash") {
            const checkbox = form.getCheckBox("paymentMethod2");
            checkbox.check();
          }
          if (item.value === "Cheque") {
            const checkbox = form.getCheckBox("paymentMethod3");
            checkbox.check();
          }
          if (item.value === "Etransfer") {
            const checkbox = form.getCheckBox("paymentMethod4");
            checkbox.check();
          }
          if (item.value === "Others") {
            const checkbox = form.getCheckBox("paymentMethod5");
            checkbox.check();
          }
        }
      });
      //fill form fields
      const fields = form.getFields();
      fields.forEach((field) => {
        const fieldName = field.getName();
        for (let i = 0; i < allDelivaryFiled[0].length; i++) {
          if (allDelivaryFiled[0][i]?.part_name == fieldName) {
            let getField = form.getTextField(allDelivaryFiled[0][i]?.part_name);
            if (getField && allDelivaryFiled[0][i]?.value !== null) {
              if (allDelivaryFiled[0][i]?.part_type === "text") {
                getField.setText(`${allDelivaryFiled[0][i]?.value}`);
              } else {
                getField.setText(
                  `$${priceComma(allDelivaryFiled[0][i]?.value, 2)}`
                );
              }
            } else if (getField && allDelivaryFiled[0][i]?.value === null) {
              getField.setText("");
            }
          }
        }
      });
      //show pdf
      const pdfBytes = await pdfDoc.save();
      const file = new Blob([pdfBytes], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      download(file, "Deposit-Form.pdf", "application/pdf");
      setPrintLoading(false);
      allDelivaryFiled = [];
    } catch (e) {
      setPrintLoading(false);
      console.log(e);
    }
  };
  return (
    <div className="p-md-3 px-md-4 p-sm-2 p-1 m-0 first_page-container d-flex row align-items-scratch justify-content-start">
      <div className="p-0 m-0 w-100 d-flex justify-content-between align-items-center">
        <h2 className="p-0 m-0 mx-2">Deposit</h2>
      </div>
      <h5 className="p-0 m-0 mt-2 text-dark mx-2 col-12">
        {vehicleData?.midVehicleDealership?.Vehicle?.model_year +
          " " +
          vehicleData?.midVehicleDealership?.Vehicle?.make +
          " " +
          vehicleData?.midVehicleDealership?.Vehicle?.model}
      </h5>

      <div className="p-0 m-0 mt-4 row bg-white shadow rounded">
        <ChooseCustomer formik={formik} />
        <VehicleDescription formik={formik} vehicleData={vehicleData} />
        <DepositDetails formik={formik} />
      </div>
      <div
        className="p-2 py-3 mt-2 mb-2 m-0 d-flex justify-content-end align-items-center"
        style={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backdropFilter: "blur(5px)",
        }}
      >
        {printLoading ? (
          <SimpleLoading />
        ) : (
          <div className="p-0 m-0 ">
            <button
              className="btn form_submit-btn my-1 px-5 shadow d-flex align-items-center"
              onClick={() => handlePrint(false)}
            >
              <FaDownload className="mx-2" /> Download and Print
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DepositForm;
